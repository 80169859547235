import { Link } from 'react-router-dom';
import mask from '../assets/image/Mask group.png';
import profile3 from '../assets/image/profile3.png';
import { GrLogout } from 'react-icons/gr';
import $ from "jquery";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import render12 from '../assets/image/RENDER-1-copy.webp';
import { makeApiRequest } from '../config/axiosService';


function Navbar() {

    const token = localStorage.getItem('token')
    // console.log("nav page token", token);
    const [userEmail, setUserEmail] = useState("")

    const Navigate = useNavigate()
    const Logout = () => {
        localStorage.clear()
        Navigate('/login')
    }
    useEffect(() => {
        $(document).on("scroll", function () {
            if ($(document).scrollTop() > 10) {
                $("#navbar").hide();
            }
            else {
                $("#navbar").show();
            }
        });
    });

    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        try {
            let params = {
                url: `get-user-details`,
                method: "GET",
                // data: payload
            }
            const response = await makeApiRequest(params);
            if (response.status) {
                setUserEmail(response.data.email)
            }
        } catch (e) {
            console.log(e);
        }
    }



    return (
        <div className='APP'>
            <div className=''>
                <nav class="navbar navbar-expand-lg bg-body-transparent custom-insidenav " id="navbar">
                    <div class="container-fluid col-lg-11">
                        <a class="navbar-brand" href="/"><img src={render12}></img></a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse  align-items-center" id="navbarNav">
                            <ul class="navbar-nav mx-auto mb-2 mb-lg-0 mt-lg-0 mt-4 me-3">

                                <li class="nav-item border-0 me-lg-3">
                                    <Link to="/tobulkorder"><a class="nav-link navbar-new-link-text-1 custom-nav-hover" aria-current="page" href="">BULK ORDER</a></Link>
                                </li>

                                <li class="nav-item border-0 mx-lg-5">
                                    <Link to="/buy"><a class="nav-link navbar-new-link-text-1 custom-nav-hover" href="">BUY WITH CRYPTO</a></Link>
                                </li>

                                <li class="nav-item  border-0">
                                    <Link to="/isotope"><a class="nav-link navbar-new-link-text-1 custom-nav-hover" href="">WHAT IS OUR ASSET</a></Link>
                                </li>
                                <li className='d-flex align-items-center '>
                                    <div className=''>
                                        <Link to="https://presale.cuptoken.trade/">
                                            <button className="treasure-btn-2 active ">presale</button>
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                            {
                                token ? (
                                    //     <ul class="navbar-nav custom-listnav">
                                    //     <a class="nav-last" href="#">
                                    //         <div className="d-flex flex-column justify-content-center text-center custom-user-mo">
                                    //             <img src={profile3} className="d-block me-3 custom-maskimg" alt="user" />
                                    //             {/* <span className='navbar-new-link-text-2'>user</span> */}
                                    //         </div>
                                    //         <div class="dropdown">
                                    //                 <span className='navbar-new-link-text-2 dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">user</span>
                                    //                 <ul class="dropdown-menu custom-user-drop">
                                    //                     <li class=" custom-user-drops"><a class="dropdown-item" href="#">LOG OUT</a></li>

                                    //                 </ul>
                                    //             </div>
                                    //     </a>
                                    // </ul>
                                    <ul class="navbar-nav custom-listnav ms-lg-5">

                                        <li>
                                            <a class="nav-last" href="/dashboard/profile">
                                                <div className="d-flex flex-column justify-content-center text-center custom-user-mo">
                                                    <img src={profile3} className="d-block me-3 custom-maskimg" alt="user" />
                                                </div>

                                                <div class="dropdown dash-1">
                                                    <span className='navbar-new-link-text-2 dropdown-toggle' type="button" alt="user" data-bs-toggle="dropdown" aria-expanded="false">{userEmail.substring(0, 4) + "..."}</span>

                                                    <ul class="dropdown-menu custom-user-drop">
                                                        <li class=" custom-user-drops "><a class="dropdown-item " href="/dashboard/profile">Dashboard</a></li>
                                                        <li class=" custom-user-drops"><a class="dropdown-item" href="#" onClick={Logout}>LOG OUT</a></li>
                                                    </ul>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                ) : (
                                    <>
                                        <ul class="navbar-nav custom-listnavs">
                                            <li>
                                                <div className='d-flex'>
                                                    <div className=' '>
                                                        <Link to="/login">
                                                            <button className="treasure-btn-2 active me-3 ">Login</button>
                                                        </Link>
                                                    </div>
                                                    <div className=''>
                                                        <Link to="/register">
                                                            <button className="treasure-btn-2 active ">Register</button>
                                                        </Link>
                                                    </div>
                                                </div>

                                            </li>
                                        </ul>



                                    </>

                                )
                            }
                            {/* <ul class="navbar-nav custom-listnav">
                            <li>
                                <a class="nav-last" href="#">
                                    <div className="d-flex flex-column justify-content-center text-center custom-user-mo">
                                        <img src={profile3} className="d-block me-3 custom-maskimg" alt="user" />

                                       
                                    </div>
                                     <div class="dropdown">
                                            <span className='navbar-new-link-text-2 dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">user</span>
                                            <ul class="dropdown-menu custom-user-drop">
                                                <li class=" custom-user-drops"><a class="dropdown-item" href="#">LOG OUT</a></li>

                                            </ul>
                                        </div>
                                </a>
                            </li>
                        </ul>
                        <div className='custom-nav-last-section'>
                            <GrLogout/>
                        </div> */}
                        </div>

                    </div>

                </nav>
            </div>

        </div>
    )
}

export default Navbar