import React from 'react'
import { Link } from 'react-router-dom';
import phone from '../assets/image/Group 89.png'
import igas from '../assets/image/335-Igas-Certificate-of-Analysis-page-0001.jpg'
import Bewertung from '../assets/image/Preis-Bewertung-ISE-Analyse-2_page-2.jpg'
import Bewertung1 from '../assets/image/Preis-Bewertung-ISE-Analyse-2_page-3.jpg'
import render12 from '../assets/image/RENDER-1-copy.webp';
// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';


function View() {
    return (
        <div className='App'>
            <div className='container-fluid'>
                <div className='custom-view'>
                    <div className=''>
                        <div className='row'>
                            <div className='col-lg-12 p-0'>
                                <div className="row justify-content-center align-items-center m-0">
                                    <div className="col-lg-11 ">
                                        <div className="pt-4">
                                            <a class="navbar-brand" href="/"><img src={render12}></img></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='custom-inside-view'>
                                    <div className='custom-google-swiper'>
                                        <Swiper

                                            slidesPerView={1}
                                            spaceBetween={10}
                                            pagination={{
                                                type: 'fraction',
                                            }}
                                            // pagination={{
                                            //     type: 'fraction',
                                            // }}
                                            breakpoints={{
                                                640: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                768: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                1024: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                            }}
                                            navigation={true}
                                            modules={[Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div class="row align-items-center justify-content-around">

                                                    <div class="col-lg-12 p-0">
                                                        <div class="custom-loremright text-center">
                                                            <img className="view-img-slide" src={igas}></img>
                                                        </div>

                                                    </div>

                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide> <div class="row align-items-center justify-content-around">

                                                <div class="col-lg-12 p-0">
                                                    <div class="custom-loremright  text-center">
                                                        <img className="view-img-slide" src={Bewertung}></img>
                                                    </div>

                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide> <div class="row align-items-center justify-content-around">

                                                <div class="col-lg-12 p-0">
                                                    <div class="custom-loremright text-center">
                                                        <img className="view-img-slide" src={Bewertung1}></img>
                                                    </div>

                                                </div>
                                            </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default View