
import '../assets/css/Footer.css';
import { Link } from 'react-router-dom';
import $ from "jquery";
import jQuery from "jquery";
import Navbar from "../separate/Navbar"
import React, { useEffect } from 'react';

function Privatepolicys() {
  useEffect(() => {
		// Scroll to the top of the page when component mounts
		window.scrollTo(0, 0);
  }, []);
  return (
    <div className='App privacy-nav-1'>
      <div className='container-fluid'>
      <Navbar />
        <div className='customer-privacy'>
          <div className='custom-container'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='custom-inside-privacy'>
                  <p>At CUP Token, accessible from <span>www.cuptoken.trade </span> , one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that are collected and recorded by CUP Token and how we use it.</p>
                  <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us <span>info@cuptoken.trade </span>.</p>
                  <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in CUP Token. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                  <div className=''>
                    <h3>CONSENT</h3>
                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                  </div>
                  <div className=''>
                    <h3>INFORMATION WE COLLECT</h3>
                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                    <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                    <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

                  </div>
                  <div className=''>
                    <p>How we use your information</p>
                    <p>We use the information we collect in various ways, including:</p>
                    <p>Provide, operate, and maintain our website</p>
                    <p>Improve, personalize, and expand our website</p>
                    <p>Understand and analyze how you use our website</p>
                    <p>Develop new products, services, features, and functionality</p>
                    <p>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</p>
                    <p>Send you emails</p>
                    <p>Find and prevent fraud</p>
                    <p>Log Files</p>
                    <p>CUP Token follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and are a part of hosting services' analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

                  </div>
                  <div className=''>
                    <h3>COOKIES AND WEB BEACONS</h3>
                    <p>Like any other website, CUP Token uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
                    <h3>Choice, Deletion, and Access</h3>
                    <p>If you need to update or delete information about yourself or your interactions with the Services or the Site, please contact us.</p>
                    <p>You may have options regarding the collection and use of information about yourself. You can refuse to provide certain information, but you may be unable to use certain Services or access certain features of the Site as a result.</p>
                    <h3>Communications:</h3>
                    <p>Please unsubscribe or change your communication preferences if you do not want to receive promotional messages from us.</p>
                    <h3>Browsers and mobile devices:</h3>
                    <p>Most browsers and devices have a help feature that will tell you how to prevent your browser or device from accepting new cookies, as well as how to have the browser notify you when you receive a new cookie.</p>
                  </div>
                  <div className=''>
                    <h3>ADVERTISING PARTNERS PRIVACY POLICIES</h3>
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of CUP Token.</p>
                    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on CUP Token, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
                    <p>Note that CUP Token has no access to or control over these cookies that are used by third-party advertisers.</p>
                  </div>
                  <div className=''>
                    <h3>THIRD-PARTY PRIVACY POLICIES</h3>
                    <p>CUP Token's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt out of certain options.</p>
                    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found on the browsers' respective websites.</p>
                  </div>
                  <div className=''>
                    <h3>CCPA PRIVACY RIGHTS (DO NOT SELL MY PERSONAL INFORMATION)</h3>
                    <p>Under the CCPA, among other rights, consumers have the right to:</p>
                    <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                    Request that a business delete any personal data about the consumer that a business has collected.
                    <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
                    <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us <span>info@cuptoken.trade</span> </p>
                  </div>
                  <div className=''>
                    <h3>GDPR Data Protection Rights</h3>
                    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                    <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                    <p>The right to rectification You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                    <p>The right to erasure You have the right to request that we erase your personal data, under certain conditions.</p>
                    <p>The right to restrict processing You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                    <p>The right to object to processing You have the right to object to our processing of your personal data, under certain conditions.</p>
                    <p>The right to data portability You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                  </div>
                  <div className=''>
                    <h3>Children's Information</h3>
                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                    <p>CUP Token does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                  </div>
                  <div className=''>
                    <h3>DATA RETENTION</h3>
                    <p>We keep information collected in accordance with this Privacy Policy for as long as is required to fulfill the relevant purposes described in this Privacy Policy, unless a longer retention period is required or permitted by law, such as for tax, accounting, fraud prevention, investigative, and dispute resolution purposes, or to establish or defend a Claim. We may anonymize personal information about you in some cases so that it cannot be used to identify you, in which case we may use such information indefinitely without further notice to you.</p>
                  </div>
                  <div className=''>
                    <h3>Changes to This Privacy Policy</h3>
                    <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
                  </div>
                  <div className=''>
                    <h3>CONTACT US</h3>
                    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us <span>info@cuptoken.trade</span> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privatepolicys