
import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeApiRequest } from "./config/axiosService";
import config from "./config/config";
import logo from './logo.svg';
import '../src/assets/css/Dashboard.css';
import { Link } from 'react-router-dom';
import $ from "jquery";
import moment from "moment";
import { LuFileSearch } from "react-icons/lu";

function Tradehistory() {
    const [buyTodayOrders, setBuyTodayOrders] = useState([]);
    const [buyRestOfTheDaysOrders, setBuyRestOfTheDaysOrders] = useState([]);
    const [bulkTodayOrders, setBulkTodayOrders] = useState([]);
    const [bulkRestOfTheDaysOrders, setBulkRestOfTheDaysOrders] = useState([]);
    // get login email
    const UserEmail = localStorage.getItem('email');

    const buyOrder = async () => {

        try {

            const params = {
                url: `getSelledItem`,
                method: "GET",
            };

            const response = await makeApiRequest(params);
            // console.log("response---", response);

            if (response.status === true) {
                if (response.tradeBuy) {
                    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

                    // Filter orders for today and rest of the days
                    const todayBuyOrders = response.tradeBuy.filter(order => order.date.split('T')[0] === today);

                    const restOfTheDaysBuyOrders = response.tradeBuy.filter(order => order.date.split('T')[0] !== today);

                    setBuyTodayOrders(todayBuyOrders);
                    setBuyRestOfTheDaysOrders(restOfTheDaysBuyOrders);
                }

                if (response.tradeBulk) {
                    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

                    // Filter orders for today and rest of the days
                    const todayBulkOrders = response.tradeBulk.filter(order => order.date.split('T')[0] === today);

                    const restOfTheDaysBulkOrders = response.tradeBulk.filter(order => order.date.split('T')[0] !== today);

                    setBulkTodayOrders(todayBulkOrders);
                    setBulkRestOfTheDaysOrders(restOfTheDaysBulkOrders);
                }
            } else {
                setBuyTodayOrders([]);
                setBuyRestOfTheDaysOrders([]);
                setBulkTodayOrders([]);
                setBulkRestOfTheDaysOrders([]);
            }
        } catch (error) {
            console.error("Error fetching Balance:", error);
        }

    }

    useEffect(() => {
        buyOrder();
        //set Time Interval
        const interval = setInterval(() => {
            buyOrder();
        }, 60000);

        return () => clearInterval(interval);
    }, [])



    return (
        <div className='APP'>

            <div className='container-fluid'>
                <div className='custom-tradehistory'>
                    <div className=''>
                        <div className='row'>
                            <div className='col-lg-12 custom-scroll-trade'>
                                <h2 style={{ color: "#BC9018" }}>Orders History</h2>
                                <div className='custom-inside-trade'>
                                    <h4>Fraction And Bulk History</h4>
                                    <div className='custom-tradenavtabs'>
                                        <div className='custom-tabbtns'>
                                            <nav>
                                                <div class="nav nav-tabs d-flex justify-content-around border-0" id="nav-tab" role="tablist">
                                                    <button class="nav-link active border-0 " id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Buy</button>
                                                    <button class="nav-link border-0 " id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Sell</button>

                                                </div>
                                            </nav>
                                        </div>

                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <ul class="nav nav-pills mb-3 custom-trade-buy-1" id="pills-tab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"> FRACTION</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Bulk</button>
                                                    </li>

                                                </ul>
                                                <div class="tab-content" id="pills-tabContent">
                                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                                            <div class="accordion-item">

                                                                <h2 class="accordion-header" id="flush-headingOne">
                                                                    <button class="accordion-button collapsed custom-todaybtn text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                        Today
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                                    <div class="accordion-body px-0 " >
                                                                        {buyTodayOrders && buyTodayOrders.length > 0 ? (
                                                                            buyTodayOrders.map((data) => (
                                                                                <div className='custom-accordian-body active'>
                                                                                    <div className='row d-flex align-items-centers'>
                                                                                        <div className='col-lg-6 col-8'>
                                                                                            <div className='custom-today-accordian-1'>
                                                                                                <p className='mb-auto'>TXN-Id</p>
                                                                                                <p className='mb-auto'>ProductId</p>
                                                                                                <p className='mb-auto'>Purchased Quantity</p>
                                                                                                <p className='mb-auto'>Coin</p>
                                                                                                <p className='mb-auto'>Amount</p>
                                                                                                <p className='mb-auto'>Date and Time</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-lg-6 col-4'>
                                                                                            <div className='custom-today-accordian-1 text-end'>
                                                                                                <React.Fragment key={data._id}>
                                                                                                    <p className='mb-auto'>{data._id}</p>
                                                                                                    <p className='mb-auto'>{data.productId}</p>
                                                                                                    <p className='mb-auto'>{data.quantity}</p>
                                                                                                    <p className='mb-auto'>{data.network}</p>
                                                                                                    <p className='mb-auto'>{data.amount}</p>
                                                                                                    <p className='mb-auto'>{data.date.split('T')[0]} - {moment.utc(data.time).format("hh:mm A")}</p>
                                                                                                </React.Fragment>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className='text-center'>
                                                                                <LuFileSearch style={{ fontSize: "45px", color: "#BC9018" }} />
                                                                                <p className='mb-auto '>No Orders Today</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="accordion-item">
                                                                <h2 class="accordion-header" id="flush-headingTwo">
                                                                    <button class="accordion-button collapsed custom-todaybtn text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                                        Rest Of The Day
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                                    <div class="accordion-body px-0 " >
                                                                        {buyRestOfTheDaysOrders && buyRestOfTheDaysOrders.length > 0 ? (
                                                                            buyRestOfTheDaysOrders.map((data) => (
                                                                                <div className='custom-accordian-body active'>
                                                                                    <div className='row d-flex align-items-centers'>
                                                                                        <div className='col-lg-6 col-8'>
                                                                                            <div className='custom-today-accordian-1'>
                                                                                                <p className='mb-auto'>TXN-Id</p>
                                                                                                <p className='mb-auto'>ProductId</p>
                                                                                                <p className='mb-auto'>Purchased Quantity</p>
                                                                                                <p className='mb-auto'>Coin</p>
                                                                                                <p className='mb-auto'>Amount</p>
                                                                                                <p className='mb-auto'>Date and Time</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-lg-6 col-4'>
                                                                                            <div className='custom-today-accordian-1 text-end'>
                                                                                                <React.Fragment key={data._id}>
                                                                                                    <p className='mb-auto'>{data._id}</p>
                                                                                                    <p className='mb-auto'>{data.productId}</p>
                                                                                                    <p className='mb-auto'>{data.quantity}</p>
                                                                                                    <p className='mb-auto'>{data.network}</p>
                                                                                                    <p className='mb-auto'>{data.amount}</p>
                                                                                                    <p className='mb-auto'>{data.date.split('T')[0]} - {moment.utc(data.time).format("hh:mm A")}</p>
                                                                                                </React.Fragment>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className='text-center'>
                                                                                <LuFileSearch style={{ fontSize: "45px", color: "#BC9018" }} />
                                                                                <p className='mb-auto '>No Orders Today</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                                            <div class="accordion-item">

                                                                <h2 class="accordion-header" id="flush-headingOne">
                                                                    <button class="accordion-button collapsed custom-todaybtn text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                        Today
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                                    <div class="accordion-body px-0 " >
                                                                        {bulkTodayOrders && bulkTodayOrders.length > 0 ? (
                                                                            bulkTodayOrders.map((data) => (
                                                                                <div className='custom-accordian-body active'>
                                                                                    <div className='row d-flex align-items-centers'>
                                                                                        <div className='col-lg-6 col-8'>
                                                                                            <div className='custom-today-accordian-1'>
                                                                                                <p className='mb-auto'>TXN-Id</p>
                                                                                                <p className='mb-auto'>ProductId</p>
                                                                                                <p className='mb-auto'>Purchased Quantity</p>
                                                                                                <p className='mb-auto'>Coin</p>
                                                                                                <p className='mb-auto'>Amount</p>
                                                                                                <p className='mb-auto'>Date and Time</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-lg-6 col-4'>
                                                                                            <div className='custom-today-accordian-1 text-end'>
                                                                                                <React.Fragment key={data._id}>
                                                                                                    <p className='mb-auto'>{data._id}</p>
                                                                                                    <p className='mb-auto'>{data.productId}</p>
                                                                                                    <p className='mb-auto'>{data.quantity}</p>
                                                                                                    <p className='mb-auto'>{data.network}</p>
                                                                                                    <p className='mb-auto'>{data.amount}</p>
                                                                                                    <p className='mb-auto'>{data.date.split('T')[0]} - {moment.utc(data.time).format("hh:mm A")}</p>
                                                                                                </React.Fragment>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className='text-center'>
                                                                                <LuFileSearch style={{ fontSize: "45px", color: "#BC9018" }} />
                                                                                <p className='mb-auto '>No Orders Today</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="accordion-item">
                                                                <h2 class="accordion-header" id="flush-headingTwo">
                                                                    <button class="accordion-button collapsed custom-todaybtn text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                                        Rest Of The Day
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                                    <div class="accordion-body px-0 " >
                                                                        {bulkRestOfTheDaysOrders && bulkRestOfTheDaysOrders.length > 0 ? (
                                                                            bulkRestOfTheDaysOrders.map((data) => (
                                                                                <div className='custom-accordian-body active'>
                                                                                    <div className='row d-flex align-items-centers'>
                                                                                        <div className='col-lg-6 col-8'>
                                                                                            <div className='custom-today-accordian-1'>
                                                                                                <p className='mb-auto'>TXN-Id</p>
                                                                                                <p className='mb-auto'>ProductId</p>
                                                                                                <p className='mb-auto'>Purchased Quantity</p>
                                                                                                <p className='mb-auto'>Coin</p>
                                                                                                <p className='mb-auto'>Amount</p>
                                                                                                <p className='mb-auto'>Date and Time</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-lg-6 col-4'>
                                                                                            <div className='custom-today-accordian-1 text-end'>
                                                                                                <React.Fragment key={data._id}>
                                                                                                    <p className='mb-auto'>{data._id}</p>
                                                                                                    <p className='mb-auto'>{data.productId}</p>
                                                                                                    <p className='mb-auto'>{data.quantity}</p>
                                                                                                    <p className='mb-auto'>{data.network}</p>
                                                                                                    <p className='mb-auto'>{data.amount}</p>
                                                                                                    <p className='mb-auto'>{data.date.split('T')[0]} - {moment.utc(data.time).format("hh:mm A")}</p>
                                                                                                </React.Fragment>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className='text-center'>
                                                                                <LuFileSearch style={{ fontSize: "45px", color: "#BC9018" }} />
                                                                                <p className='mb-auto '>No Orders Today</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                <p class="text-center">UPCOMING !</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tradehistory