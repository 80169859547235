
import '../assets/css/Footer.css';
import { Link } from 'react-router-dom';
import $ from "jquery";
import jQuery from "jquery";
import Navbar from "../separate/Navbar"
import React, { useEffect } from 'react';


function Terms() {
    useEffect(() => {
		// Scroll to the top of the page when component mounts
		window.scrollTo(0, 0);
  }, []);
    return (
        <div className='App terms-nav-1'>
            <div className='container-fluid'>
            <Navbar />
                <div className='custome-terms'>
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custome-terms-inside'>
                                    <h3>Terms and Conditions</h3>
                                    <p>These terms (“Terms”) govern the use of services (“Services”) provided by CUP Token and all platforms affiliated with CUP Token (“CUP Token”, “we”, “us”, “our”). These terms are binding on the users of CUP Token and its Platforms (“you”, “your”, “user(s)”) which includes, but is not limited to, any person or entity who accesses, browses or uses in any form the Services provided by us.</p>
                                    <p>By accessing the Services provided by our Platforms, you expressly consent to the Terms and Conditions contained herein and understand its meaning and intent. Please read these Terms and Conditions and our Privacy Policy carefully before using our Platforms or the Services provided by us. All transactions are final, and there shall be no refunds or cancellations, except as may be required by applicable law or regulation or unless expressly agreed upon by us in writing.</p>
                                    <div className=''>
                                        <h3>GENERAL</h3>
                                        <p>1. You agree and acknowledge that you are solely responsible for your use of the Platforms or Services and that we are not liable for any harm, loss, or damages that may result from your use of the Services. This includes, but is not limited to, any damages caused by incorrect use of the Services, such as faulty transactions, network and technical failures, unauthorized access to your wallets, legal and regulatory issues and consequences, or fraud by third parties.</p>
                                        <p>2. You agree and acknowledge that you will use the Platforms or Services in compliance with all applicable laws and regulations and that you will not use the Services for any unlawful or prohibited purposes. This includes, but is not limited to, using the Services (i) to fund terrorism or other criminal activity; (ii) to evade any export restrictions or economic sanctions; (iii) to conduct illegal money transmission, currency exchange, or money laundering; or (iv) if you are restricted or prohibited by any applicable laws and regulations from using the Services. We reserve the right to terminate your access to the Services at any time if we determine that you are in breach of this provision.</p>
                                        <p>Due to regulatory restrictions,the <span>Services provided by CUP Token and its Platforms are not available to residents or citizens of the United States of America or any of its territories</span>. If you are located in or a citizen of the United States of America, you are prohibited from accessing, browsing or using our Services in any form. By using our Services, you represent and warrant that you are not located in or a citizen of the United States of America or any of its territories. We reserve the right to terminate your access to our Services at any time if we determine that you are in violation of this provision.</p>
                                    </div>
                                    <div className=''>
                                        <h3>RISKS ASSOCIATED WITH USE SERVICES</h3>
                                        <p>1. You acknowledge and agree that there are certain risks involved in using the Services and purchasing, owning and using CUP Token for the receipt of Services. These risks include, but are not limited to, technical failures, network congestion, legal and regulatory uncertainties, market volatility, fraud and theft.</p>
                                        <p>2. By purchasing CUP Token, you confirm that you have read and understood these Terms and that you are willing to assume these risks. You also represent and warrant that you are legally capable and authorized to purchase CUP Token and to use the Services in your jurisdiction.</p>
                                        <p>3. You are solely responsible for securing your wallet, vault or other storage mechanisms that you use to receive and hold CUP Token purchased, as well as any private key(s) or other credentials required to access such storage mechanism(s). If you lose your private key(s) or other access credentials, you may lose access to your CUP Token and we will not be liable for any such loss or any costs, expenses or damages arising from it.</p>
                                        <p>4. You must make the payment for purchasing CUP Token to the multi-signature address specified by us. If you send the payment to any other address or entity that is not specified by us, you acknowledge and agree that we will not receive such payment and we will have no obligation to deliver any CUP Token to you. We will also have no obligations of any kind to you regarding such payment.</p>
                                        <p>5. We will only deliver CUP Token to you after we have received your payment in full and the amount quoted for your purchase request, i.e., the Purchase Price, has been fully paid. We will consider the Purchase Price to be paid in full as soon as we receive three network confirmations of the transaction. We reserve the right to declare your purchase request void and refuse or cancel your payment at any time at our sole and absolute discretion.</p>
                                        <p>6. We reserve the right to modify any timelines described herein, at our sole and absolute discretion, to account for network congestion or other technical challenges that may affect the delivery of CUP Token or the use of the Services.</p>
                                        <p>7. We will not be responsible for any amount received from an incompatible wallet or Smart Contract account that results in “lost” CUP Token that cannot be withdrawn from the account. You must ensure that you use a compatible wallet or Smart Contract account to receive and hold CUP Token purchased from us.</p>
                                        <p>8. All purchases of CUP Token from us are final and non-refundable, except as may be required by applicable law or regulation or unless expressly agreed upon by us in writing. You bear all risks related to any refund of payment made during the CUP Token sale and we will not be liable for any such refund or any costs, expenses or damages arising from it.</p>
                                        <p>9. You acknowledge and agree that any amount paid for CUP Token is exclusive of all applicable taxes. You are solely responsible for determining what taxes apply to your purchase of CUP Token, including but not limited to value added tax, sales tax, use tax or any other similar tax, and for withholding, collecting, reporting and remitting the correct taxes to the appropriate tax authorities. We will not be responsible for withholding, collecting, reporting or remitting any sales, use, value added or similar tax arising from your purchase of CUP Token.</p>
                                        <p>10. You acknowledge and agree that purchasing CUP Token does not grant you any rights or ownership over the Services or the Platforms provided by us. You also acknowledge and agree that CUP Token is not a security, commodity, currency or any other form of financial instrument and does not represent any share, stake or equity in us or our Platforms.</p>
                                        <p>11. You acknowledge and agree that the value of CUP Token may fluctuate significantly over time due to various factors beyond our control, such as supply and demand, market conditions, regulatory changes, technical developments, hacking attacks or other malicious activities. We do not guarantee or warrant the stability or liquidity of CUP Token and we will not be liable for any loss of value or inability to sell or exchange CUP Token.</p>
                                        <p>12. You acknowledge and agree that using the Services may involve interacting with third-party platforms, protocols, applications or services that are not owned or controlled by us (“Third-Party Services”). We do not endorse or assume any responsibility for such Third-Party Services and we will not be liable for any harm, loss or damages arising from your use of such Third-Party Services. You are solely responsible for reviewing the terms and conditions and privacy policies of such Third-Party Services before using them.</p>

                                    </div>
                                    <div className=''>
                                        <h3>DENIAL OF ACCESS AND TERMINATION</h3>
                                        <p>We reserve the right to terminate the use of our Platforms and Services and restrict you from such use if you are found to be in breach of these Terms.</p>
                                        <p>In case of termination, we shall not liable for any loss of information, damage or any other loss of similar nature. </p>
                                    </div>
                                    <div className=''>
                                        <h3>USER CODE OF CONDUCT</h3>
                                        <h3>Obligations and Prohibited Actions:</h3>
                                        <p>* You shall not use any material that is false, offensive, harmful, obscene or pornographic, hateful, defamatory, libelous, derogatory, abusive, threatening, or in any other way illegal or infringing upon the legal rights of others, or in violation of this clause.</p>
                                        <p>* You shall be civil and respectful of other Users and shall not display toxic, trolling or behaviors that lead to harassment of any kind, including but not exclusively mental or emotional.</p>
                                        <p>* You shall not impersonate any other natural or legal person, and use their identification or contact details, create accounts in their name, or falsely state or misrepresent any association or affiliation with such persons.</p>
                                        <p>* You shall not interfere with security-related features of the Platforms, including but not limited to: (i) disabling or circumventing features that prevent or limit use or copying of any content; or (ii) reverse engineering or otherwise attempting to discover the source code of any portion of the Platforms except to the extent that the activity is expressly permitted by applicable law. Any attempt at such interference shall attract a ban from the Platforms. </p>
                                        <p>* You shall not collect, generate, affect in any way usernames or email addresses using bots or any other methods or sell or transfer the User profile to any person or entity without our written permission.</p>
                                        <p>* Spamming which shall include but is not restricted to excessive use of capital letters; repetition of text, and exclamation points on the site interferes with the use of service and is prohibited.</p>
                                        <p>* You shall not use CUP Token for any illegal or unauthorized purposes, such as funding terrorism or other criminal activities, evading sanctions or taxes, laundering money, or engaging in unlawful gambling or speculation.</p>
                                        <p>* You shall not use CUP Token Platforms or Services in a way that harms, disrupts, interferes with, or violates the rights of other Users, third parties, or us. This includes, but is not limited to: (i) spreading malware, viruses, spyware, or other harmful software; (ii) sending unsolicited messages, advertisements, or spam; (iii) engaging in fraudulent activities or scams; (iv) infringing on the intellectual property rights of others; (v) posting abusive, hateful, defamatory, libelous, derogatory, threatening.</p>
                                    </div>
                                    <div className=''>
                                        <h3>ISSUANCE AND USE OF CUP TOKEN</h3>
                                        <p>The purpose of the CUP Token's tokens (“CUP Token”) is to be used in a blockchain platform developed by us for the rendering of the Services. The Services include, but are not limited to, enabling decentralized governance, voting, and participation in the CUP Token Network and its affiliated platforms and applications.</p>
                                        <p>The purchase, ownership, receipt or possession of CUP Token carries no rights, express or implied, other than the right to use CUP Token as a means to enable usage of and interaction with the CUP Token Network, if successfully completed and deployed. You understand and accept that CUP Token do not represent or confer any ownership right or stake, share, security, or equivalent rights, or any right to receive future revenue shares, intellectual property rights or any other form of participation in or relating to us, the Platforms or any other affiliates of CUP Token and/or us and/or any of our corporate affiliates. The only rights that CUP Token carry is those relating to the provision and receipt of Services, subject to limitations and conditions of these Terms.</p>
                                        <p>The CUP Token are not and are not intended to be a digital currency, security, commodity or any kind of financial instrument. The information provided is not intended to form the basis for any investment decision and no specific recommendations. You should conduct your own research and analysis before purchasing CUP Token and seek independent professional advice if necessary. We shall not be responsible for any direct or consequential loss or damage of any kind whatsoever arising directly or indirectly from (i) reliance on any information provided, (ii) any error, omission or inaccuracy in any such information; or (iii) any action resulting from such information.</p>
                                        <p>By purchasing, owning, and using CUP Token, you expressly acknowledge and assume the risks concerning losing access to tokens due to loss of Private Key(s), custodial error or purchaser error and risks related to the protocol; Risk of mining attacks; risk of hacking and security weaknesses; risks associated with markets for tokens; risk of uninsured losses; risks associated with uncertain regulations and enforcement actions; risks arising from taxation; risk of competing platforms; risk of insufficient interest in the platform or distributed applications; risks associated with the development and maintenance of the Platforms;; risk of dissolution of the CUP Token or Platform; risks arising from lack of governance rights and any other such unanticipated risks. You also acknowledge and agree that we do not guarantee or warrant the availability, functionality, performance, security, reliability, or quality of the CUP Token Network or its affiliated platforms and applications. We reserve the right to migrate the CUP Token to another protocol in the future, in our reasonable discretion, for the operation of the Platform and Services.</p>
                                    </div>
                                    <div className=''>
                                        <h3>THIRD-PARTY SERVICES </h3>
                                        <p>We may use third-party sites, services or resources and make no representation or warranties of any kind regarding any site, service, or resource provided by a third party, including, but not limited to, any representations or warranties regarding its accuracy, completeness, usefulness, security, or legitimacy. </p>
                                        <p>We have no control over third-party sites, services, and resources, and we are not responsible for any harm, loss, or damage that may arise from your use of such services. If you decide to access any of the third-party sites, services, or resources, you do so entirely at your own risk and subject to the terms and conditions for such sites, services, or resources.</p>
                                    </div>
                                    <div className=''>
                                        <h3>REPRESENTATIONS AND WARRANTIES</h3>
                                        <p>1.	You represent and warrant that you have read and understood these terms and conditions and you acknowledge and agree that there are risks associated with the services and acquisition of CUP Token, owning CUP Token and using CUP Token for the provision or receipt of services in the ecosystem including (but not necessarily limited to) the risks described above;</p>
                                        <p>2.	You represent and warrant that you have a sufficient understanding of technical and business matters (including those that relate to the Services) cryptographic tokens, token storage mechanisms (such as token wallets), and blockchain technology to understand these Terms and to appreciate the risks and implications of purchasing the CUP Token;</p>
                                        <p>3.	You represent and warrant that you understand the restrictions and risks associated with the creation of CUP Token as set forth herein, and acknowledges and assumes all such risks;</p>
                                        <p>4.	You represent and warrant that you have obtained sufficient information about the CUP Token, the Services and the Platforms to make an informed decision to purchase the CUP Token;</p>
                                        <p>5.	You represent and warrant that you understand that the CUP Token confer only the right to provide and receive Services and do not confer other rights of any form with respect to our Platforms, including, but not limited to, any ownership, distribution, redemption, liquidation, proprietary (including all forms of intellectual property), or other financial or legal rights;</p>
                                        <p>6.	You represent and warrant that you are purchasing CUP Token solely for the purpose of receiving Services, participating in our project, and supporting the development, testing, deployment and operation of CUP Token;</p>
                                        <p>7.	You represent and warrant that you are aware of the commercial risks associated with our project, and you are not purchasing CUP Token for any other purposes, including, but not limited to, any investment, speculative or financial purpose;</p>
                                        <p>8.	You represent and warrant that your purchase of CUP Token complies with applicable laws and regulations in your jurisdiction, including, but not limited to: (i) legal capacity and any other threshold requirements in your jurisdiction for the purchase of the CUP Token and entering into contracts with us; (ii) any foreign exchange or regulatory restrictions applicable to such purchase; and (iii) any governmental or other consents that may need to be obtained;</p>
                                        <p>9.	You represent and warrant that you will comply with any applicable tax obligations in your jurisdiction arising from your purchase of CUP Token;</p>
                                        <p>10.	You represent and warrant that, if you are purchasing CUP Token on behalf of any entity, you are authorized to accept these Terms on such entity’s behalf and that such entity will be responsible for breach of these Terms by you or any other employee or agent of such entity;</p>
                                        <p>11.	You represent and warrant that you are not resident, domiciled, or purchasing CUP Token from a jurisdiction that prevents the acquisition of CUP Token.</p>
                                        <p>12.	You represent and warrant that you have obtained independent legal advice with respect to same before accepting these Terms, and, in accepting these Terms.</p>
                                        <p>13.	You represent and warrant that you are not a citizen or resident of a geographic area in which access to or use of the Services or the acceptance of delivery of the CUP Token is prohibited by applicable law, decree, regulation, treaty or administrative act, and if your country of residence or other circumstances change such that the above representations are no longer accurate, you will immediately cease using the Services;</p>
                                        <p>14. If you are registering to use the Services on behalf of a legal entity, you further represent and warrant that:
                                            <br />(i) such legal entity is duly organized and validly existing under the applicable laws of the jurisdiction of its organization; and
                                            <br />(ii) you are duly authorized by such legal entity to act on its behalf.

                                        </p>
                                        <p>You also represent and warrant that none of the funds being used to purchase the Tokens are to your knowledge proceeds obtained or derived directly or indirectly as a result of illegal activities, and the funds being used to purchase the Tokens which will be advanced by you under these Terms will not represent proceeds of crime as per the anti-money laundering and countering the financing of terrorism (AML/CFT) and you acknowledge that we may in the future be required by law to disclose your name and other information relating to these Terms, on a confidential basis, pursuant to the (AML/CFT), and to the best of your knowledge, none of the funds to be provided by you are being tendered on behalf of a person or entity who has not been identified to you, and you will promptly notify us if you discover that any of such representations cease to be true, and will promptly provide us with all necessary information in connection therewith.
                                            Here is a possible addition of a sentence in relation to CUP Token:</p>
                                        <p>15. You represent and warrant that you understand and agree that CUP Token is a decentralized autonomous organization that operates on the basis of smart contracts and blockchain technology, and that you will not hold us or any of our affiliates liable for any actions, decisions, or outcomes of CUP Token that may affect your use of the Services or the value of the Tokens.</p>

                                    </div>
                                    <div className=''>
                                        <h3>INDEMNIFICATION</h3>
                                        <p>1.	To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless CUP Token and its respective past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (the “Company Parties”) from and against all claims, demands, actions, damages, losses, costs and expenses (including attorneys’ fees) that arise from or relate to:
                                            <p>(i) your purchase or use of CUP Token;</p>
                                            <p>(ii) your responsibilities or obligations under these Terms;</p>
                                            <p>(iii) your violation of any of these Terms; or</p>
                                            <p> (iv) your violation of any rights of any other person or entity. We reserve the right to exercise sole control over the defence, at your expense, of any claim subject to indemnification under this Section. This indemnity is in addition to, and not in lieu of, any other indemnities set forth in any written agreement with you.</p>
                                           
                                           <p> You also agree  to cooperate fully with us in the defence of any claim that is the subject of your obligations under this Section. You will not settle any claim without our prior written consent.</p>
                                           </p>
                                    </div>
                                    <div className=''>
                                        <h3>DISCLAIMERS</h3>
                                        <p>1.	YOU REPRESENT AND WARRANT AND COVENANT THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS AND CONDITIONS TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS OTHERWISE SPECIFIED IN WRITING BY US, THE CUP TOKEN ARE SOLD ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, AND CUP TOKEN EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES AS TO THE CUP TOKEN, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT; WE DO NOT REPRESENT OR WARRANT THAT THE CUP TOKEN ARE RELIABLE, CURRENT OR ERROR-FREE, MEET YOUR REQUIREMENTS, OR THAT DEFECTS IN THE CUP TOKEN WILL BE CORRECTED; AND WE (III) CUP TOKEN CAN NOT AND DOES NOT REPRESENT OR WARRANT THAT THE CUP TOKEN OR THE DELIVERY MECHANISM FOR CUP TOKEN ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
                                        <p>2.	THESE TERMS DO NOT CONSTITUTE A PROSPECTUS, AN OFFER DOCUMENT OF ANY SORT, OR ARE INTENDED TO CONSTITUTE AN OFFER OF SECURITIES OR A SOLICITATION FOR INVESTMENT IN SECURITIES IN ANY JURISDICTION. IF YOU DECIDE TO PARTICIPATE AND PURCHASE CUP TOKEN, PLEASE NOTE THAT YOUR CONTRIBUTION DOES NOT REPRESENT, CONSTITUTE OR INVOLVE THE EXCHANGE OF CRYPTOCURRENCIES FOR ANY FORM OF SECURITIES, INVESTMENT UNITS AND/OR FORM OF ORDINARY SHARES IN THE PROJECT, ON ANY PLATFORM, IN CUP TOKEN, OR ANY OTHER RELATED COMPANY.</p>
                                        <p>3.	THE CUP TOKEN ARE NOT INTENDED TO CONSTITUTE SECURITIES AND/OR COLLECTIVE INVESTMENT UNITS IN ANY PROJECT, PLATFORM OR COMPANY. THE CUP TOKEN HOLDER DOES NOT RECEIVE ANY FORM OF DIVIDEND OR OTHER REVENUE RIGHTS, NOR DOES IT REPRESENT ANY PARTICIPATION IN A PROFIT-SHARING SCHEME. THESE TERMS AND CONDITIONS IN ANY PART THEREOF AND ANY COPY THEREOF MUST NOT BE TRANSMITTED TO ANY COUNTRY WHERE DISTRIBUTION OR DISSEMINATION OF THESE DOCUMENTS AND ITS INFORMATION IS PROHIBITED OR RESTRICTED. NO REGULATORY AUTHORITY HAS EXAMINED OR APPROVED TO THIS DATE OF ANY OF THE INFORMATION SET OUT IN THIS DOCUMENT. THE PUBLICATION, DISTRIBUTION OR DISSEMINATION OF THESE TERMS DO NOT IMPLY THE APPLICABLE LAWS.</p>
                                        <p>4.	REGULATORY REQUIREMENTS OR RULES HAVE BEEN COMPLIED WITH TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAWS, REGULATIONS AND RULES, CUP TOKEN, ITS FOUNDERS, TEAM MEMBERS AND ANY THIRD PARTY INVOLVED IN THE PROJECT SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR OTHER LOSSES OF ANY KIND, IN TORT, CONTRACT OR OTHERWISE (INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE, INCOME OR PROFITS, AND LOSS OF USE OR DATA), ARISING OUT OF OR IN CONNECTION WITH ANY ACCEPTANCE OF OR RELIANCE ON THESE TERMS, OR ANY PART THEREOF AND/OR INFORMATION PROVIDED ON THE WEB PAGE HTTPS://CUP TOKEN.io/D-SWAP.io/D-NFT.io/D-WALLET.io/ (and not listed platforms on here). </p>
                                        <p>5.	ALL STATEMENTS CONTAINED IN THESE TERMS, WEBPAGE, STATEMENTS MADE IN PRESS RELEASES OR IN ANY PLACE ACCESSIBLE BY THE PUBLIC AND ORAL STATEMENTS THAT MAY BE MADE BY CUP TOKEN, ITS FOUNDERS, TEAM MEMBERS AND ANY THIRD PARTY INVOLVED IN THE PROJECT AND ACTING ON BEHALF OF CUP TOKEN, THAT ARE NOT STATEMENTS OF HISTORICAL FACT CONSTITUTE “FORWARD-LOOKING STATEMENTS”. ALL STATEMENTS REGARDING THE COMPANY.</p>
                                        <p>6.	NEITHER CUP TOKEN, ITS FOUNDERS, TEAM MEMBERS, ANY THIRD PARTY INVOLVED IN THE PROJECT NOR ANY OTHER PERSON REPRESENTS, WARRANTS AND/OR UNDERTAKES THAT THE ACTUAL FUTURE RESULTS, PERFORMANCE OR ACHIEVEMENTS OF CUP TOKEN WILL BE AS DISCUSSED IN THESE “FORWARD-LOOKING STATEMENTS.” NO INFORMATION IN THESE TERMS SHOULD BE CONSIDERED TO BE BUSINESS, LEGAL, FINANCIAL OR ADVICE REGARDING CONTRIBUTION OR PARTICIPATION TO THE DEVELOPMENT OF THE CUP TOKEN NETWORK AND ANY OF ITS PROJECTS. CUP TOKEN DOES NOT MAKE OR INTENDS TO MAKE, AND HEREBY DISCLAIMS, ANY REPRESENTATION, WARRANTY OR UNDERTAKING IN ANY FORM WHATSOEVER TO ANY ENTITY OR PERSON, INCLUDING ANY REPRESENTATION, WARRANTY OR UNDERTAKING IN RELATION TO THE TRUTH, ACCURACY, AND COMPLETENESS OF ANY OF THE INFORMATION SET OUT IN THESE TERMS. THE INFORMATION CONTAINED ON THESE TERMS AND CONDITIONS ARE OF DESCRIPTIVE NATURE ONLY. YOU SHOULD NOT RELY ON THEM AS A BASIS FOR MAKING ANY DECISIONS OR ACTIONS REGARDING THE PURCHASE OR USE OF CUP TOKEN OR THE SERVICES. YOU SHOULD CONDUCT YOUR OWN DUE DILIGENCE AND SEEK INDEPENDENT PROFESSIONAL ADVICE BEFORE PURCHASING OR USING CUP TOKEN OR THE SERVICES.</p>
                                       
                                    </div>
                                    <div className=''>
                                        <h3>RELEASE</h3>
                                        <p>To the fullest extent permitted by applicable law, you release us and our Company Parties from responsibility, liability, claims, demands and/or damages (actual and consequential) of every kind and nature, known and unknown (including, but not limited to, claims of negligence), arising out of or related to disputes between users and the acts or omissions of third parties. You expressly waive any rights you may have under any other statute or common law principles that would otherwise limit the coverage of this release to include only those claims that you may know or suspect to exist in your favour at the time of agreeing to this release.</p>
                                    </div>
                                    <div className=''>
                                        <h3>LIMITATION OF LIABILITY</h3>
                                        <p>1.	To the fullest extent permitted by applicable law, in no event we or any of the Cup Token Parties shall be liable for any indirect, special, incidental, consequential, or exemplary damages of any kind (including, but not limited to, where related to loss of revenue, income or profits, loss of use or data, or damages for business interruption) arising out of or in any way related to the sale or use of the CUP Token or otherwise related to these terms, regardless of the form of action, whether based in contract, tort (including, but not limited to, simple negligence, whether active, passive or imputed), or any other legal or equitable theory (even if the party has been advised regarding the possibility of such damages and regardless of whether such damages were foreseeable). </p>
                                        <p>2.	In no event will the joint aggregate liability of the CUP Token DAO Parties and us, whether in contract, warranty, tort (including negligence, whether active, passive or imputed), or other theory, arising out of or relating to these terms or the use of or inability to use the CUP Token, exceed the amount you paid to CUP Token DAO for the CUP Token. These limitations are essential to enable us to offer the CUP Token at a reasonable price and to protect our interests. You acknowledge and agree that you have read and understood these limitations and that they are fair and reasonable.</p>
                                    </div>
                                    <div className=''>
                                        <h3>GOVERNING LAW AND DISPUTE RESOLUTION</h3>
                                        <p>Any claims, disputes and controversies arising out of these Terms or in connection with the access or use of CUP Token Court shall be settled in the CUP Token Court following the dispute process established herein. </p>
                                        <p>Notwithstanding the above, if a party wished to bring such a claim, dispute or controversy in court, it shall do so in the Court of the city of Victoria (Marshal Islands) and these Terms shall be governed by and construed and enforced in accordance with the laws of Marshal Islands. However, we retain the right to bring any suit, action or proceeding against You, in your country of residence or any other relevant country. You agree to waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                                        <p>Waiver of Class Action - You waive off any/all right of class action lawsuit, class-wide arbitration and/or in any other proceeding in which either party acts or proposes to act in a representative capacity shall only bring claims in an individual capacity through individual arbitration. </p>
                                        <p>Any cause arising out of these Terms shall commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.</p>
                                        <p>By using CUP Token Court, you agree to abide by its rules and procedures and to respect its decisions. You also acknowledge that CUP Token Court is an experimental platform that may have technical issues or errors and that you use it at your own risk.</p>
                                    </div>
                                    <div className=''>
                                        <h3>SEVERABILITY</h3>
                                        <p>If any term, clause or provision of these Terms is held unlawful, void or unenforceable, then that term, clause or provision will be severable from these Terms and will not affect the validity or enforceability of any remaining part of that term, clause or provision, or any other term, clause or provision of these Terms.</p>
                                        <p>I can help you rewrite the text as you requested. Here is the rewritten text:</p>
                                    
                                    </div>
                                    <div className=''>
                                        <h3>MISCELLANEOUS</h3>
                                        <p>1.	Entire Agreement- These Terms (and the documents referred to herein) constitute the entire agreement relating to your purchase of CUP Token. We may assign rights and obligations under these Terms. Our failure to exercise or enforce any right or provision of these Terms will not operate as a waiver of such right or provision.</p>
                                        <p>2.	Rights Reserved - We reserve the right to modify, update and/or change the Terms contained herein from time to time. Such changes shall be reflected in our Terms and Conditions, and continuing usage shall imply acceptance of the new Terms. </p>
                                        <p>3.	We shall not be liable for any delay or failure to perform any obligation under these Terms where the delay or failure results from any cause beyond reasonable control. </p>
                                        <p>4.	Purchasing CUP Token from us does not create any form of partnership, joint venture, or any other similar relationship between you and us. Except as otherwise provided herein, these Terms are intended solely for the benefit of you and are not intended to confer third-party beneficiary rights upon any other person or entity. </p>
                                        <p>5.	You agree and acknowledge that all agreements, notices, disclosures, and other communications that we provide to you, including these Terms, will be provided in electronic form.</p>
                                        <p>6.	You also agree and acknowledge that CUP Token is a decentralized platform that aims to empower individuals and communities through digital innovation and collaboration. By purchasing CUP TOKEN , you support the vision and mission of CUP Token and its community.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms