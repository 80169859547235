import React, { useEffect, useState } from 'react';
import { makeApiRequest } from "../config/axiosService";
import config from '../config/config';
import moment from "moment"
import { useLocation } from 'react-router-dom';
import { LuFileSearch } from "react-icons/lu";
import { data } from 'jquery';

function Cryptotransaction() {
    const [withdrawTransactions, setWithdrawTransactions] = useState([]);
    const [deposit, setDeposit] = useState([])
    const [selectedType, setSelectedType] = useState('deposit');
    const [currentBuyPage, setCurrentBuyPage] = useState(1);
    const itemsPerPage = 5;
    const location = useLocation()

    const transactionWithdrawDeposit = async (e) => {
        try {
            if (selectedType === 'withdraw') {
                let params = {
                    url: 'getwithdraw',
                    method: "GET",
                }
                const response = await makeApiRequest(params);
                // console.log("withdraw--", response);
                if (response.status == true) {
                    setWithdrawTransactions(response.data);
                } else {
                    setWithdrawTransactions([])
                }
            } else if (selectedType === 'deposit') {
                let params = {
                    url: 'depositTrans',
                    method: "GET"
                }
                const response = await makeApiRequest(params);
                // console.log("deposit--",response);
                if (response.status == true) {
                    setDeposit(response.data);
                } else {
                    setDeposit([])
                }
                
            }
        } catch (error) {
            console.error('Error fetching transaction data:', error);
        }
    }

    const formatAddress = (address) => {
        const addr = address.substring(0, 5) + "..." + address.substring(address.length - 4);
        return addr;
    }

    const changeTimestampToDate = (timeStamp) => {

        // console.log("bnb--time==", timeStamp);
        var d = new Date(timeStamp * 1000) 
        const currTime = d.toUTCString();
        const date = moment.utc(currTime).format("YYYY-MM-DD,hh:mm A");

        return date;
    }

    const BTCTime = (timeStamp) => {
        if (!timeStamp) {
            return "Invalid time value";
        }

        const date = moment.utc(timeStamp); 

        if (!date.isValid()) {
            return "Invalid time value";
        }

        const formattedDate = date.format('YYYY-MM-DD, h:mm A');

        return formattedDate;
    }


    const fixedBalance = (amt) => {
        let balanceAmt = Number(amt)
        // console.log("balanceAmt", balanceAmt);
        return balanceAmt.toFixed(8)
    }
    const totalAmount = (recAmt, tnxFee) => {
        let userInput = Number(recAmt) + Number(tnxFee)
        // console.log("userInput",userInput,"recAmt",recAmt,"tnxFee",tnxFee);
        let resultVal = fixedBalance(userInput)

        return resultVal
    }

    const formatDate = (dateString) => {
        const dateTime = new Date(dateString);

        const formattedDateTime = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "UTC",
        }).format(dateTime);

        const [month, day, yearTime] = formattedDateTime.split('/');
        const [year, time] = yearTime.split(',');

        const formattedDateTimeWithComma = `${year}-${month}-${day}, ${time}`;

        return formattedDateTimeWithComma;
    };

    useEffect(() => {
        transactionWithdrawDeposit();
    }, [selectedType]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.size > 0) {
            const status = searchParams.get("status");
            status != null ? setSelectedType('withdraw') : setSelectedType('deposite');
        }
    }, [location])

    const renderPaginationNumbers = () => {
        const paginationNumbers = [];
        if (selectedType == 'deposit') {
            const totalPages = Math.ceil(deposit.length / itemsPerPage);

            const maxVisiblePages = 5;

            if (totalPages <= maxVisiblePages) {
                for (let i = 1; i <= totalPages; i++) {
                    paginationNumbers.push(i);
                }
            } else {
                const leftBoundary = Math.min(
                    Math.max(1, currentBuyPage - Math.floor(maxVisiblePages / 2)),
                    totalPages - maxVisiblePages + 1
                );
                const rightBoundary = Math.max(
                    Math.min(totalPages, currentBuyPage + Math.floor(maxVisiblePages / 2)),
                    maxVisiblePages
                );

                if (currentBuyPage < Math.floor(maxVisiblePages / 2) + 1) {
                    for (let i = 1; i <= maxVisiblePages; i++) {
                        paginationNumbers.push(i);
                    }
                } else if (currentBuyPage > totalPages - Math.floor(maxVisiblePages / 2)) {
                    for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
                        paginationNumbers.push(i);
                    }
                } else {
                    for (let i = leftBoundary; i <= rightBoundary; i++) {
                        paginationNumbers.push(i);
                    }
                }
            }

            return paginationNumbers.map((pageNumber, index) => (
                <li
                    key={index}
                    className={`page-item ${currentBuyPage === pageNumber ? "active" : ""}`}
                >
                    <button
                        className="page-link"
                        onClick={() => setCurrentBuyPage(pageNumber)}
                    >
                        {pageNumber}
                    </button>
                </li>
            ));
        } else {
            const totalPages = Math.ceil(withdrawTransactions.length / itemsPerPage);

            const maxVisiblePages = 5;

            if (totalPages <= maxVisiblePages) {
                for (let i = 1; i <= totalPages; i++) {
                    paginationNumbers.push(i);
                }
            } else {
                const leftBoundary = Math.min(
                    Math.max(1, currentBuyPage - Math.floor(maxVisiblePages / 2)),
                    totalPages - maxVisiblePages + 1
                );
                const rightBoundary = Math.max(
                    Math.min(totalPages, currentBuyPage + Math.floor(maxVisiblePages / 2)),
                    maxVisiblePages
                );

                if (currentBuyPage < Math.floor(maxVisiblePages / 2) + 1) {
                    for (let i = 1; i <= maxVisiblePages; i++) {
                        paginationNumbers.push(i);
                    }
                } else if (currentBuyPage > totalPages - Math.floor(maxVisiblePages / 2)) {
                    for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
                        paginationNumbers.push(i);
                    }
                } else {
                    for (let i = leftBoundary; i <= rightBoundary; i++) {
                        paginationNumbers.push(i);
                    }
                }
            }

            return paginationNumbers.map((pageNumber, index) => (
                <li
                    key={index}
                    className={`page-item ${currentBuyPage === pageNumber ? "active" : ""}`}
                >
                    <button
                        className="page-link"
                        onClick={() => setCurrentBuyPage(pageNumber)}
                    >
                        {pageNumber}
                    </button>
                </li>
            ));
        }

    };

    const depositeTxFee = (value) =>{
        let fxValue = fixedBalance(value)
        let percentge = 2
        let depositeAmt = (percentge / 100) * fxValue 
        return fixedBalance(depositeAmt)
    }
    const depositeRecAmt = (value) =>{
        let fxValue = fixedBalance(value)
        let percentge = 2
        let depositeAmt = (percentge / 100) * fxValue 
        let receivedDepositeAmt = fxValue - depositeAmt
        return fixedBalance(receivedDepositeAmt)
    }
    return (
        <div className='APP'>
            <div className='container-fluid'>
                <div className='custom-crypto-transaction'>
                    <div className=''>
                        <div className='row'>
                            <div className='col-lg-12 mx-auto'>
                                <div className='custom-insidecrypto'>
                                    <h2 className=''>Transaction History</h2>
                                    <div className='custom-navcrypto'>
                                        <div class="tab-content" id="pills-tabContent">
                                            <div class="tab-pane fade show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                                <div className=''>
                                                    <p className=''>Type</p>
                                                    <div class="">
                                                        <select
                                                            className='custom-select'
                                                            onChange={(e) => { setSelectedType(e.target.value) }}
                                                            value={selectedType}
                                                        >
                                                            <option value="deposit"  >Deposit</option>
                                                            <option value="withdraw" >Withdraw</option>
                                                        </select>
                                                    </div>

                                                    <div className='custom-datascroll text-center '>
                                                        <table class="table w-100 new-wrap-content-child mb-0" style={{ maxHeight: "100%", minHeight: "150px" }}>
                                                            {selectedType === 'deposit' ? (
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No</th>
                                                                        <th>Coin</th>
                                                                        <th>Deposite Amount</th>
                                                                        <th>Received Amount</th>
                                                                        <th>Transaction Fee </th>
                                                                        <th>Date/Time (UTC)</th>
                                                                        <th>TransactionHash</th>
                                                                    </tr>
                                                                </thead>
                                                            ) : (
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No</th>
                                                                        <th>Coin</th>
                                                                        <th>Withdraw Amount</th>
                                                                        <th>Received Amount</th>
                                                                        <th>Transaction Fee </th>
                                                                        <th>Requested Date (UTC)</th>
                                                                        <th>Approved Date (UTC)</th>
                                                                        <th>TransactionHash</th>
                                                                    </tr>
                                                                </thead>
                                                            )}

                                                            <tbody>
                                                                {(() => {
                                                                    function formathash(hash) {
                                                                        // Implementation of formathash function
                                                                        // ...
                                                                        return formathash;
                                                                    }
                                                                    if (selectedType === 'withdraw') {
                                                                        if (withdrawTransactions.length === 0) {
                                                                            return (
                                                                                <tr>
                                                                                    <td colSpan="6" className='border-0'><LuFileSearch style={{fontSize:"45px", color:"#BC9018"}} /><br/>Data Not Found</td>
                                                                                </tr>

                                                                            )
                                                                        } else {
                                                                            return withdrawTransactions
                                                                                .slice(
                                                                                    (currentBuyPage - 1) * itemsPerPage,
                                                                                    currentBuyPage * itemsPerPage
                                                                                ).map((withdrawTransactions, index) => (
                                                                                    <tr key={withdrawTransactions._id}>
                                                                                        <td>{(currentBuyPage - 1) * itemsPerPage + index + 1}</td>
                                                                                        <td>{withdrawTransactions.coin} </td>
                                                                                        <td>{totalAmount(withdrawTransactions.amount, withdrawTransactions.txFee)}</td>
                                                                                        <td>{fixedBalance(withdrawTransactions.amount)}</td>
                                                                                        <td>{fixedBalance(withdrawTransactions.txFee)}</td>
                                                                                        <td>{formatDate(withdrawTransactions.createdAt)}</td>
                                                                                        <td>{withdrawTransactions.txhash === "" ? (<span>-</span>) : (formatDate(withdrawTransactions.updatedAt))}</td>
                                                                                        <td>
                                                                                            {(() => {
                                                                                                if (withdrawTransactions.coin === "ETH" && withdrawTransactions.txhash !== "") {
                                                                                                    return <a
                                                                                                        href={`${config.sepolia_explorer_url}/tx/${withdrawTransactions.txhash}`}
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        {formatAddress(withdrawTransactions.txhash)}
                                                                                                    </a>;
                                                                                                } else if (withdrawTransactions.coin == "BSC-USD" && withdrawTransactions.txhash != "") {
                                                                                                    return <a
                                                                                                        href={`${config.bnb_explorer_url}/tx/${withdrawTransactions.txhash}`}
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        {formatAddress(withdrawTransactions.txhash)}
                                                                                                    </a>
                                                                                                } else if (withdrawTransactions.coin == "DUSD" && withdrawTransactions.txhash != "") {
                                                                                                    return <a
                                                                                                        href={`${config.dchain_explorer_url}/tx/${withdrawTransactions.txhash}`}
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        {formatAddress(withdrawTransactions.txhash)}
                                                                                                    </a>
                                                                                                } else if (withdrawTransactions.coin == "BTC" && withdrawTransactions.txhash != "") {
                                                                                                    return <a
                                                                                                        href={`${config.bitcoin_explorer_url}/tx/${withdrawTransactions.txhash}`}
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        {formatAddress(withdrawTransactions.txhash)}
                                                                                                    </a>
                                                                                                } else if (withdrawTransactions.coin == "BNB" && withdrawTransactions.txhash != "") {
                                                                                                    return <a
                                                                                                        href={`${config.bnb_explorer_url}/tx/${withdrawTransactions.txhash}`}
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        {formatAddress(withdrawTransactions.txhash)}
                                                                                                    </a>
                                                                                                }
                                                                                                else {
                                                                                                    return <span>-</span>;
                                                                                                }
                                                                                            })()}
                                                                                        </td>

                                                                                    </tr>

                                                                                ));

                                                                        }
                                                                    }

                                                                    else if (selectedType === 'deposit') {
                                                                        if (deposit.length === 0) {
                                                                            // No transactions in deposit
                                                                            return (
                                                                                <tr>
                                                                                    <td colSpan="7" className='border-0'><LuFileSearch style={{fontSize:"45px", color:"#BC9018"}} /><br />Data Not Found</td>
                                                                                </tr>
                                                                            );
                                                                        } else {
                                                                            // Deposit logic(selledBuys || []).length > 0 ? (
                                                                            return deposit
                                                                                .slice(
                                                                                    (currentBuyPage - 1) * itemsPerPage,
                                                                                    currentBuyPage * itemsPerPage
                                                                                ).map((data, index) => (
                                                                                    <tr key={data._id}>
                                                                                        <td>{(currentBuyPage - 1) * itemsPerPage + index + 1}</td>
                                                                                        <td>{data.network}</td>
                                                                                        <td>{fixedBalance(data.latestTransactionAmount)}</td>
                                                                                        <td>{depositeRecAmt(data.latestTransactionAmount)}</td>
                                                                                        <td>{depositeTxFee(data.latestTransactionAmount)}</td>
                                                                                        <td>{data.network == "BTC" ? BTCTime(data.date) : changeTimestampToDate(data.txTimeStamp)}</td>
                                                                                        <td>
                                                                                            {
                                                                                                data.network == "ETH" ? (
                                                                                                    <a
                                                                                                        href={`${config.sepolia_explorer_url}/tx/${data.latestTxhash
                                                                                                            }`}
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        {formatAddress(data.latestTxhash
                                                                                                        )}
                                                                                                    </a>
                                                                                                ) : ("")
                                                                                            }
                                                                                            {
                                                                                                data.network == "BSC-USD" ? (
                                                                                                    <a
                                                                                                        href={`${config.bnb_explorer_url}/tx/${data.latestTxhash}`}
                                                                                                        target="_blank"
                                                                                                    >
                                                                                                        {formatAddress(data.latestTxhash)}
                                                                                                    </a>
                                                                                                ) : ("")
                                                                                            }
                                                                                            {
                                                                                                data.network == "DUSD" ? (
                                                                                                    <a
                                                                                                        href={`${config.dchain_explorer_url}/tx/${data.latestTxhash}`}
                                                                                                        target="_blank"
                                                                                                    // rel="noopener noreferrer"
                                                                                                    // className="https://sepolia.etherscan.io"
                                                                                                    >
                                                                                                        {formatAddress(data.latestTxhash)}
                                                                                                    </a>
                                                                                                ) : ("")
                                                                                            }
                                                                                            {
                                                                                                data.network == "BTC" ? (
                                                                                                    <a
                                                                                                        href={`${config.bitcoin_explorer_url}/tx/${data.latestTxhash}`}
                                                                                                        target="_blank"
                                                                                                    // rel="noopener noreferrer"
                                                                                                    // className="https://sepolia.etherscan.io"
                                                                                                    >
                                                                                                        {formatAddress(data.latestTxhash)}
                                                                                                    </a>
                                                                                                ) : ("")
                                                                                            }
                                                                                            {
                                                                                                data.network == "BNB" ? (
                                                                                                    <a
                                                                                                        href={`${config.bnb_explorer_url}/tx/${data.latestTxhash}`}
                                                                                                        target="_blank"
                                                                                                    // rel="noopener noreferrer"
                                                                                                    // className="https://sepolia.etherscan.io"
                                                                                                    >
                                                                                                        {formatAddress(data.latestTxhash)}
                                                                                                    </a>
                                                                                                ) : ("")
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                ));
                                                                        }
                                                                    }
                                                                })()}
                                                            </tbody>
                                                        </table>
                                                    </div> <br />
                                                    <nav>
                                                        <ul className="pagination justify-content-center custom-transaction-pagination">
                                                            {renderPaginationNumbers()}
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Cryptotransaction