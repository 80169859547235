import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { makeApiRequest } from "../config/axiosService";
import render12 from "../assets/image/RENDER-1-copy.webp";
import { useLocation, useNavigate } from "react-router-dom";
import "../assets/css/Authenticator.css";
import { Spinner } from "react-bootstrap";
import { isAddress } from 'web3-validator';
import { validate } from 'bitcoin-address-validation';

function VerifyOTP() {
  const [otp, setOTP] = useState("");
  const [emailLoader, setEmailLoader] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const navigate = useNavigate()
  const Verifyotp = localStorage.getItem('verifyOTP');
  const location = useLocation()
  const [ payloadData,setPayloadData ] =useState({});
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (localStorage.getItem('verifyOTP') === '1') {
        localStorage.setItem('verifyOTP', '0');
        localStorage.setItem('verifyAuthenticate', '0');
        window.location.href = "/withdrawcrypto";
      }
    }, 180000); // 1 minute in milliseconds  
    const handleBeforeUnload = () => {
      localStorage.setItem('verifyOTP', '0');
      localStorage.setItem('verifyAuthenticate', '0');
      clearTimeout(timeout);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const toAddress = searchParams.get("toAddress");
    const network = searchParams.get("network");
    const amount = searchParams.get("amount");
    setPayloadData({
         toAddress,
         network,
         amount
    })  
}, [location]);

  const sendOtpMail = async () => {
    setEmailLoader(true);
    try {
      let params = {
        url: `send-email-otp`,
        method: "POST",
      };
      const response = await makeApiRequest(params);
      if (response.status) {
        toast.success(response.message);
        setTimeout(() => {
          setEmailLoader(false);
        }, 50000);
      } else {
        setEmailLoader(false);
        toast.error(response.message);
      }
    } catch (error) {
      setEmailLoader(false);
      toast.error("Something went wrong");
    }
  };

  const handleOTPChange = (event) => {
    const { value } = event.target;
    setOTP(value);
  };

  const verifyOTP = async () => {
    setOtpLoader(true);
    try {
      let payload = {
        otp: Number(otp),
      };
      let checkWithdrawReq =await validateWithdrawReq()
          if(checkWithdrawReq == true){
              let params = {
                url: `verify-email-otp`,
                method: "POST",
                data: payload,
              };
              const response = await makeApiRequest(params);
              if (response.status) {
                let verifiedOTP = localStorage.getItem('verifyOTP');
                if(verifiedOTP == 1){
                  toast.success(response.message);
                  intiateWithdraw()
                  setTimeout(()=>{
                    localStorage.setItem('verifyAuthenticate',0)
                    localStorage.setItem('verifyOTP',0)
                    window.location.href="/dashboard/my-crypto-transaction?status=withdraw"
                    setOtpLoader(false);
                  },6000)
                }
              } else {
                setOtpLoader(false);
                toast.error(response.message);
              }
          }else{
            toast.error(checkWithdrawReq)
            setOtpLoader(false); 
            setTimeout(() => {
              window.location.href = "/withdrawcrypto"
            }, 3000);
          }
    } catch (error) {
      console.log("error", error);
      setOtpLoader(false);
      toast.error("Something went wrong");
    }
  };

  const intiateWithdraw = async()=>{
    try {
      const transactionData = {
        coin: payloadData.network,
        toAddress: payloadData.toAddress,
        amount: payloadData.amount
      };

      let params = {
          url: `withdraw-request`,
          method: "POST",
          data: transactionData
      };

    const response = await makeApiRequest(params);
    if (response.status == true) {
        toast.success(response.message);
    } else {
        toast.error(response.message);
        setOtpLoader(false);
    }
      
    } catch (error) {
      console.log("error", error);
      setOtpLoader(false);
      toast.error("Something went wrong");
    }
  }
  const fixedBalance = (amt) =>{
    let balanceAmt = Number(amt);
    return balanceAmt.toFixed(5)
  }
  const validateWithdrawReq = async () => {
    let withdrawAllowed = false;
    let params = {
      url : "validWithdrawAmt",
      method : "POST"
    }
    let response = await makeApiRequest(params);
    // console.log("validateWithdrawReq response",response,payloadData);
    let address = isAddress(payloadData.toAddress)
    let btcAddress = validate(payloadData.toAddress)
    console.log("toAddress",payloadData.toAddress);
    console.log("address",address,"btcAddress",btcAddress);
    const cryptoAddressRegex =/^(0x)?[0-9a-fA-F]{40}$/;
    const btcTestnetAddressRegex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
    if (!cryptoAddressRegex.test(address) || !btcTestnetAddressRegex.test(btcAddress)) {
       console.log("Invalid crypto address");
    }
    
    if (Number(payloadData.amount) === 0) {
      return "Please enter a value other than 0";
    }
    // Error Based Admin allowable
    if(payloadData.network == "BTC" && Number(payloadData.amount) <= response.data.BTC_Withdraw_Amt){
        return `BTC withdraw minimum should be greater than ${response.data.BTC_Withdraw_Amt}`;
    }
    if(payloadData.network == "ETH" && Number(payloadData.amount) <= response.data.ETH_Withdraw_Amt){
        return `ETH withdraw minimum should be greater than ${response.data.ETH_Withdraw_Amt}`;
    }
    if(payloadData.network == "BNB" && Number(payloadData.amount) <= response.data.BNB_Withdraw_Amt){
        return `BNB withdraw minimum should be greater than ${response.data.BNB_Withdraw_Amt}` ;
    }
    if(payloadData.network == "BSC-USD" && Number(payloadData.amount) <= response.data.BUSDT_Withdraw_Amt){
        return `BSC-USD withdraw minimum should be greater than ${response.data.BUSDT_Withdraw_Amt}`;
    }
    if(payloadData.network == "DUSD" && Number(payloadData.amount) <= response.data.DUSD_Withdraw_Amt){
        return `DUSD withdraw minimum should be greater than ${response.data.DUSD_Withdraw_Amt}`;
    }
    // Error Based user balance
    if(payloadData.network == "BTC" && Number(payloadData.amount) > response.data.BTC_userBalance){
        return `BTC wallet balance is low : ${fixedBalance(response.data.BTC_userBalance)}`;
    }   
    if(payloadData.network == "ETH" && Number(payloadData.amount) > response.data.ETH_userBalance){
        return `ETH wallet balance is low : ${fixedBalance(response.data.ETH_userBalance)}`;
    }
    if(payloadData.network == "BNB" && Number(payloadData.amount) > response.data.BNB_userBalance){
        return `BNB wallet balance is low : ${fixedBalance(response.data.BNB_userBalance)}`;
    }
    if(payloadData.network == "BSC-USD" && Number(payloadData.amount) > response.data.BUSDT_userBalance){
        return `BSC-USD wallet balance is low : ${fixedBalance(response.data.BUSDT_userBalance)}`;
    }
    if(payloadData.network == "DUSD" && Number(payloadData.amount) > response.data.DUSD_userBalance){
        return `DUSD wallet balance is low : ${fixedBalance(response.data.DUSD_userBalance)}`;
    }
    return withdrawAllowed = true;
  }

  return (
    <div className="App">
      <ToastContainer />
      <div className="container-fluid">
        <div className="custom-google-authenticator">
          <div className="row justify-content-center align-items-center m-0">
            <div className="col-lg-12 ">
              <div className="pt-0">
                <a class="navbar-brand" href="/">
                  <img src={render12}></img>
                </a>
              </div>
            </div>
          </div>
          <div className="m-0">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12">
                <div className="custom-inside-google-authenticator">
                  <h2>Verify OTP</h2>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-5">
                    <div className="custom-store-section ">
                      <div class="mb-3">
                        <div class="form-text text-center">
                          If you couldn't received Email, please send Email
                          again.
                        </div>
                      </div>
                      <div className="text-center mb-3">
                        {emailLoader == true ? (
                          <button class="opt-btn">
                            <Spinner animation="border" />
                          </button>
                        ) : (
                          <button class="opt-btn " onClick={sendOtpMail}>
                            Send Email
                          </button>
                        )}
                      </div>
                      <div class="mb-3">
                        <div class="form-label text-center">OTP</div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter 6-digit OTP Here"
                          onKeyPress={(event) => {
                            const keyCode = event.which || event.keyCode;
                            const keyValue = String.fromCharCode(keyCode);
                            if (!/^[0-9\b]+$/.test(keyValue)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={handleOTPChange}
                          maxLength={6}
                        />
                      </div>
                      <div className="text-center">
                        {otpLoader == true ? (
                          <button class="opt-btn">
                            <Spinner animation="border" />
                          </button>
                        ) : (
                          <button
                            class="opt-btn"
                            onClick={verifyOTP}
                            disabled={otp.length !== 6}
                            style={{
                              backgroundColor:
                                otp.length !== 6 ? "#CCCCCC" : "",
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyOTP;
