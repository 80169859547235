import React, { useEffect, useState } from "react";
import "../assets/css/Dashboard.css";
import { makeApiRequest } from "../config/axiosService";
import profile from "../assets/image/profile.png";
import accountname from "../assets/image/account name.png";
import mail from "../assets/image/mail.png";
import address from "../assets/image/address.png";
import kyc from "../assets/image/kyc.png";
import Editprofile from "./Editprofile";
import KycVerify from "./KycVerifyForm";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

function Profile() {
  const [userDatas, setUserDatas] = useState({});
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const Address = (address) => {
    if (address && address.length > 5) {
      let returnData = address.substring(0, 20) + "..." + address.substring(address.length - 20)
      return returnData;
    }
  };

  const getUserDatas = async () => {
    try {
      // get login email
      const UserEmail = localStorage.getItem("email");
      // console.log("UserEmail-----",UserEmail);

      const payload = {
        UserEmail: UserEmail,
      };

      const params = {
        url: `getUserDatas`,
        method: "POST",
        data: payload,
      };

      const response = await makeApiRequest(params);
      // console.log("userdatas ===", response);

      const allFieldsFilled =
        response.data &&
        response.data.email &&
        response.data.password &&
        response.data.lastloginIpAddress &&
        response.data.contactname &&
        response.data.contactLastname &&
        response.data.companyname &&
        response.data.address &&
        response.data.countryname &&
        response.data.countrycode &&
        response.data.pincode &&
        response.data.mobilenumber;
      if (response.message == true) {
        setUserDatas(response.data);
      } else {
        setUserDatas("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyEmail = async () => {
    try {
      setIsLoading(true);
      let payLoad = {
        userMail: userDatas.email,
      };
      let params = {
        url: "verify-user-email",
        method: "POST",
        data: payLoad,
      };
      let response = await makeApiRequest(params);
      if (response.status == true) {
        toast.success(response.message);
        setTimeout(() => {
          if (response.status == true) {
            setIsLoading(false);
          }
        }, 5 * 60 * 1000);
      } else {
        toast.error(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // console.log("onemin");
    getUserDatas();

    //set Time Interval
    const interval = setInterval(() => {
      getUserDatas();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="App">
        <ToastContainer />
        <div className="container-fluid">
          <div className="custom-profile">
            <div className="">
              <div className="row">
                <div className="col-lg-12 p-0">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-8">
                      <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center">
                        <img src={profile} alt="Profile"></img>
                        <p className="mb-0 ms-lg-3 " style={{ color: "#BC9018", fontSize: "20px" }}>Profile</p>
                        <button
                          className=" ms-lg-auto mt-2 mt-lg-0"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Edit
                        </button>
                      </div>
                      <div className="custom-account-setting mt-3">
                        {userDatas.contactname == "" ? (
                          ""
                        ) : (
                          <div className="custom-acccount-name d-flex flex-lg-row flex-column align-items-center">
                            <img src={accountname} alt="Profile"></img>
                            <div className="text-center text-lg-start ms-lg-3">
                              <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Account Name</h4>
                              <p className="mb-0">{userDatas.contactname}</p>
                            </div>

                          </div>
                        )}

                        {userDatas.emailVerifyStatus == 0 ? (
                          <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                            <img src={mail} alt="Profile"></img>
                            <div className=" text-lg-start text-center ms-lg-3">
                              <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Gmail</h4>
                              <p className="mb-0 email-ellipsis-css">
                                {userDatas.email}
                              </p>
                            </div>
                            <div className="text-end ms-lg-auto  mt-2 mt-lg-0">
                              {isLoading ? (
                                <>
                                  <button >
                                    <Spinner animation="border" />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button onClick={verifyEmail}>Verify Email</button>
                                </>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center  mb-2">
                            <img src={mail} alt="Profile"></img>
                            <div className="text-lg-start text-center ms-lg-3">

                              <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Gmail</h4>
                              <p className="mb-0 email-ellipsis-css">
                                {userDatas.email}
                              </p>
                            </div>
                            <div className="text-end ms-lg-auto  mt-2 mt-lg-0">
                              <button
                                className={`ms-auto ${userDatas.emailVerifyStatus === 1
                                  ? "fade-disabled-verify"
                                  : ""
                                  }`}
                                disabled={true}
                                style={{ backgroundColor: 'green', color: 'white', border: '0px' }}
                              >
                                Verified
                              </button>
                            </div>
                          </div>
                        )}

                        {userDatas.address == "" ? (
                          ""
                        ) : (
                          <div className="custom-acccount-name d-flex flex-lg-row flex-column align-items-center">
                            <img src={address} alt="Profile"></img>
                            <div className="text-center text-lg-start ms-lg-3">
                              <h4 style={{ color: "#BC9018", fontSize: "20px" }}>Address</h4>
                              <p className="mb-0" style={{wordBreak:"break-all"}}>{userDatas.address}</p>
                            </div>

                          </div>
                        )}

                        {userDatas.kycVerifyStatus === 0 ? (
                          <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                            <img src={kyc} alt="Profile"></img>
                            <div className="text-lg-start text-center ms-lg-3">
                              <h4 style={{ color: "#BC9018", fontSize: "20px" }}>KYC</h4>
                              <p className="mb-0">Passport , International Driving Licence</p>
                            </div>
                            <div className="text-end ms-lg-auto  mt-2 mt-lg-0">
                              <button
                                className="ms-auto"
                                data-bs-toggle="modal"
                                data-bs-target="#KycModal"
                              >
                                Verify KYC
                              </button>
                            </div>
                          </div>
                        ) : userDatas.kycVerifyStatus === 1 ? (
                          <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                            <img src={kyc} alt="Profile"></img>
                            <div className="text-lg-start text-center ms-lg-3">
                              <h4 style={{ color: "#BC9018", fontSize: "20px" }}>KYC</h4>
                              <p className="mb-0">
                                Passport , International Driving Licence
                              </p>
                            </div>
                            <div className="text-end ms-lg-auto  mt-2 mt-lg-0">
                              <button
                                className={`ms-auto ${userDatas.kycVerifyStatus === 1
                                  ? "fade-disabled"
                                  : ""
                                  }`}
                                data-bs-toggle="modal"
                                data-bs-target="#KycModal"
                                disabled={true}
                              >
                                Pending
                              </button>
                            </div>
                          </div>
                        ) : userDatas.kycVerifyStatus === 2 ? (
                          <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center  mb-2">
                            <img src={kyc} alt="Profile"></img>
                            <div className="text-lg-start text-center ms-lg-3">
                              <h4 style={{ color: "#BC9018", fontSize: "20px" }}>KYC</h4>
                              <p className="mb-0">Passport , International Driving Licence</p>
                            </div>
                            <div className="text-end ms-lg-auto  mt-2 mt-lg-0">
                              <button
                                className={`ms-auto ${userDatas.kycVerifyStatus === 2
                                  ? "fade-disabled-verify"
                                  : ""
                                  }`}
                                data-bs-toggle="modal"
                                data-bs-target="#KycModal"
                                disabled={true}
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                }}
                              >
                                Verified
                              </button>
                            </div>
                          </div>
                        ) : userDatas.kycVerifyStatus === 3 ? (
                          <div className="custom-inside-profile d-flex flex-lg-row flex-column align-items-center mb-2">
                             <img src={kyc} alt="Profile"></img>
                            <div className="text-lg-start text-center ms-lg-3">
                              <h4 style={{ color: "#BC9018", fontSize: "20px" }}>KYC</h4>
                              <p className="mb-0">Reason: {userDatas.reason}</p>
                            </div>
                            <div className="text-end ms-lg-auto  mt-2 mt-lg-0">
                              <button
                                className="ms-auto"
                                data-bs-toggle="modal"
                                data-bs-target="#KycModal"
                              >
                                Submit Again
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {userDatas ? (
      ) : (
        <div>
          <Editprofile />
        </div>
      )
      } */}

      <div
        class="modal fade custom-modal-update-profil1"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog custom-modal-update-profile ">
          <div class="modal-content kyc-verification">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div>
                <Editprofile />
              </div>
            </div>
            <div class="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      <div
        class="modal fade custom-modal-update-profil1"
        id="KycModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  ">
          <div class="modal-content custom-modal-update-profile kyc-verification">
            <div class="modal-header d-flex align-items-start pb-0 justify-content-center">
              <div className="position-relative">
                <h4 className="text-center" style={{ color: "#BC9018" }}>KYC VERIFICATION</h4>
              </div>
              <div className="top-modal-1">
                <button
                  type="button"
                  class="btn-close ms-auto"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

            </div>
            <div class="modal-body">
              <div>
                <KycVerify />
              </div>
            </div>
            <div class="modal-footer border-0"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
