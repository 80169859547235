import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import axios from "axios";
import config from '../config/config';
import { useLocation } from 'react-router-dom';
import { makeApiRequest } from "../config/axiosService";

const EnableFunction = forwardRef((props, ref) => {
    const location = useLocation()
    const [enable, setEnable] = useState(false);


    const UserEmail = localStorage.getItem('email')

    const email = localStorage.getItem('userEmail')


    const enabled = async () => {
        if (location.pathname == '/dashboard') {

            let params = {
                url: `enable?UserEmail`,
                method: "POST",
                
              }
            // const response = await axios.get(`${config.backendurl}/enable?UserEmail=${UserEmail}`);
            const response = await makeApiRequest(params);
            if (response.user.secret) {
                setEnable(true)
            }
            else {
                setEnable(false)
            }
        } else if (location.pathname == '/login') {

            let params = {
                url: `enable?UserEmail`,
                method: "get",
              
              }

            // const response = await axios.get(`${config.backendurl}/enable?UserEmail=${email}`);
            const response = await makeApiRequest(params);
            if (response.user.secret) {
                setEnable(true)
            }
            else {
                setEnable(false)
            }
        }
    }

    useImperativeHandle(ref, () => ({
        enabled,
        enable,
    }));

    return (<></>)


});


export default EnableFunction;
