import React, { useState, useEffect, useRef } from "react";
import "../assets/css/Style2.css";
import RENDER from "../assets/image/brand-logo.png";
import Bottle from "../assets/image/bottle.png";
import { makeApiRequest } from "../config/axiosService";
import Editprofile from "./Editprofile";
import { ToastContainer, toast } from "react-toastify";
import { FaFileDownload } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import moment from "moment"
import { MdKeyboardArrowRight } from "react-icons/md";
import { LuFileSearch } from "react-icons/lu";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from "@react-pdf/renderer";


function Assetdashboard() {
  const [bulkdata, setBulkdata] = useState([]);
  const [buyData, setBuyData] = useState();
  const [fractionBalance, setFractionBalance] = useState();
  const [ampouleno, setAmpouleno] = useState();
  const [productNo, setProductNo] = useState();
  const [buyFractionOrders, setBuyFractionOrder] = useState([]);
  const [productIds, setProductIds] = useState();
  const [emailVerifyStatus, setEmailVerifyStatus] = useState();
  const [kycVerifyStatus, setKYCVerifyStatus] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [orderType, setOrderType] = useState("BUY")
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCupToken, setTotalCupToken] = useState();
  const [currentfractioncost, setCurrentfractioncost] = useState(0);
  const [currentbulkcost, setCurrentbulkcost] = useState(0)
  const itemsPerPage = 3;
  const utcDate = new Date();
  const currTime = utcDate.toUTCString();


  useEffect(() => {
    getVerifyEmailStatus();
    FractionBalance();
    TotalCupToken();
    FindCurrentCost();
    BuyAssest();
    BulkAssest();
    BuyFraction();
  }, []);

  const UserEmail = localStorage.getItem("email");

  const setType = (type) => {
    setOrderType(type)
  }

  const FindCurrentCost = async () => {
    try {
      let params = {
        url: `getFindCurrentCost`,
        method: "GET",
      };

      const response = await makeApiRequest(params);
      // console.log("FindCurrentCost----",response);
      if (response.status == true) {
        setCurrentfractioncost(response.findCurrentCost.currentfractioncost);
        setCurrentbulkcost(response.findCurrentCost.currentbulkcost);
      } else {
        setCurrentfractioncost(0);
        setCurrentbulkcost(0);
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }
  }

  const BulkAssest = async (id) => {
    try {
      setProductIds(id);
      let params = {
        url: `getbulkassest`,
        method: "GET",
      };
      const response = await makeApiRequest(params);
      // console.log('BulkAssest--',response);
      if (response.status == true) {
        setBulkdata(response.data);
      } else {
        setBulkdata([]);
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }
  };

  const hideAmpouleNumber = (number) => {
    return "*".repeat(number.length); // Replaces each character in the number with an asterisk
  };

  const FractionBalance = async () => {
    try {
      let params = {
        url: `getFractionBalance`,
        method: "GET",
      };
      const response = await makeApiRequest(params);
      if (response.status == true) {
        const Balance = response.data;
        // console.log("Balance--",Balance);
        setFractionBalance(Balance)
        BuyFraction();
      } else {
        setFractionBalance(0);
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }

  }

  const BuyAssest = async () => {
    try {
      let params = {
        url: `getbuyassest`,
        method: "GET",
      };
      const response = await makeApiRequest(params);
      // console.log("BuyAssest--",response);
      if (response.status == true) {
        setBuyData(response.data);
        const hiddenAmpoule = hideAmpouleNumber(
          response.data[0].productId.ampouleno
        );
        setAmpouleno(hiddenAmpoule);
      } else {
        setBuyData([]);
        setAmpouleno("**********");
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }

  };

  const BuyFraction = async () => {
    try {
      const params = {
        url: `getBuyFraction`,
        method: "GET",
      };

      const response = await makeApiRequest(params);
      if (response.status === true) {
        setBuyFractionOrder(response.buyFractionOrder);
        FractionBalance();
      } else {
        setBuyFractionOrder([]);
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }
  };

  const getVerifyEmailStatus = async () => {
    try {
      let params = {
        url: `verifyEmailStatus`,
        method: "GET",
      };
      const response = await makeApiRequest(params);
      // console.log("response----+>",response);
      if (response.status == true) {
        const verifyEmailStatus = response.data.emailVerifyStatus;
        const verifyKYCStatus = response.data.kycVerifyStatus;
        setEmailVerifyStatus(verifyEmailStatus);
        setKYCVerifyStatus(verifyKYCStatus);
      } else {
        setEmailVerifyStatus(0);
        setKYCVerifyStatus(0);
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }

  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return formattedDate;
  };


  const handleSubmit = async (id) => {
    try {
      // setIsLoading(true);
      if (emailVerifyStatus !== 1 && kycVerifyStatus !== 2) {
        // setIsLoading(false);
        toast.error('Email or KYC not verified. Please verify your email and KYC first.');
        setTimeout(() => {
          window.location.href = "/dashboard/profile";
        }, 2000);
      } else {
        let payload = {
          id: id,
          startDate: startDate,
          currTime: currTime,

        };
        const params = {
          url: `getRedeemBulk`,
          method: "POST",
          data: payload,
        };
        const response = await makeApiRequest(params);
        // console.log("regetRedeemBulk---++", response);
        if (response.status === true) {
          // setIsLoading(false);
          toast.success(response.message);
          BulkAssest();
          TotalCupToken();
        }
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }

  };

  const TotalCupToken = async () => {
    try {
      const params = {
        url: `getTotalCupToken`,
        method: "GET",
      };

      const response = await makeApiRequest(params);
      // console.log("TotalCupToken------+", response);
      if (response.status == true) {
        setTotalCupToken(response.Totalcuptoken);
      } else {
        setTotalCupToken(0);
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }

  }

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    if (orderType == 'BUY') {
      const totalPages = Math.ceil(buyFractionOrders.length / itemsPerPage);

      const maxVisiblePages = 5;

      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        const leftBoundary = Math.min(
          Math.max(1, currentPage - Math.floor(maxVisiblePages / 2)),
          totalPages - maxVisiblePages + 1
        );
        const rightBoundary = Math.max(
          Math.min(totalPages, currentPage + Math.floor(maxVisiblePages / 2)),
          maxVisiblePages
        );

        if (currentPage < Math.floor(maxVisiblePages / 2) + 1) {
          for (let i = 1; i <= maxVisiblePages; i++) {
            paginationNumbers.push(i);
          }
        } else if (currentPage > totalPages - Math.floor(maxVisiblePages / 2)) {
          for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
            paginationNumbers.push(i);
          }
        } else {
          for (let i = leftBoundary; i <= rightBoundary; i++) {
            paginationNumbers.push(i);
          }
        }
      }

      return paginationNumbers.map((pageNumber, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => setCurrentPage(pageNumber)}
          >
            {pageNumber}
          </button>
        </li>
      ));
    } else {
      const totalPages = Math.ceil(bulkdata.length / itemsPerPage);

      const maxVisiblePages = 5;

      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        const leftBoundary = Math.min(
          Math.max(1, currentPage - Math.floor(maxVisiblePages / 2)),
          totalPages - maxVisiblePages + 1
        );
        const rightBoundary = Math.max(
          Math.min(totalPages, currentPage + Math.floor(maxVisiblePages / 2)),
          maxVisiblePages
        );

        if (currentPage < Math.floor(maxVisiblePages / 2) + 1) {
          for (let i = 1; i <= maxVisiblePages; i++) {
            paginationNumbers.push(i);
          }
        } else if (currentPage > totalPages - Math.floor(maxVisiblePages / 2)) {
          for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
            paginationNumbers.push(i);
          }
        } else {
          for (let i = leftBoundary; i <= rightBoundary; i++) {
            paginationNumbers.push(i);
          }
        }
      }

      return paginationNumbers.map((pageNumber, index) => (
        <li
          key={index}
          className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => setCurrentPage(pageNumber)}
          >
            {pageNumber}
          </button>
        </li>
      ));
    }

  };

  return (
    <div className="App">
      <ToastContainer />
      <div className="container-fluid">
        <div className="custom-assetdash-board">
          <div className="">
            <div className="row">
              <div className="col-lg-12 ">
                <h2 style={{ color: "#BC9018" }}>My Asset <span className="fs-4" style={{ color: "#BC9018" }}>: (Total : {fractionBalance ? totalCupToken + fractionBalance : totalCupToken || 0} Cup Tokens)</span></h2>
                <p style={{ color: "#BC9018" }}>1 Gram = 1 cup Tokens</p>
                <div className="mt-3 reedem-order-ouy-line">
                  <ul
                    class="nav nav-pills mb-3 justify-content-lg-center justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item me-3 me-lg-5" role="presentation">
                      <button
                        class="nav-link active asset-btn-1"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => setType("BUY")}
                      >
                        FRACTION
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link asset-btn-1  mt-lg-0"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={() => setType("BULK")}
                      >
                        BULK
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content mt-5" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                      tabindex="0"
                    >
                      <div className="row">
                        {(buyData && (fractionBalance > 0)) ? (
                          <div className="col-lg-12">
                            <div className="row align-items-center justify-content-center text-center">
                              <div className="col-lg-5">
                                <div className="custom-inside-assetdash mb-4 ">
                                  <div className="assets-card-bg-styling-css  py-3 px-3 ">
                                    <div className="row row-cols-1  text-center align-items-center justify-content-between">
                                      <div className="col">
                                        <p className="card-title-styling-css text-start">
                                          Ultrafine Copper Powder
                                        </p>
                                        <div className="row">
                                          <div className="col-lg-9">
                                            <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                              <p className="assets-card-inner-text-css">
                                                Ampoule No:
                                              </p>
                                              <p className="assets-card-inner-text-css">
                                                {ampouleno}
                                              </p>
                                            </div>
                                            <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                              <p className="assets-card-inner-text-css">
                                                Net weight:
                                              </p>
                                              <p className="assets-card-inner-text-css">
                                                200 gram
                                              </p>
                                            </div>
                                            <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                              <p className="assets-card-inner-text-css">
                                                My Holdings:
                                              </p>
                                              <p className="assets-card-inner-text-css">
                                                {`${fractionBalance} gram(${fractionBalance} cup)`}
                                              </p>
                                            </div>
                                            <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                              <p className="assets-card-inner-text-css">
                                                My Fraction :
                                              </p>
                                              <p className="assets-card-inner-text-css">
                                                {`${((fractionBalance / 200) * 100).toFixed(1)}%`}
                                              </p>
                                            </div>
                                            <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                              <p className="assets-card-inner-text-css">
                                                Purchase rate :
                                              </p>
                                              <p className="assets-card-inner-text-css">
                                                {currentfractioncost} USD
                                              </p>
                                            </div>
                                            <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                              <p className="assets-card-inner-text-css">
                                                Current cost :
                                              </p>
                                              <p className="assets-card-inner-text-css">
                                                {currentfractioncost} USD
                                              </p>
                                            </div>
                                            <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                              <p className="assets-card-inner-text-css">
                                                Current Value of my asset:
                                              </p>
                                              <p className="assets-card-inner-text-css">
                                                {((currentfractioncost * fractionBalance).toFixed(2))} USD
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <img
                                              className="custom-buybottle"
                                              src={Bottle}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            {/* <LuFileSearch style={{fontSize:"45px", color:"#BC9018"}} />
                            <p>No Orders</p> */}
                            <div className="col-lg-12">
                              <div className="row align-items-center justify-content-center text-center">
                                <div className="col-lg-5">
                                  <div className="custom-inside-assetdash mb-4 ">
                                    <div className="assets-card-bg-styling-css  py-3 px-3 ">
                                      <div className="row row-cols-1  text-center align-items-center justify-content-between">
                                        <div className="col">
                                          <p className="card-title-styling-css text-start">
                                            Ultrafine Copper Powder
                                          </p>
                                          <div className="row">
                                            <div className="col-lg-9">
                                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                <p className="assets-card-inner-text-css">
                                                  Ampoule No:
                                                </p>
                                                <p className="assets-card-inner-text-css">
                                                  ************
                                                </p>
                                              </div>
                                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                <p className="assets-card-inner-text-css">
                                                  Net weight:
                                                </p>
                                                <p className="assets-card-inner-text-css">
                                                  200 gram
                                                </p>
                                              </div>
                                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                <p className="assets-card-inner-text-css">
                                                  My Holdings:
                                                </p>
                                                <p className="assets-card-inner-text-css">
                                                  0 gram(0 cup)
                                                </p>
                                              </div>
                                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                <p className="assets-card-inner-text-css">
                                                  My Fraction :
                                                </p>
                                                <p className="assets-card-inner-text-css">
                                                  0.0%
                                                </p>
                                              </div>
                                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                <p className="assets-card-inner-text-css">
                                                  Purchase rate :
                                                </p>
                                                <p className="assets-card-inner-text-css">
                                                  {currentfractioncost} USD
                                                </p>
                                              </div>
                                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                <p className="assets-card-inner-text-css">
                                                  Current cost :
                                                </p>
                                                <p className="assets-card-inner-text-css">
                                                  {currentfractioncost} USD
                                                </p>
                                              </div>
                                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                <p className="assets-card-inner-text-css">
                                                  Current Value of my asset:
                                                </p>
                                                <p className="assets-card-inner-text-css">
                                                  0 USD
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-3">
                                              <img
                                                className="custom-buybottle"
                                                src={Bottle}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row mt-5  custom-line-1">
                        <p className="card-title-styling-css text-center mt-3">
                          FRACTION ORDERS
                        </p>

                        {buyFractionOrders.length > 0 ? (
                          <div className="col-lg-12">
                            <div className="row mt-5 align-items-center justify-content-center text-center">
                              {Array.isArray(buyFractionOrders) &&
                                buyFractionOrders.slice(
                                  (currentPage - 1) * itemsPerPage,
                                  currentPage * itemsPerPage
                                ).map((data, index) => (
                                  <>
                                    <div className="col-lg-5">
                                      <div className="custom-inside-assetdash mb-4">
                                        <div className="assets-card-bg-styling-css  py-3 px-3 ">
                                          <div className="row row-cols-1  text-center align-items-center justify-content-between">
                                            <div className="col">
                                              <p className="card-title-styling-css text-start">
                                                Ultrafine Copper Powder
                                              </p>

                                              <div className="row">
                                                <div className="col-lg-9">
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Ampoule No:
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {data.productId.ampouleno}
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Net weight:
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      200 gram
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      My Holdings:
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      200 gram(200 cup)
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      My Fraction :
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      ({((200 / 200) * 100).toFixed(1)}%)
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Purchase rate :
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {data.purchaserate} USD
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Current cost :
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {currentbulkcost} USD
                                                    </p>
                                                  </div>

                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Current Value of my asset:
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {currentfractioncost * 200} USD
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Date :
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {data.date.split('T')[0]} - {moment.utc(data.time).format('hh:mm A')}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-3">
                                                  <img
                                                    className="custom-buybottle"
                                                    src={Bottle}
                                                  />
                                                </div>
                                                <div className="col-lg-12 ">
                                                  {data.status === "Redeem" || data.status === "InProcess" || data.status === "Completed" ? (
                                                    <>
                                                      <div className="dowload-con-1">
                                                        <PDFDownloadLink
                                                          className="invoice-color"
                                                          document={
                                                            <PrintInvoice invoiceData={data} />
                                                          }
                                                          fileName={`invoice.pdf`}
                                                        >
                                                          {({
                                                            blob,
                                                            url,
                                                            loading,
                                                            error,
                                                          }) =>
                                                            loading ? (
                                                              "Loading document..."
                                                            ) : (
                                                              <>
                                                                <FaFileDownload className="me-1" />
                                                                Download Invoice
                                                              </>
                                                            )
                                                          }
                                                        </PDFDownloadLink>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <button
                                                      className="Redeem-btn"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#exampleModal"
                                                      onClick={() => setProductIds(data.productId._id)}
                                                    // onClick={() =>
                                                    //   BuyFraction(
                                                    //     data.productId._id
                                                    //   )
                                                    // }
                                                    >
                                                      Redeem
                                                    </button>
                                                  )}
                                                </div>
                                                <div className="text-center">
                                                  <Link to="/view" target="_blank">
                                                    <h3 className="custom-view  text-dark">
                                                      view certify
                                                      <MdKeyboardArrowRight />
                                                      <span></span>
                                                    </h3>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="modal fade custom-modal-update-profil1"
                                      id="exampleModal"
                                      tabindex="-1"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog custom-modal-update-profile">
                                        <div class="modal-content">
                                          <div class="modal-header">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div class="modal-body">
                                            <div>
                                              <Editprofile
                                                productID={productIds}
                                              />
                                            </div>
                                          </div>
                                          <div class="modal-footer border-0"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            <LuFileSearch style={{ fontSize: "45px", color: "#BC9018" }} />
                            <p>No Orders</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                      tabindex="0"
                    >
                      {bulkdata.length == 0 ? (
                        <div className="text-center">
                          <LuFileSearch style={{ fontSize: "45px", color: "#BC9018" }} />
                          <p>No Orders</p>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row align-items-center justify-content-center text-center">
                              {Array.isArray(bulkdata) &&
                                bulkdata.slice(
                                  (currentPage - 1) * itemsPerPage,
                                  currentPage * itemsPerPage
                                ).map((data, index) => (
                                  <>
                                    <div key={index} className="col-lg-5">
                                      <div className="custom-inside-assetdash mb-4 ">
                                        <div className="assets-card-bg-styling-css  py-3 px-3 inside-asset-1">
                                          <div className="row row-cols-1  text-center align-items-center justify-content-between">
                                            <div className="col">
                                              <p className="card-title-styling-css text-start">
                                                Ultrafine Copper Powder
                                              </p>
                                              <div className="row">
                                                <div className="col-lg-9">
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Ampoule No:
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {data.productId.ampouleno}
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Net weight:
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      200 gram
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      My Holdings:
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      200 gram(200 cup)
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      My Fraction :
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      ({((200 / 200) * 100).toFixed(1)}%)
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Purchase rate :
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {data.purchaserate} USD
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Current cost :
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {currentbulkcost} USD
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Current Value of my asset:
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {currentfractioncost * 200} USD
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                                    <p className="assets-card-inner-text-css">
                                                      Date :
                                                    </p>
                                                    <p className="assets-card-inner-text-css">
                                                      {data.date.split('T')[0]} - {moment.utc(data.time).format('hh:mm A')}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-3">
                                                  <img
                                                    className="custom-buybottle"
                                                    src={Bottle}
                                                  />
                                                </div>
                                                <div className="col-lg-12">
                                                  {data.status === "Redeem" || data.status === "InProcess" || data.status === "Completed" ? (
                                                    <>
                                                      <div className="dowload-con-1">
                                                        <PDFDownloadLink
                                                          className="invoice-color"
                                                          document={
                                                            <PrintInvoice invoiceData={data} />
                                                          }
                                                          fileName={`invoice.pdf`}
                                                        >
                                                          {({
                                                            blob,
                                                            url,
                                                            loading,
                                                            error,
                                                          }) =>
                                                            loading ? (
                                                              "Loading document..."
                                                            ) : (
                                                              <>
                                                                <FaFileDownload className="me-1" />
                                                                Download Invoice
                                                              </>
                                                            )
                                                          }
                                                        </PDFDownloadLink>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        className="Redeem-btn"
                                                        onClick={() =>
                                                          handleSubmit(
                                                            data.productId._id
                                                          )
                                                        }
                                                      >
                                                        Redeem
                                                      </button>
                                                    </>
                                                  )}
                                                </div>
                                                <div className="text-center">
                                                  <Link to="/view" target="_blank">
                                                    <h3 className="custom-view  text-dark">
                                                      view certify
                                                      <MdKeyboardArrowRight />
                                                      <span></span>
                                                    </h3>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <nav>
                      <ul className="pagination justify-content-center custom-transaction-pagination">
                        {renderPaginationNumbers()}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: '15px',
  },
  image: {
    width: '30%',
    border: '1px solid white',
    padding: '5px 0',
    textAlign: 'center',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 10,
  },
  column: {
    flex: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeader: {
    padding: 10,
    backgroundColor: 'rgb(228, 190, 97)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    color: '#fff',
    textAlign: 'center',
  },
  tableInnerContent: {
    color: '#000',
    backgroundColor: '#fff',
    padding: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
  },
  summaryContainer: {
    display: 'flex',
    marginTop: 10,
  },
  summaryText: {
    width: '100%',
    height: 184,
    border: '2px solid white',
    padding: '0 5px',
    marginTop: '20px',
  },
});

const PrintInvoice = ({ invoiceData }) => {
  console.log("invoiceData", invoiceData);
  function formatDate(string) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([], options);
  }
  var dateTime_now = new Date();
  var dateTime_now_utc_str = dateTime_now.toISOString();
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.flexContainer}>
          <View style={styles.column}>
            <Image src={RENDER} style={styles.image} />
          </View>
          <View style={styles.column}>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: "15px" }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>Invoice :</Text>{invoiceData.productId._id}</Text>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: "15px" }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>Date :</Text>{formatDate(dateTime_now_utc_str)}</Text>
          </View>
        </View>
        <View style={styles.flexContainer}>
          <View style={[styles.column, { marginBottom: '20px' }]}>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: '20px' }}>Billing Address</Text>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: "15px" }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>Company :</Text>{invoiceData.companyname}</Text>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: "15px" }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>Address :</Text>{invoiceData.address}</Text>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: "15px" }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>Country :</Text> {invoiceData.countryname}</Text>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: "15px" }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>Pincode :</Text>{invoiceData.pincode}</Text>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: "15px" }}><Text style={{ fontWeight: 'heavy', fontSize: '17px' }}>Mob No :</Text>{invoiceData.mobilenumber}</Text>
            {/* Add more Text components as needed */}
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: '20px' }}>Network Status</Text>
            <Text style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: "15px" }}>{invoiceData.network}</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableHeader, { width: '15%', fontSize: "12px" }]}>
              <Text>S.No</Text>
            </View>
            <View style={[styles.tableHeader, { width: '35%', fontSize: "12px" }]}>
              <Text>Product</Text>
            </View>
            <View style={[styles.tableHeader, { width: '25%', fontSize: "12px" }]}>
              <Text>Amp No</Text>
            </View>
            <View style={[styles.tableHeader, { width: '25%', fontSize: "12px" }]}>
              <Text>Price</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableInnerContent, { width: '15%', fontSize: "10px" }]}>
              <Text>1</Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '35%', fontSize: "10px" }]}>
              <Text>Cup Token</Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '25%', fontSize: "10px" }]}>
              <Text>{invoiceData.productId.ampouleno}</Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '25%', fontSize: "10px" }]}>
              <Text>{invoiceData.amount}</Text>
            </View>
          </View>
          {/* <View style={styles.tableRow}>
            <View style={[styles.tableInnerContent, { width: '15%',fontSize:"10px" }]}>
              <Text>2</Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '35%' }]}>
              <Text>ampuascascasclle</Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '25%' }]}>
              <Text>1</Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '25%' }]}>
              <Text>$20.00</Text>
            </View>
          </View> */}
          <View style={styles.tableRow}>
            <View style={[styles.tableInnerContent, { width: '15%', fontSize: "10px" }]}>
              <Text style={{ border: '0px' }}></Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '35%', fontSize: "10px" }]}>
              <Text></Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '25%', fontSize: "10px" }]}>
              <Text>Grand Total</Text>
            </View>
            <View style={[styles.tableInnerContent, { width: '25%', fontSize: "10px" }]}>
              <Text>{invoiceData.amount}</Text>
            </View>
          </View>
        </View>

        <View style={styles.flexContainer}>
          <View style={styles.summaryText}>
            <Text style={{ color: 'black' }}>
              A job description contains the following components: job title, job purpose, job duties and responsibilities, required qualifications,
              preferred qualifications, and working conditions.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Assetdashboard;

