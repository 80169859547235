import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { makeApiRequest } from "../config/axiosService";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { FaRegEyeSlash } from "react-icons/fa";
import { Icon } from 'react-icons-kit';
import { Formik, useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { logDOM } from "@testing-library/react";
import render12 from '../assets/image/RENDER-1-copy.webp';

function ResetPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mailStatus, setMailStatus] = useState({});
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [loader, setLoader] = useState(false);
  const [confirmType, setConfirmType] = useState("password");
  const [confirmIcon, setConfirmIcon] = useState(eyeOff);
  const [mailexpire, setMailexpire] = useState()
  const [token, setToken] = useState()

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const confirmPassHandleToggle = () => {
    if (confirmType === "password") {
      setConfirmIcon(eye);
      setConfirmType("text");
    } else {
      setConfirmIcon(eyeOff);
      setConfirmType("password");
    }
  };

  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  const passwordMatch =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~()\-_+=!@#$%^&*<>`:{}\[\];'?",.])(?=.*\d).{8,}$/;

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(" Password is required!").matches(passwordMatch,"Password must contain at least 8 characters, one uppercase letter, one number, and atleast one symbol!"),
      confirmPassword: Yup.string().required("Confirm password is required!"),
    }),
    onSubmit: async (values) => {
      try {
        // setLoader(true);

        if (values.password === values.confirmPassword) {
          let token = searchParams.get("request");
          const payload = {
            password: values.password,
            confirmPassword: values.confirmPassword,
            emailToken: token,
          };
          let params = {
            url: `user-reset-password`,
            method: "POST",
            data: payload,
          };
          const res = await makeApiRequest(params);

          if (res.status == true) {
            toast.success(res.message);
            setTimeout(() => {
              window.location.href = '/login'
            }, 2000);
          } else {
            toast.error(res.message);
          }
        } else {
          toast.error("Password and confirm password doesn't match..!");
        }
      } catch (error) {
        setLoader(false);
        toast.error(error.message);
      }
    },
  });

  const MailExpired = async () => {
    let token = searchParams.get("request");
    setToken(token);

    try {
      const payload = {
        emailToken: token,
      };
      let params = {
        url: `mailexpire`,
        method: "POST",
        data: payload,
      };
      const res = await makeApiRequest(params);
      if (res.status === false) {
        setMailexpire(false);
      } else {
        setMailexpire(true);
      }
    } catch (error) {
      console.log('failed', error);

    }
  };


  useEffect(() => {
    MailExpired();
  }, [token]);


  return (
    <div className="App">
      <ToastContainer />
      <div className="container-fluid ">
        <div className="p-4">
          <a class="navbar-brand" href="/"><img src={render12}></img></a>
        </div>
        <div className="custom-thankyou">

          <div className="row  align-items-center">
            <div className="col-lg-6 mx-auto">
              <div className="custom-inside-thankyou">
                {mailexpire == false ? (
                  <h2 className="mt-4 mb-4">{'Your Mail is Expired'}</h2>
                ) : (
                  <form
                    className="new-contact-form"
                    name="footercontact"
                    id="footercontact"
                    autocomplete="off"
                    novalidate="novalidate"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      return false;
                    }}
                  >
                    <p className="fs-3 fw-500 text-white">
                      Reset-Password
                    </p>
                    <div className="mb-3">
                      <div className="mb-3">
                        <div class="input-group ">
                          <input
                            type={type}
                            class="form-control with-input mb-1"
                            id="exampleInputPassword1"
                            name="password"
                            maxLength="15"
                            placeholder="Password"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            invalid={
                              touched.password && errors.password ? true : false
                            }
                          />
                          <span
                            class="input-group-text eye-icon reset-eye"
                            onClick={handleToggle}
                          >
                            <Icon class="absolute mr-10" icon={icon} size={25} />
                            {/* <FaRegEyeSlash /> */}
                          </span>
                        </div>
                        {touched.password && errors.password && (
                          <small className="invalid-login error password-text-33">
                            {errors.password}
                          </small>
                        )}
                      </div>



                      <div class="input-group mb-0 mt-2">
                        <input
                          type={confirmType}
                          class="form-control with-input mb-1"
                          id="exampleInputPassword1"
                          name="confirmPassword"
                          maxLength="15"
                          placeholder="confirmPassword"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          invalid={
                            touched.confirmPassword && errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        <span
                          class="input-group-text eye-icon reset-eye"
                          onClick={confirmPassHandleToggle}
                        >
                          <Icon
                            class="absolute mr-10"
                            icon={confirmIcon}
                            size={25}
                          />
                          {/* <FaRegEyeSlash /> */}
                        </span>
                      </div>
                      {touched.confirmPassword && errors.confirmPassword && (
                        <small className="invalid-login error password-text-33">
                          {errors.confirmPassword}
                        </small>
                      )}
                    </div>
                    {loader == true ? (
                      <Spinner animation="border" />
                    ) : (
                      <button className="register-btn-1"> Reset Password </button>
                    )}
                  </form>
                )}
                <div className="mt-3">
                  <Link to="/login" className="register-btn-1" > login </Link>
                </div>

                <div className="custom-icon-form mt-3 mb-4">
                  <span>
                    <a href="https://twitter.com/DictysFinance" target='_blank' className="text-dark"><FaXTwitter className="me-1 thankyou-icon" /></a>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
