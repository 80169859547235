import React, { useState, useEffect } from "react";
import "../src/assets/css/Dashboard.css";
import { makeApiRequest } from "./config/axiosService";
import moment from "moment"
import { LuFileSearch } from "react-icons/lu";
import $ from "jquery";

function Myassettransaction() {
  const [buyandBulkOrder, setBuyandBulkOrder] = useState([]);
  const [currentBuyPage, setCurrentBuyPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    MyAssetTransaction();
  }, []);



  const MyAssetTransaction = async () => {
    try {
      let params = {
        url: `myAssetTransaction`,
        method: "GET",
      };
      const response = await makeApiRequest(params);
      // console.log("response---",response);
      if (response.status == true) {
        const combinedDatas = response.transactions;
        setBuyandBulkOrder(combinedDatas);
      }else{
        setBuyandBulkOrder([]);
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }
  };
  
  const burnAddress = (address) => {
    let returnData =
      address.substring(0, 5) + "..." + address.substring(address.length - 4);
    return returnData;
  };

  $(document).ready(function () {
    $(".custom-table-row").on("click", function () {
      // Remove 'activations' class from all buttons
      $(".custom-table-row").removeClass("activations");

      // Add 'activations' class to the clicked button
      $(this).addClass("activations");
    });
  });

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    const totalPages = Math.ceil(buyandBulkOrder.length / itemsPerPage);
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationNumbers.push(i);
      }
    } else {
      const leftBoundary = Math.min(
        Math.max(1, currentBuyPage - Math.floor(maxVisiblePages / 2)),
        totalPages - maxVisiblePages + 1
      );
      const rightBoundary = Math.max(
        Math.min(totalPages, currentBuyPage + Math.floor(maxVisiblePages / 2)),
        maxVisiblePages
      );

      if (currentBuyPage < Math.floor(maxVisiblePages / 2) + 1) {
        for (let i = 1; i <= maxVisiblePages; i++) {
          paginationNumbers.push(i);
        }
      } else if (currentBuyPage > totalPages - Math.floor(maxVisiblePages / 2)) {
        for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        for (let i = leftBoundary; i <= rightBoundary; i++) {
          paginationNumbers.push(i);
        }
      }
    }

    return paginationNumbers.map((pageNumber, index) => (
      <li
        key={index}
        className={`page-item ${currentBuyPage === pageNumber ? "active" : ""}`}
      >
        <button
          className="page-link"
          onClick={() => setCurrentBuyPage(pageNumber)}
        >
          {pageNumber}
        </button>
      </li>
    ));
  };


  return (
    <div className="APP">
      <div className="container-fluid">
        <div className="custom-assettransaction">
          <div className="">
            <div className="row align-items-center">
              <div className="col-lg-12 mx-auto">
                <div className="custom-inside-asset-transaction">
                  <h2 className="">My Asset Transaction</h2>
                  <div className="custom-assetcroll">
                    <table className="col-lg-12">
                      <thead className="custom-tablefirst">
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Asset</th>
                        <th>Amount</th>
                        <th>Coin</th>
                        <th>Txn id</th>
                        <th>USDT</th>
                        <th>Status</th>
                        <th>Quantity</th>
                      </thead>
                      {buyandBulkOrder && buyandBulkOrder.length > 0 ? (
                        buyandBulkOrder.slice(
                          (currentBuyPage - 1) * itemsPerPage,
                          currentBuyPage * itemsPerPage
                        ).map((data, index) => (
                          <tr className="custom-table-row">
                            <td>{(currentBuyPage - 1) * itemsPerPage + index + 1}</td>
                            <td>{data.date.split("T")[0]} - {moment.utc(data.time).format("hh:mm A")}</td>
                            <td>
                              <p className="custom-ress">Cup Token</p>
                            </td>
                            <td>{Number(data.amount).toFixed(5)}</td>
                            <td>{data.network}</td>
                            <td>{burnAddress(data._id)}</td>
                            <td>{data.usdtPrice}</td>
                            <td>{"Purchased"}</td>
                            <td>{data.type == "bulk" ? "Ampoule(200 Gram)" : `${data.quantity} Gram`}</td>
                          </tr>
                        ))
                      ) : (
                        <div className=" token-data text-center mt-4 display-table-caption">
                          <LuFileSearch style={{fontSize:"45px", color:"#BC9018"}} />
                          <p>No Data</p>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
                <br />
                <nav>
                  <ul className="pagination justify-content-center custom-transaction-pagination">
                    {renderPaginationNumbers()}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Myassettransaction;
