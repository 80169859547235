import React, { useEffect, useState } from "react";
import { makeApiRequest } from "../config/axiosService";
import "../assets/css/Dashboard.css";
import { Link } from "react-router-dom";
import { logDOM } from "@testing-library/react";
import ethlogo from "../assets/image/eth1.png";
import axios from "axios";
import io from "socket.io-client";
import config from "../config/config";
import dusd_png from "../assets/image/dusd.png"

function Cryptowalllet() {
    const [coinBalance, setCoinBalance] = useState(0);
    const [coin, setCoin] = useState();
    const [userBalance, setUserBalance] = useState({});
    const [BTCUSDT, setBTCUSDT] = useState(0);
    const [ETHUSDT, setETHUSDT] = useState(0);
    const [MATICUSDT, setMATICUSDT] = useState(0);
    const [DCXUSDT, setDCXUSDT] = useState(0);
    const [BNBUSDT, setBNBUSDT] = useState(0);
    const [BUSD, setBUSDT] = useState(0);
    const [DUSD, setDUSD] = useState(0);
    const [balanceLoader, setBalanceLoader] = useState(false);
    const [estimatedBalance, setEstimatedBalance] = useState(0);
    const [socketConnection, setSocketConnection] = useState(null);

    useEffect(() => {
        userWalletBalance();
        initializeSocket();
        fetchCryptoPrices();
    }, []);

    const initializeSocket = () => {
        let socket = io(config.backendurl, {
            transports: ['websocket'],
        });
        let socketUnsubscribe;
        if (socket) {
            socketUnsubscribe = socket;
            setSocketConnection(socket)
            socket.on('connect', function () {
                console.log('socket connect');
            });
            socket.on('disconnect', function () {
                console.log('socket disconnected');
            });
        }
        return () => {
            if (socketUnsubscribe) {
                socketUnsubscribe.disconnect();
            }
        };
    };

    useEffect(() => {

        if (socketConnection != null) {
            let data = {
                userId: userBalance._id,
            }
            socketConnection.emit("userWalletBalance", data);

            socketConnection.on('getBalance', async (data) => {
                if (data.data != null && Object.keys(userBalance).length) {
                    estimatedBalanceFunction(data.data);
                    setTimeout(() => {
                        setUserBalance(data.data);
                        fetchCryptoPrices();
                    }, 10000);
                    // console.log("it works",data);
                }
            });

        }

    }, [socketConnection, userBalance])

    const fetchCryptoPrices = async () => {
        try {
            const BTC_Response = await axios.get(
                "https://api.binance.com/api/v3/ticker/price",
                {
                    params: {
                        symbol: "BTCUSDT",
                    },
                }
            );
            const BTCUSDT_prices = BTC_Response.data.price;
            setBTCUSDT(Number(BTCUSDT_prices));

            const ETH_Response = await axios.get(
                "https://api.binance.com/api/v3/ticker/price",
                {
                    params: {
                        symbol: "ETHUSDT",
                    },
                }
            );
            const ETHUSDT_prices = ETH_Response.data.price;
            setETHUSDT(Number(ETHUSDT_prices));

            const BNB_Response = await axios.get(
                "https://api.binance.com/api/v3/ticker/price",
                {
                    params: {
                        symbol: "BNBUSDT",
                    },
                }
            );
            const BNBUSDT_prices = BNB_Response.data.price;
            setBNBUSDT(Number(BNBUSDT_prices));

            const USDT_Response = await axios.get(
                "https://api.binance.com/api/v3/ticker/price",
                {
                    params: {
                        symbol: "BUSDUSDT",
                    },
                }
            );
            const USDT_prices = USDT_Response.data.price;
            const DUSD_price = USDT_Response.data.price / USDT_Response.data.price
            // console.log("DUSD_price",DUSD_price);
            setBUSDT(Number(USDT_prices));
            setDUSD(Number(DUSD_price));

            //   const MATIC_Response = await axios.get(
            //     "https://api.binance.com/api/v3/ticker/price",
            //     {
            //       params: {
            //         symbol: "MATICUSDT",
            //       },
            //     }
            //   );
            //   const MATICUSDT_prices = MATIC_Response.data.price;
            //   setMATICUSDT(Number(MATICUSDT_prices));
            //   const getPrice = await axios.get(
            //     "https://api.coinstore.com/api/v1/ticker/price"
            //   );
            //   if (getPrice && getPrice.data && getPrice.data.data) {
            //     const price = getPrice.data.data.find(
            //       (item) => item.symbol === "DCXUSDT"
            //     );
            //     const currentprice = Number(price.price);
            //     setDCXUSDT(currentprice);
            //   }

        } catch (error) {
            console.log("Error fetching crypto prices:", error);
        }
    };

    const estimatedBalanceFunction = (userValue) => {
        // console.log("userValue",userValue);
        if (Object.keys(userValue).length) {
            let BTC_Value = BTCUSDT * Number(userValue.BTC_Balance)
            let ETH_Value = ETHUSDT * Number(userValue.ETH_Balance)
            let BNB_Value = BNBUSDT * Number(userValue.BNB_Balance)
            let BUSDT_Value = BUSD * Number(userValue.BUSDT_Balance)
            let DUSD_Value = DUSD * Number(userValue.DUSD_Balance)
            //   let MATIC_Value = MATICUSDT * Number(userValue.MATIC_Balance)
            //   let DCX_Value = DCXUSDT * Number(userValue.DCX_Balance)

            let sumOfCoins = BTC_Value + ETH_Value + BNB_Value + BUSDT_Value + DUSD_Value
            let fixedSumOfCoins = fixedBalance(sumOfCoins)
            // console.log("fixedSumOfCoins",fixedSumOfCoins);
            setEstimatedBalance(fixedSumOfCoins)
        }
    }



    const getCoinImage = (coinName) => {
        switch (coinName) {
            case "BTC":
                return "https://s2.coinmarketcap.com/static/img/coins/200x200/1.png";
            case "ETH":
                return "https://app.1inch.io/assets/images/network-logos/ethereum.svg";
            case "BNB":
                return "https://cryptologos.cc/logos/bnb-bnb-logo.png";
            case "BSC-USD":
                return "https://bscscan.com/token/images/busdt_32.png";
            case "DUSD":
                return dusd_png;
            // case "MATIC":
            //     return "https://app.1inch.io/assets/images/network-logos/polygon.svg";
            // case "DCX":
            //     return "https://www.d-ecosystem.io/Group-1.png";

            default:
                return null;
        }
    };

    const userWalletBalance = async () => {
        try {
            // get login email
            setBalanceLoader(false);
            const UserEmail = localStorage.getItem("email");

            const payload = {
                UserEmail: UserEmail,
            };

            const params = {
                url: `getUserWalletBalance`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                data: payload,
            };

            const response = await makeApiRequest(params);
            if (response.message == true) {
                setTimeout(() => {
                    setBalanceLoader(true);
                }, 1000);
                setUserBalance(response.data);
                estimatedBalanceFunction(response.data);
                fetchCryptoPrices()
            } else {
                setBalanceLoader(false);
            }
        } catch (error) {
            setBalanceLoader(false);
            console.log("Error fetching Balance:", error);
        }
    };

    const fixedBalance = (amt) => {
        let balanceAmt = Number(amt);
        return balanceAmt.toFixed(8);
    };


    return (
        <div className="App">
            <div className="container-fluid">
                <div className="custom-crypto-wallet">
                    <div className="">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="custom-inside-crypto-wallet">
                                    <nav className="d-flex justify-content-between mb-5 wallet-response">
                                        <h2>Estimated balance : ${estimatedBalance == 0 ? "0.00" : parseFloat(estimatedBalance).toFixed(2)}</h2>
                                        <div
                                            class="nav nav-tabs border-0 d-flex "
                                            id="nav-tab"
                                            role="tablist"
                                        >
                                            <Link to="/deposite">
                                                <button
                                                    class="nav-link  border-0 new-alter-crypto-sec-01"
                                                    id="nav-deposit-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-deposit"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="nav-deposit"
                                                    aria-selected="true"
                                                >
                                                    Deposit
                                                </button>
                                            </Link>
                                            <Link to="/withdrawcrypto">
                                                <button
                                                    class="nav-link border-0"
                                                    id="nav-withdraw-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-withdraw"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="nav-withdraw"
                                                    aria-selected="false"
                                                >
                                                    Withdraw
                                                </button>
                                            </Link>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div
                                            class="tab-pane fade show active"
                                            id="nav-deposit"
                                            role="tabpanel"
                                            aria-labelledby="nav-deposit-tab"
                                        >
                                            <div className="custom-inside-crypto-wallet-nav">
                                                {/* <h4 className='mb-5'>All</h4> */}
                                                <div className="custom-inside-crypto-wallet-table  tableFixHead">
                                                    <table class="table table-striped text-center mb-0">
                                                        <thead className="custom-symbol-table">
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Symbol</th>
                                                                <th>Coin</th>
                                                                <th>Main Wallet Balance</th>
                                                                <th>USDT Value</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td className="text-center">
                                                                    <span>
                                                                        {" "}
                                                                        <img
                                                                            className="me-2"
                                                                            src={getCoinImage("BTC")}
                                                                            alt={"BTC"}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td>BTC</td>
                                                                {balanceLoader ? (
                                                                    <>
                                                                        <td>
                                                                            {fixedBalance(userBalance.BTC_Balance)}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                fixedBalance(
                                                                                    BTCUSDT *
                                                                                    Number(userBalance.BTC_Balance)
                                                                                )}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden ">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden ">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td>
                                                                    <div className="">
                                                                        <Link
                                                                            to={`/deposite`}
                                                                            state={{ coin: "BTC" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Deposit
                                                                            </span>
                                                                        </Link>

                                                                        <Link
                                                                            to={`/buy`}
                                                                            state={{ coin: "BTC" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Buy
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td className="text-center">
                                                                    <span>
                                                                        {" "}
                                                                        <img
                                                                            className="me-2"
                                                                            src={getCoinImage("ETH")}
                                                                            alt={"ETH"}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td>ETH</td>
                                                                {balanceLoader ? (
                                                                    <>
                                                                        <td>{fixedBalance(userBalance.ETH_Balance)}</td>
                                                                        <td>
                                                                            {
                                                                                fixedBalance(
                                                                                    ETHUSDT * Number(userBalance.ETH_Balance)
                                                                                )}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td>
                                                                    <div className="">
                                                                        <Link
                                                                            to={`/deposite`}
                                                                            state={{ coin: "ETH" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Deposit
                                                                            </span>
                                                                        </Link>

                                                                        <Link
                                                                            to={`/tobulkorder`}
                                                                            state={{ coin: "ETH" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Buy
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td>3</td>
                                                                <td className="text-center">
                                                                    <span>
                                                                        {" "}
                                                                        <img
                                                                            className="me-2"
                                                                            src={getCoinImage("DCX")}
                                                                            alt={"DCX"}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td>DCX</td>
                                                                {balanceLoader ? (
                                                                    <>
                                                                        <td>{fixedBalance(userBalance.DCX_Balance)}</td>
                                                                        <td>
                                                                            {
                                                                                fixedBalance(
                                                                                    DCXUSDT * Number(userBalance.DCX_Balance)
                                                                                )}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td>
                                                                    <div className="">
                                                                        <Link
                                                                            to={`/deposite`}
                                                                            state={{ coin: "DCX" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Deposit
                                                                            </span>
                                                                        </Link>

                                                                        <Link
                                                                            to={`/tobulkorder`}
                                                                            state={{ coin: "DCX" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Buy
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td className="text-center">
                                                                    <span>
                                                                        {" "}
                                                                        <img
                                                                            className="me-2"
                                                                            src={getCoinImage("MATIC")}
                                                                            alt={"MATIC"}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td>MATIC</td>
                                                                {balanceLoader ? (
                                                                    <>
                                                                        <td>
                                                                            {fixedBalance(userBalance.MATIC_Balance)}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                fixedBalance(
                                                                                    MATICUSDT *
                                                                                    Number(userBalance.MATIC_Balance)
                                                                                )}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td>
                                                                    <div className="">
                                                                        <Link
                                                                            to={`/deposite`}
                                                                            state={{ coin: "MATIC" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Deposit
                                                                            </span>
                                                                        </Link>

                                                                        <Link
                                                                            to={`/tobulkorder`}
                                                                            state={{ coin: "MATIC" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Buy
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr> */}
                                                            <tr>
                                                                <td>3</td>
                                                                <td className="text-center">
                                                                    <span>
                                                                        {" "}
                                                                        <img
                                                                            className="me-2"
                                                                            src={getCoinImage("BNB")}
                                                                            alt={"BNB"}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td>BNB</td>
                                                                {balanceLoader ? (
                                                                    <>
                                                                        <td>{fixedBalance(userBalance.BNB_Balance)}</td>
                                                                        <td>
                                                                            {
                                                                                fixedBalance(
                                                                                    BNBUSDT * Number(userBalance.BNB_Balance)
                                                                                )}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td>
                                                                    <div className="">
                                                                        <Link
                                                                            to={`/deposite`}
                                                                            state={{ coin: "BNB" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Deposit
                                                                            </span>
                                                                        </Link>

                                                                        <Link
                                                                            to={`/tobulkorder`}
                                                                            state={{ coin: "BNB" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Buy
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                            <td>4</td>
                                                                <td className="text-center">
                                                                    <span>
                                                                        {" "}
                                                                        <img
                                                                            className="me-2"
                                                                            src={getCoinImage("BSC-USD")}
                                                                            alt={"BSC-USD"}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td>BSC-USD</td>
                                                                {balanceLoader ? (
                                                                    <>
                                                                        <td>{fixedBalance(userBalance.BUSDT_Balance)}</td>
                                                                        <td>
                                                                            {
                                                                                fixedBalance(
                                                                                    BUSD * Number(userBalance.BUSDT_Balance)
                                                                                )}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td>
                                                                    <div className="">
                                                                        <Link
                                                                            to={`/deposite`}
                                                                            state={{ coin: "BSC-USD" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Deposit
                                                                            </span>
                                                                        </Link>

                                                                        <Link
                                                                            to={`/tobulkorder`}
                                                                            state={{ coin: "BSC-USD" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Buy
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                            <td>5</td>
                                                                <td className="text-center">
                                                                    <span>
                                                                        {" "}
                                                                        <img
                                                                            className="me-2"
                                                                            src={getCoinImage("DUSD")}
                                                                            alt={"DUSD"}
                                                                        />
                                                                    </span>
                                                                </td>
                                                                <td>DUSD</td>
                                                                {balanceLoader ? (
                                                                    <>
                                                                        <td>{fixedBalance(userBalance.DUSD_Balance)}</td>
                                                                        <td>
                                                                            {
                                                                                fixedBalance(
                                                                                    DUSD * Number(userBalance.DUSD_Balance)
                                                                                )}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                class="spinner-border spinner-border-sm wallet-loader"
                                                                                role="status"
                                                                            >
                                                                                <span class="visually-hidden">
                                                                                    Loading...
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td>
                                                                    <div className="">
                                                                        <Link
                                                                            to={`/deposite`}
                                                                            state={{ coin: "DUSD" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Deposit
                                                                            </span>
                                                                        </Link>

                                                                        <Link
                                                                            to={`/tobulkorder`}
                                                                            state={{ coin: "DUSD" }}
                                                                        >
                                                                            <span className="ms-2 custom-wallet-deposite">
                                                                                Buy
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cryptowalllet;
