import React from 'react'
import render12 from '../assets/image/RENDER-1-copy.webp';

function VerificationPopup({ userData }) {
    return (
        <div className='App'>
            {/* <p>Email or KYC not verified. Please verify your email and KYC first.</p> */}
            <div className='container-fluid'>
                <div className='custom-popup-1'>
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-lg-11 pt-4'><a class="navbar-brand" href="/"><img src={render12}></img></a></div>
                    </div>
                    <div className='container'>

                        <div className='row'>
                            <div className='col-lg-12'>

                                <div className='row justify-content-center align-items-center text-center min-vh-100'>
                                    <div className='col=lg-6'>
                                        <div className='mt-1'>
                                            <p className='fs-4'>Admin needs to verify KYC. Please wait for it.</p>
                                            <p className='fs-5'>Please check whether your email is verified.</p>
                                            <a href='/dashboard/profile'>
                                                <button className='mt-1 treasure-btn-1 active'>click To Verify</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerificationPopup