import { Link, useParams } from "react-router-dom";
import Navbar from "../separate/Navbar";
import $, { isPlainObject } from "jquery";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import config from "../config/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Formik, useFormik } from "formik";
import ReactFlagsSelect, { Am } from "react-flags-select";
import "./../App.css";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";
import { makeApiRequest } from "../config/axiosService";
import { jwtDecode } from "jwt-decode";
import Select from "react-select";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import dusd_png from "../assets/image/dusd.png"

function Tobulkorder({ Ampouleno }) {
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation()
  const Walletcoin = location.state ? location.state.coin : null;
  // console.log(Walletcoin, 'walletcoin');

  const [startDate, setStartDate] = useState(new Date());
  const [countryCode, setCountryCodes] = useState();
  const [countryData, setCountryDatas] = useState({});
  const [selectedCountryName, setSelectedCountryName] = useState();
  // const [quantity, setQuantity] = useState();

  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userExist, setUserExist] = useState(false);

  // const [value, setValue] = useState('');
  const [symbol, setSymbol] = useState()
  const [tradeData, setTradeData] = useState([]);
  const [price, setPrice] = useState()
  // const [selectedValue, setSelectedValue] = useState({});
  const [balance, setBalance] = useState(0)
  const [buyPrice, setBuyPrice] = useState(0)
  const [ampoulePrice, setAmpoulePrice] = useState(0)
  const [Exactcost, setExactcost] = useState(0)
  const [ExactAmpoulecost, setExactAmpoulecost] = useState(0)
  const [networkAddress, setNetworkAddress] = useState('')
  const [PrivateKey, setPrivateKey] = useState()
  const [rpc, setRpc] = useState();
  const [addr, setAddr] = useState();
  const [validationStatus, setValidationStatus] = useState(false)

  const navigate = useNavigate()
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const [CountryName, setCountryname] = useState("")
  const [userSelectedCountry, setUserSelectedCountry] = useState('')
  const [emailVerifyStatus, setEmailVerifyStatus] = useState();
  const [kycVerifyStatus, setKYCVerifyStatus] = useState();
  const [socketConnection, setSocketConnection] = useState(null);
  const [InsufficientBulkBalance, setInsufficientBulkBalance] = useState(false)
  const [InsufficientBuyBalance, setInsufficientBuyBalance] = useState(false);

  //Balance States
  const [btcBalance, setBtcBalance] = useState()
  const [dchainBalance, setDchainBalance] = useState()
  const [ethBalance, setEthBalance] = useState()
  const [maticBalance, setMaticBalance] = useState()
  const [bnbBalance, setBnbBalance] = useState()
  const [busdtBalance, setBusdtBalance] = useState()
  const [dusdBalance, setdusdBalance] = useState()
  const [usdtprice, setUsdtprice] = useState(0)

  const [BTCUSDT, setBTCUSDT] = useState(0);
  const [ETHUSDT, setETHUSDT] = useState(0);
  const [BUSD, setBUSDT] = useState(0);
  const [DUSD, setDUSD] = useState(0);
  const [MATICUSDT, setMATICUSDT] = useState(0);
  const [DCXUSDT, setDCXUSDT] = useState(0);
  const [BNBUSDT, setBNBUSDT] = useState(0);

  const UserEmail = localStorage.getItem('email')

  const token = localStorage.getItem("token");

  const params = useParams();
 

  const utcDate = new Date();
  const currTime = utcDate.toUTCString();
  const [analysisCountryData, setAnalysisCountryData] = useState();


  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9]+(\.[a-zA-Z]{2,3}|\.[a-zA-Z]{2})$/

  useEffect(() => {
    fetchCryptoPrices();
  }, []);


  const fetchCryptoPrices = async () => {
    try {
      const BTC_Response = await axios.get(
        "https://api.binance.com/api/v3/ticker/price",
        {
          params: {
            symbol: "BTCUSDT",
          },
        }
      );
      const BTCUSDT_prices = BTC_Response.data.price;
      setBTCUSDT(Number(BTCUSDT_prices));

      const ETH_Response = await axios.get(
        "https://api.binance.com/api/v3/ticker/price",
        {
          params: {
            symbol: "ETHUSDT",
          },
        }
      );
      const ETHUSDT_prices = ETH_Response.data.price;
      setETHUSDT(Number(ETHUSDT_prices));

      const BNB_Response = await axios.get(
        "https://api.binance.com/api/v3/ticker/price",
        {
          params: {
            symbol: "BNBUSDT",
          },
        }
      );
      const BNBUSDT_prices = BNB_Response.data.price;
      setBNBUSDT(Number(BNBUSDT_prices));

      const USDT_Response = await axios.get(
        "https://api.binance.com/api/v3/ticker/price",
        {
          params: {
            symbol: "BUSDUSDT",
          },
        }
      );
      const USDT_prices = USDT_Response.data.price;
      const DUSD_price = USDT_Response.data.price / USDT_Response.data.price
      setBUSDT(Number(USDT_prices));
      setDUSD(Number(DUSD_price));
      setPrice(USDT_prices)
      setPrice(DUSD_price)


      // const MATIC_Response = await axios.get(
      //   "https://api.binance.com/api/v3/ticker/price",
      //   {
      //     params: {
      //       symbol: "MATICUSDT",
      //     },
      //   }
      // );
      // const MATICUSDT_prices = MATIC_Response.data.price;
      // setMATICUSDT(Number(MATICUSDT_prices));
      // const getPrice = await axios.get(
      //   "https://api.coinstore.com/api/v1/ticker/price"
      // );
      // if (getPrice && getPrice.data && getPrice.data.data) {
      //   const price = getPrice.data.data.find(
      //     (item) => item.symbol === "DCXUSDT"
      //   );
      //   const currentprice = Number(price.price);
      //   setDCXUSDT(currentprice);
      // }

    } catch (error) {
      console.log("Error fetching crypto prices:", error);
    }
  };


  const getbuyupdate = async () => {
    try {
      const Token = localStorage.getItem("token");
      const decoded = jwtDecode(Token);

      const id = decoded.userId;
      // console.log(id, 'iddd');

      const payload = { id };
      let params = {
        url: "updateuserdata",
        method: 'POST',
        data: payload
      }
      const response = await makeApiRequest(params);
      // console.log("updateuserdata-----",response.data);
      setValues(response.data);
      setSelectedCountryName(response.data.countryname)
      var countryData = response.data.countrycode;
      setSelectedCountryCode(countryData);
      // setValues(response.data);
      setUserSelectedCountry(response.data.countryname)

    } catch (e) {
      console.log(e);
    }
  }


  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      contactname: "",
      contactLastname: "",
      companyname: "",
      address: "",
      countryname: "",
      countrycode: "",
      pincode: "",
      mobilenumber: "",
      email: "",
      quantity: "",
      startDate: "",
      time: "",
      type: "",
    },
    // validationSchema: Yup.object().shape({
    //   contactname: Yup.string().required("Firstname is required!"),
    //   contactLastname: Yup.string().required("Lastname is required!"),
    //   companyname: Yup.string().required("Companyname is required!"),
    //   address: Yup.string().required("Address is required!"),
    //   pincode: Yup.number().required("Pincode is required!"),
    //   mobilenumber: Yup.string().required("Mobilenumber is required!"),
    //   quantity: Yup.string().required("Quantity is required!"),
    //   email: Yup.string()
    //     .email("Please enter valid email!")
    //     .required("E-mail is required!")
    //     .matches(regex, "Invalid Email!"),
    // }),
  });

  const getVerifyStatus = async () => {
    let params = {
      url: `verifyEmailStatus`,
      method: "GET",
    }
    const response = await makeApiRequest(params);
    console.log("response----+>",response);
    if (response.status == true) {
      const verifyEmailStatus = response.data.emailVerifyStatus;
      const verifyKYCStatus = response.data.kycVerifyStatus;
      if (verifyEmailStatus && verifyKYCStatus) {
        setEmailVerifyStatus(verifyEmailStatus);
        setKYCVerifyStatus(verifyKYCStatus);
      } 
    }else{
      setEmailVerifyStatus(0);
        setKYCVerifyStatus(0);
    }
  }


  let path = window.location.pathname;
  let pathUrl = path === "/buy" ? "buyOrder" : "bulkOrder";
  // if (selectedCountryCode && selectedCountryCode !== undefined && selectedCountryCode !== "undefined") {
  //   if (Array.isArray(countryData)) {
  //     countryData.map((data)=>{
  //       if (data.code === selectedCountryCode) {
  //         setCountryname(data.name)
  //       }
  //     })
  //   }
  // }


  const handleSubmit = async (e) => {
    console.log("working");
    e.preventDefault();
    handleChange(e);

    try {
      if (emailVerifyStatus == 1 && kycVerifyStatus == 2 ) {
        const Token = localStorage.getItem("token");
        const decoded = jwtDecode(Token);
        let userId = decoded.userId;

        const minPin = 4; // Replace with your minimum pin code value
        const maxPin = 10; // R0x311C73b81fba200520a0a35DABE781c79d697542eplace with your maximum pin code value
        const isValidPin = /^\d{4}$/.test(values.pincode);
        // const sanitizedPincode = values.pincode.replace(/[^a-zA-Z]/g, '');
        const sanitizedName = values.contactname.replace(/[^a-zA-Z]/g, '');
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        try {
          if (window.location.pathname === '/tobulkorder' || window.location.pathname == '/tobulkorder/') {
            if (!values.contactname) {
              return toast.error('First Name is required');
            }

            if (!values.contactLastname) {
              return toast.error('Last Name is required');
            }
            // else if (!values.companyname) {
            //   return toast.error('Company Name is required');
            // }
            else if (values.address === "") {
              return toast.error('Please fill in the Address');
            }
            else if (!values.mobilenumber) {
              return toast.error('Mobile number is required ');
            }
            else if (values.mobilenumber.length < 10) {
              // console.log('mobilenumber length');
              return toast.error('Mobile number must be at least 10 digits');
            }
            else if (!values.email) {
              return toast.error('Email is required');
            }
            else if (!emailRegex.test(values.email)) {
              return toast.error('Invalid email format. Please enter a valid email address.');

              // if (!emailRegex.test(values.email)) {
              //   return toast.error('Invalid email format. Please enter a valid email address.');
              // }
            }
            else if (values.pincode == 0) {
              return toast.error('Please fill pin code.');
            }
            else if (values.pincode.length <= minPin) {
              return toast.error('Pin code must be above than 4.');
            }
            else if (values.pincode.length > maxPin) {
              return toast.error('Pin code must be less than or equal to 10.');
            }

            else if (!value) {
              return toast.error('Please select the network coin ')

            }

            else if (!values.quantity) {
              return toast.error('Ampoule is required');
            } else if (values.quantity === '0') {
              return toast.error('Ampoule quantity  zero is not allowed');
            } else if (values.quantity === '0' || /^\d+\.\d+$/.test(values.quantity)) {
              return toast.error('Ampoule quantity  decimals are not allowed');
            } else if (values.quantity > 50) {
              return toast.error('Maximum 50 Quantity For One Time Purchase ');
            } else if (countryData == "") {
              return toast.error('Please select Country');
            }
            else {
              const selectedCountry = countries.find(
                (country) => country.code === selectedCountryCode
              );
              if (!selectedCountry) {
                return toast.error('Please selecte country code.');
              }
            }

            // console.log(pathUrl, 'pathurl');
            // if (Exactcost == 0) {
            //   toast.error("Insufficient Balance")
            // }
            setIsLoading(true);
            setValidationStatus(true)

            const totalAmount = ampoulePrice;
            const amount = fixedBalance(totalAmount / price)
           
            const payload = {
              userId: userId,
              productId: Ampouleno,
              contactname: values.contactname,
              contactLastname: values.contactLastname,
              companyname: values.companyname,
              address: values.address,
              countryname: selectedCountryName,
              countrycode: selectedCountryCode,
              pincode: values.pincode,
              mobilenumber: values.mobilenumber,
              quantity: values.quantity,
              usdtPrice: totalAmount,
              network: value,
              amount: amount,
              time: currTime,
              date: startDate,
            };
            // console.log(payload, "payload");
            let param = {
              url: `${pathUrl}`,
              method: "POST",
              data: payload,
            };
            try {

              if (totalAmount >= usdtprice) {
                setIsLoading(false)
                toast.error('Insufficient Balance');
              }
              else {
                setIsLoading(true)

                const res = await makeApiRequest(param);

                if (res.status == true) {
                  toast.success('Bulk Order purchases Successfully')
                  setTimeout(() => {
                    window.location.href = "/Thankyoupage"
                  }, 4000);
                  localStorage.setItem("order", `${pathUrl}`);
                } else {
                  setIsLoading(false)
                  toast.error(res.message);
                }
              }

            } catch (error) {
              setIsLoading(false)
              console.error('Error:', error.message);
            }
          }
          else {
            let userQuantity = Number(values.quantity);
            // console.log("userQuantity",!userQuantity,userQuantity);
            if (!userQuantity) {
              return toast.error('Quantity is required');
            }
            else if (!userQuantity && userQuantity == 0) {
              return toast.error('Ampoule quantity zero is not allowed');
            } else if (values.quantity === "0" || /^\d+\.\d+$/.test(values.quantity)) {
              return toast.error('Ampoule quantity decimals are not allowed');
            } else if (userQuantity > 199) {
              return toast.error("If you want 200 or above.Kindly make a Order from Bulk page")
            }
            else if (!value) {
              return toast.error('Please select the network coin ')

            }
            else {
              // if (ExactAmpoulecost == 0) {
              //   toast.error("Insufficient Balance")
              // }0.3759
              // console.log("values.quantity",typeof values.quantity);
              // return false;
              setIsLoading(true)
              setValidationStatus(true)

              const totalAmount = buyPrice * values.quantity
              // console.log("totalAmount--",totalAmount);
              const amount = fixedBalance(totalAmount / price)
              // console.log("amount---", amount);

              const payload = {
                userId: userId,
                // productId: Ampouleno,
                quantity: values.quantity,
                network: value,
                amount: amount,
                usdtPrice: totalAmount,
                time: currTime,
                date: startDate,
                // type: window.location.pathname,
              };

              let param = {
                url: `${pathUrl}`,
                method: "POST",
                data: payload,
              };

              try {
                if (totalAmount >= usdtprice) {
                  setIsLoading(false)
                  toast.error('Insufficient Balance');
                }
                else {

                  const res = await makeApiRequest(param);
                  // console.log('bulkorder======res===', res);

                  if (res.status == true) {
                    // setIsLoading(false);
                    toast.success('Fraction Order Completed Successfully')
                    setTimeout(() => {
                      window.location.href = "/Thankyoupage"
                    }, 4000);
                    localStorage.setItem("order", `${pathUrl}`);
                  } else {
                    // console.log('else');
                    setIsLoading(false)
                    toast.error(res.message);
                  }

                }
              } catch (error) {
                setIsLoading(false)
                console.error('Error:', error.message);
              }
            }
          }
        }
        catch (error) {
          setIsLoading(false)
          console.log(error.message);
        }
      }
      else{
        // Show an error message or take appropriate action
        toast.error('Email or KYC not verified. Please verify your email and KYC first.');
        setTimeout(() => {
          window.location.href = '/dashboard/profile';
        }, 2000);
      }
    } catch (error) {
      setIsLoading(false)
      console.log("error", error.message);
    }
  }

  const fetchCountryData = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
      const data = await response.json();
      setCountryDatas(data.country_name)
      setCountryCodes(data.country_code?.toLowerCase())
      // console.log(data.country_code, "countryCode");
      // setSelectedCountryCode(data.country_code)
      // setSelectedCountryName(data.country_name)
      setCountryCodes(values.country_code)
      // console.log(values.country_code,"values.country_code");
    } catch (error) {
      console.error("Error fetching country data: ", error);
      setCountryDatas("us");
    }
  }

  const userFind = async () => {
    if (token) {
      const Token = localStorage.getItem("token");
      const decoded = jwtDecode(Token);

      const id = decoded.userId;
      // console.log(id, 'iddd');

      const payload = { id };

      let params = {
        url: `userFind`,
        method: "POST",
        data: payload,
      };

      const response = await makeApiRequest(params);
      if (response.message == "User Exists") {
        setUserExist(true);
      } else {
        setUserExist(false);
      }
      // }
    };
  }

  const handlePhoneChange = (value, country, e, formettedValue) => {
    handleChange(e);

    setValues((prevValues) => ({
      ...prevValues,
      mobilenumber: formettedValue,
    }));
  };

  let name = "";
  if (
    window.location.pathname === "/buy" || window.location.pathname == '/buy/'
  ) {
    name = "Buy";
  } else if (window.location.pathname === "/tobulkorder" || window.location.pathname == '/tobulkorder/') {
    name = "Bulk";
  }



  const defaultCountrySelect = async () => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        // Extract country codes and names from the response data
        const countryData = response.data.map((countrycode) => ({
          code: countrycode.cca2,
          name: countrycode.name.common,
        }));
        setCountries(countryData);

        const filteredCountries = countryData.filter((countrycode) => countrycode.code === selectedCountryCode);
        // console.log("filteredCountries", filteredCountries);
        if (filteredCountries) {
          setSelectedCountryName(filteredCountries[0].name);
          setSelectedCountryCode(filteredCountries[0].code);
        }
      })
      .catch((error) => {
        // console.error("Error fetching country data:", error);
      });
  }
  // console.log("countryname",countryname);
  const customStyles = {
    menu: base => ({
      ...base,
      background: "#FFF",
      margin: "0px",
      padding: "0px",

    }),
    control: (base, state) => ({
      ...base,
      background: "transparent",
      color: "#000",
      cursor: "pointer",
      // Overwrittes the different states of border
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "none" : "transparent",
        color: "black",
        cursor: "pointer",
        '&:active': {
          background: '#bc9018',
        },
      };

    }
  };


  const options = [
    {
      value: 'BTC', Symbol: 'btcusdt', label: <a><div className="select-coin-hover" style={{ color: "black", display: "flex" }}>
        <img src={"https://s2.coinmarketcap.com/static/img/coins/200x200/1.png"} className="pb-1 me-2" style={{ width: "20px", color: "black" }} />Bitcoin <span className="option-new-text">- {btcBalance !== undefined ? btcBalance :
          <span>
            {/* <img src={dicon} className="diconlogo" /> */}
            <span class="spinner-border text-warning icon-fs wallet-loader" role="status">
              <span class="visually-hidden"></span>
            </span>
          </span>
        }</span>
      </div></a>
    },
   
    {
      value: 'DUSD', Symbol: 'dcxusdt', label: <a><div style={{ color: "black", display: "flex" }}>
        <img src={dusd_png} className=' pb-1 me-2' style={{ width: "20px", color: "black" }} />DUSD
        <span className="option-new-text">-{dusdBalance !== undefined ? dusdBalance :
          <span>
            {/* <img src={dicon} className="diconlogo" /> */}
            <span class="spinner-border text-warning icon-fs wallet-loader" role="status">
              <span class="visually-hidden"></span>
            </span>
          </span>
        }</span>
      </div></a>
    },
    {
      value: 'BSC-USD', Symbol: 'busdt', label: <a><div style={{ color: "black", display: "flex" }}>
        <img src={"https://bscscan.com/token/images/busdt_32.png"} className="pb-1 me-2" style={{ width: "20px" }} /> BSC-USD
        <span className="option-new-text">- {busdtBalance !== undefined ? busdtBalance :
          <span>
            {/* <img src={dicon} className="diconlogo" /> */}
            <span class="spinner-border text-warning icon-fs wallet-loader" role="status">
              <span class="visually-hidden"></span>
            </span>
          </span>
        }</span>
      </div></a>
    },
    {
      value: 'ETH', Symbol: 'ethusdt', label: <a><div style={{ color: "black", display: "flex" }} >
        <img src={"https://app.1inch.io/assets/images/network-logos/ethereum.svg"} className="pb-1 me-2" style={{ width: "20px", color: "black" }} />ETH <span className="option-new-text">- {ethBalance !== undefined ? ethBalance :
          <span>
            {/* <img src={dicon} className="diconlogo" /> */}
            <span class="spinner-border text-warning icon-fs wallet-loader" role="status">
              <span class="visually-hidden"></span>
            </span>
          </span>
        }</span>
      </div></a>
    },
    {
      value: 'BNB', Symbol: 'bnbusdt', label: <a><div style={{ color: "black", display: "flex" }}>
        <img src={"https://cryptologos.cc/logos/bnb-bnb-logo.png"} className="pb-1 me-2" style={{ width: "20px" }} /> BNB <span className="option-new-text">- {bnbBalance !== undefined ? bnbBalance :
          <span>
            {/* <img src={dicon} className="diconlogo" /> */}
            <span class="spinner-border text-warning icon-fs wallet-loader" role="status">
              <span class="visually-hidden"></span>
            </span>
          </span>
        }</span>
      </div></a>
    },
  ];

  const [selectedValue, setSelectedValue] = useState();
  const [value, setValue] = useState();


  const HandleSelectChange = (e) => {
    setValue(e.value);
    const value = e.value;
    // console.log(value, '-------value');
    options.map((data) => {
      if (value === data.value) {
        setSelectedValue(data)
        setSymbol(data.Symbol)
      }

      if (value) {
        if (value == "BTC") {
          setRpc(config.btc_rpc);
        } else if (value == "DUSD") {
          setRpc(config.dcx_rpc);
        } else if (value == "BSC-USD") {
          setRpc(config.bnb_rpc)
        }
        // else if (value == "DCX") {
        //   setRpc(config.dcx_rpc);
        // } else if (value == "MATIC") {
        //   setRpc(config.matic_rpc)
        // } 
        else if (value == "ETH") {
          setRpc(config.eth_rpc);
        } else if (value == "BNB") {
          setRpc(config.bnb_rpc)
        } else {
          console.log("Wrong value");
        }
      }
    })
  }


  const userAvailableGram = (event) => {
    const { name, value } = event.target;
    // console.log(event.target.value, 'e');
    setValues({ ...values, [name]: value });
    options.map((data) => {
      if (data.value == 'BTC') {
        let convertedBal = Number(btcBalance).toFixed(5)
        let convertedPrice = Number(price).toFixed(8)
        const USDTprice = convertedBal * convertedPrice;

        //buy price
        const productcost = Math.floor(USDTprice.toFixed(5) / buyPrice)

        const exactCost = parseFloat(productcost)
        if (isNaN(exactCost)) {
          setExactcost("-")
        }
        else {
          setExactcost(exactCost)
        }

        const enteredBuyQuantity = parseInt(value);
        // if (!isNaN(enteredBuyQuantity) && enteredBuyQuantity > exactCost) {
        if (enteredBuyQuantity > Exactcost) {
          setInsufficientBuyBalance(true);
        } else {
          setInsufficientBuyBalance(false);
        }

        //ampoule price
        const ampoulecost = Math.floor(USDTprice / ampoulePrice)
        // const ampoulecost = USDTprice / ampoulePrice
        const exactAmpoulcost = parseFloat(ampoulecost)
        if (isNaN(exactAmpoulcost)) {
          setExactAmpoulecost("-")
        }
        else {
          setExactAmpoulecost(exactAmpoulcost)
        }

        const enteredBulkQuantity = parseInt(value);
        if (!isNaN(enteredBulkQuantity) && enteredBulkQuantity > ExactAmpoulecost) {
          setInsufficientBulkBalance(true);
        } else {
          setInsufficientBulkBalance(false);
        }
      }

      else if (data.value == 'ETH') {
        // console.log('sepolia');
        let convertedBal = Number(ethBalance).toFixed(5)
        let convertedPrice = Number(price).toFixed(8)
        const USDTprice = convertedBal * convertedPrice;
        // console.log(USDTprice, 'USDTprice');

        //buy price
        const productcost = Math.floor(USDTprice.toFixed(5) / buyPrice)

        const exactCost = parseFloat(productcost)
        if (isNaN(exactCost)) {
          setExactcost("-")
        }
        else {
          setExactcost(exactCost)
        }

        const enteredBuyQuantity = parseInt(value);
        if (!isNaN(enteredBuyQuantity) && enteredBuyQuantity > Exactcost) {
          setInsufficientBuyBalance(true);
        } else {
          setInsufficientBuyBalance(false);
        }

        const enteredBulkQuantity = parseInt(value);
        if (!isNaN(enteredBulkQuantity) && enteredBulkQuantity > ExactAmpoulecost) {
          setInsufficientBulkBalance(true);
        } else {
          setInsufficientBulkBalance(false);
        }

        //ampoule price
        const ampoulecost = Math.floor(USDTprice / ampoulePrice)
        // const ampoulecost = USDTprice / ampoulePrice
        const exactAmpoulcost = parseFloat(ampoulecost)
        if (isNaN(exactAmpoulcost)) {
          setExactAmpoulecost("-")
        }
        else {
          setExactAmpoulecost(exactAmpoulcost)
        }

      }

      else if (data.value == 'BNB') {
        let convertedBal = Number(bnbBalance).toFixed(8)
        let convertedPrice = Number(price).toFixed(8)
        const USDTprice = convertedBal * convertedPrice;
        //buy price
        const productcost = Math.floor(USDTprice.toFixed(8) / buyPrice)

        const exactCost = parseFloat(productcost)
        if (isNaN(exactCost)) {
          setExactcost("-")
        }
        else {
          setExactcost(exactCost)
        }

        const enteredBuyQuantity = parseInt(value);
        if (!isNaN(enteredBuyQuantity) && enteredBuyQuantity > Exactcost) {
          setInsufficientBuyBalance(true);
        } else {
          setInsufficientBuyBalance(false);
        }

        //ampoule price
        const ampoulecost = Math.floor(USDTprice / ampoulePrice)
        // const ampoulecost = USDTprice / ampoulePrice
        const exactAmpoulcost = parseFloat(ampoulecost)
        if (isNaN(exactAmpoulcost)) {
          setExactAmpoulecost("-")
        }
        else {
          setExactAmpoulecost(exactAmpoulcost)
        }

        const enteredBulkQuantity = parseInt(value);
        if (!isNaN(enteredBulkQuantity) && enteredBulkQuantity > ExactAmpoulecost) {
          setInsufficientBulkBalance(true);
        } else {
          setInsufficientBulkBalance(false);
        }
      }

      else if (data.value == 'DUSD') {
        let convertedBal = Number(dusdBalance).toFixed(5)
        // let convertedPrice = Number(price).toFixed(8)
        const USDTprice = convertedBal * DUSD
        setUsdtprice(USDTprice.toFixed(5))
        // console.log("USDTprice", USDTprice, "convertedBal", convertedBal, "DUSD", DUSD);
        //buy price
        const productcost = (USDTprice / buyPrice)
        // console.log("productcost--",productcost,"buyPrice",buyPrice);

        const exactCost = parseFloat(productcost)
        // console.log("exactCost--",exactCost);
        if (isNaN(exactCost)) {
          setExactcost("-")
        }
        else {
          setExactcost(exactCost)
        }

        const enteredBuyQuantity = parseInt(value);
        if (!isNaN(enteredBuyQuantity) && enteredBuyQuantity > Exactcost) {
          setInsufficientBuyBalance(true);
        } else {
          setInsufficientBuyBalance(false);
        }

        //ampoule price
        const ampoulecost = Math.floor(USDTprice / ampoulePrice)
        // console.log("ampoulecost--",ampoulecost, USDTprice, ampoulePrice);
        // const ampoulecost = USDTprice / ampoulePrice
        const exactAmpoulcost = parseFloat(ampoulecost)
        if (isNaN(exactAmpoulcost)) {
          setExactAmpoulecost("-")
        }
        else {
          setExactAmpoulecost(exactAmpoulcost)
        }

        const enteredBulkQuantity = parseInt(value);
        if (!isNaN(enteredBulkQuantity) && enteredBulkQuantity > ExactAmpoulecost) {
          setInsufficientBulkBalance(true);
        } else {
          setInsufficientBulkBalance(false);
        }
      }

      else if (data.value == 'BSC-USD') {
        let convertedBal = Number(busdtBalance).toFixed(5)
        // let convertedPrice = Number(price).toFixed(8)
        const USDTprice = convertedBal * BUSD
        setUsdtprice(USDTprice.toFixed(5))
        //buy price
        const productcost = Math.floor(USDTprice / buyPrice)

        const exactCost = parseFloat(productcost)
        if (isNaN(exactCost)) {
          setExactcost("-")
        }
        else {
          setExactcost(exactCost)
        }

        const enteredBuyQuantity = parseInt(value);
        if (!isNaN(enteredBuyQuantity) && enteredBuyQuantity > Exactcost) {
          setInsufficientBuyBalance(true);
        } else {
          setInsufficientBuyBalance(false);
        }

        //ampoule price
        const ampoulecost = Math.floor(USDTprice / ampoulePrice)
        // const ampoulecost = USDTprice / ampoulePrice
        const exactAmpoulcost = parseFloat(ampoulecost)
        if (isNaN(exactAmpoulcost)) {
          setExactAmpoulecost("-")
        }
        else {
          setExactAmpoulecost(exactAmpoulcost)
        }

        const enteredBulkQuantity = parseInt(value);
        if (!isNaN(enteredBulkQuantity) && enteredBulkQuantity > ExactAmpoulecost) {
          setInsufficientBulkBalance(true);
        } else {
          setInsufficientBulkBalance(false);
        }
      }

    })

  };

  const AvailableGram = () => {
    // return
    if (value == 'BTC') {
      let convertedBal = Number(btcBalance).toFixed(8)
      let convertedPrice = Number(price).toFixed(8)
      const USDTprice = convertedBal * convertedPrice;

      //buy price
      const productcost = Math.floor(USDTprice.toFixed(8) / buyPrice)
      const exactCost = parseFloat(productcost)
      if (isNaN(exactCost)) {
        setExactcost(0)
      }
      else {
        setExactcost(exactCost)
      }

      //ampoule price
      const ampoulecost = Math.floor(USDTprice / ampoulePrice)
      // const ampoulecost = USDTprice / ampoulePrice
      const exactAmpoulcost = parseFloat(ampoulecost)
      if (isNaN(exactAmpoulcost)) {
        setExactAmpoulecost("-")
      }
      else {
        setExactAmpoulecost(exactAmpoulcost)
      }
    }

    else if (value == 'DUSD') {
      let convertedBal = Number(dusdBalance).toFixed(5)
      // let convertedPrice = Number(price).toFixed(8)
      const USDTprice = convertedBal * DUSD
      setUsdtprice(USDTprice.toFixed(5))
      // console.log("USDTprice", USDTprice, "convertedBal", convertedBal, "DUSD", DUSD);
      //buy price
      // const productcost = Math.floor(USDTprice.toFixed(5) / buyPrice)
      const productcost = Math.floor(USDTprice / buyPrice)
      // console.log("productcost", productcost);

      const exactCost = parseFloat(productcost)
      // console.log("available gram --- exact cost--", exactCost);
      if (isNaN(exactCost)) {
        setExactcost(0)
      }
      else {
        setExactcost(exactCost)
      }

      //ampoule price
      const ampoulecost = Math.floor(USDTprice / ampoulePrice)
      // const ampoulecost = USDTprice / ampoulePrice
      const exactAmpoulcost = parseFloat(ampoulecost)
      if (isNaN(exactAmpoulcost)) {
        setExactAmpoulecost("-")
      }
      else {
        setExactAmpoulecost(exactAmpoulcost)
      }
    }

    else if (value == 'ETH') {
      // console.log('sepolia');
      let convertedBal = Number(ethBalance).toFixed(5)
      let convertedPrice = Number(price).toFixed(8)
      const USDTprice = convertedBal * convertedPrice;
      // console.log(USDTprice, 'USDTprice');

      //buy price
      const productcost = Math.floor(USDTprice.toFixed(5) / buyPrice)

      const exactCost = parseFloat(productcost)
      if (isNaN(exactCost)) {
        setExactcost(0)
      }
      else {
        setExactcost(exactCost)
      }

      //ampoule price
      const ampoulecost = Math.floor(USDTprice / ampoulePrice)
      // const ampoulecost = USDTprice / ampoulePrice
      const exactAmpoulcost = parseFloat(ampoulecost)
      if (isNaN(exactAmpoulcost)) {
        setExactAmpoulecost("-")
      }
      else {
        setExactAmpoulecost(exactAmpoulcost)
      }
    }

    else if (value == 'BSC-USD') {
      let convertedBal = Number(busdtBalance).toFixed(5)
      // let convertedPrice = Number(price).toFixed(8)
      const USDTprice = convertedBal * BUSD
      setUsdtprice(USDTprice.toFixed(5))
      //buy price
      const productcost = Math.floor(USDTprice / buyPrice)
      const exactCost = parseFloat(productcost)
      if (isNaN(exactCost)) {
        setExactcost(0)
      }
      else {
        setExactcost(exactCost)
      }

      //ampoule price
      const ampoulecost = Math.floor(USDTprice / ampoulePrice)
      // const ampoulecost = USDTprice / ampoulePrice
      const exactAmpoulcost = parseFloat(ampoulecost)
      if (isNaN(exactAmpoulcost)) {
        setExactAmpoulecost("-")
      }
      else {
        setExactAmpoulecost(exactAmpoulcost)
      }
    }

    else if (value == 'BNB') {
      let convertedBal = Number(bnbBalance).toFixed(5)
      let convertedPrice = Number(price).toFixed(8)
      const USDTprice = convertedBal * convertedPrice;
      //buy price
      const productcost = Math.floor(USDTprice.toFixed(5) / buyPrice)

      const exactCost = parseFloat(productcost)
      if (isNaN(exactCost)) {
        setExactcost(0)
      }
      else {
        setExactcost(exactCost)
      }

      //ampoule price
      const ampoulecost = Math.floor(USDTprice / ampoulePrice)
      // const ampoulecost = USDTprice / ampoulePrice
      const exactAmpoulcost = parseFloat(ampoulecost)
      if (isNaN(exactAmpoulcost)) {
        setExactAmpoulecost("-")
      }
      else {
        setExactAmpoulecost(exactAmpoulcost)
      }
    }


    // console.log(ExactAmpoulecost, 'ExactAmpoulecost');

  };

  // console.log(usdtprice, 'price');


  useEffect(() => {
    fetchCountryData();
    userFind();
    // defaultCountrySelect();
    getVerifyStatus();
    getbuyupdate();
  }, []);

  // useEffect(() => {
  //   coinName()
  // }, [Walletcoin])

  useEffect(() => {
    defaultCountrySelect();
  }, [selectedCountryCode])

  useEffect(() => {
    const initialTradeData = {};

    if (value == 'ETH' || value == 'BNB') {

      const socket = new WebSocket(`wss://stream.binance.com:9443/ws/${symbol}@ticker`);
      socket.onopen = () => {
        console.log('WebSocket Connected');
      };

      socket.onmessage = (event) => {
        // console.log("event", event);
        const messageObject = JSON.parse(event.data);
        // console.log('Received message:', messageObject);

        initialTradeData[symbol] = messageObject;
        setTradeData({ ...initialTradeData });
        setPrice(messageObject.c)

        if (symbol == 'ethusdt') {
          let convertedBal = Number(ethBalance).toFixed(5)
          // console.log(convertedBal, 'convertedBal');
          let convertedPrice = Number(messageObject.c).toFixed(8)
          const USDTprice = convertedBal * convertedPrice;
          setUsdtprice(USDTprice.toFixed(5))
        }
        else {
          let convertedBal = Number(bnbBalance).toFixed(5)
          let convertedPrice = Number(messageObject.c).toFixed(8)
          const USDTprice = convertedBal * convertedPrice;
          setUsdtprice(USDTprice.toFixed(5))
        }

      };

      socket.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      // console.log(usdtprice, 'usdtprice');

      return () => {
        socket.close();
      };
    }
  }, [symbol]);

  useEffect(() => {

    if (value == 'BTC') {

      const initialTradeData = {};

      const ws = new WebSocket(`wss://ws.coinstore.com/s/ws`);
      ws.onopen = function () {
        const payload = {
          "op": "SUB",
          "channel": [
            "btcusdt@ticker",
          ],
          "id": 1
        }
        ws.send(JSON.stringify(payload));
        // console.log("ok")
      };

      ws.onmessage = (event) => {
        const messageObject = JSON.parse(event.data);
        // const messageObject = event.data
        const livePrice = messageObject.close
        // console.log(messageObject, 'messageobject');
        // console.log(livePrice, 'liveprice');


        initialTradeData[symbol] = messageObject;
        setTradeData({ ...initialTradeData });
        setPrice(livePrice)

        let convertedBal = Number(btcBalance).toFixed(5)
        let convertedPrice = Number(messageObject.close).toFixed(8)
        const USDTprice = convertedBal * convertedPrice;
        setUsdtprice(USDTprice.toFixed(5))

      };

      ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };


      return () => {
        ws.close();
      };
    }
  }, [symbol]);

  useEffect(() => {
    if (value == "BSC-USD" || value == "DUSD") {
      if (symbol == "busdt") {
        const usdtPrice = Number(busdtBalance).toFixed(5)
        setUsdtprice(usdtPrice)
      } else {
        const usdtPrice = Number(dusdBalance).toFixed(5)
        setUsdtprice(usdtPrice)
      }
    }
  }, [symbol])

  const fixedBalance = (amt) => {
    let balanceAmt = Number(amt);
    return balanceAmt.toFixed(5);
  };

  useEffect(() => {
    AvailableGram();
  })

  useEffect(() => {
    if (token) {
      const Token = localStorage.getItem("token");
      const decoded = jwtDecode(Token);
      let userId = decoded.userId;

      let socket = io(config.backendurl, {
        transports: ["websocket"],
      });

      let socketUnsubscribe;
      if (socket) {
        socket.on("connect", function () {
          // console.log("socket connected");
          setSocketConnection(socket);
          socketUnsubscribe = socket;

          if (socket !== null) {
            socket.on("productPriceData", (data) => {
              // console.log("data", data);
              if (data.status === true) {
                setBuyPrice(data.data[0].currentfractioncost)
                setAmpoulePrice(data.data[0].currentbulkcost)
                setIsLoading(false)
              }
            });

            socket.on("getBalance", (data) => {

              if (data && data.status == true) {
                setBtcBalance(fixedBalance(data.BTCbalanceEther))
                // setDchainBalance(fixedBalance(data.dchainbalanceEther))
                // setMaticBalance(fixedBalance(data.MaticbalanceEther))
                setEthBalance(fixedBalance(data.sepoliabalanceEther))
                setBnbBalance(fixedBalance(data.BNBbalanceEther))
                setBusdtBalance(fixedBalance(data.BUSDTbalance))
                setdusdBalance(fixedBalance(data.DUSDbalance))
                setBalance(fixedBalance(data.data))
                setIsLoading(false)
              } else if (data && data.status == null) {
                setBtcBalance(0)
                // setDchainBalance(0)
                // setMaticBalance(0)
                setEthBalance(0)
                setBnbBalance(0)
                setBusdtBalance(0)
                setdusdBalance(0)
                setBalance(0)
                setIsLoading(true)
              }
            });

          }
          socket.emit("getProductPrice");

          if (socket != null && value != '') {
            let data = {
              userId: userId,
              UserEmail: UserEmail,
              value: value
            }
            let Baldata = {
              userId: userId,
            }
            socket.emit("userBalance", data);
            socket.emit("WalletBalance", Baldata);
          }
          else {
            console.log('error');
          }

          if (socket != null && value != '') {
            let data = {
              userId: userId,
              UserEmail: UserEmail,
              value: value
            }
            socket.emit("userBalance", data);
            socket.emit("WalletBalance", data);
          }
          else {
            console.log('error');
          }

        });

        socket.on("disconnect", function () {
          console.log("socket disconnected");
        });

      }
      return () => {
        if (socketUnsubscribe) {
          socketUnsubscribe.disconnect();
        }
      };
    }
  }, [value, UserEmail]);

  $(function () {
    $("#exampleCheck1").click(function () {
      if ($(this).is(":checked")) {
        $("#dvPassport").show();

      } else {
        $("#dvPassport").hide();

      }
    });
  });

  return (

    <div className="App custom-tobulk-nav">
      <ToastContainer />
      <div className="container-fluid">
        {window.location.pathname == "/tobulkorder" || window.location.pathname == "/tobulkorder/" ? <Navbar /> : <></>}
        <div className="custom-bulk-order">
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <div className="custom-inside-bulk-order">
                  {window.location.pathname == "/tobulkorder" || window.location.pathname == "/tobulkorder/" ? (
                    <h2 className="fs-6 p-2">
                      In addition to the opportunity to purchase UFCP in CUP
                      tokens, you also have the option to purchase UFCP
                      physically from us. Fill out the information below and
                      click submit. Our sales manager will contact you.
                    </h2>
                  ) : (
                    <h2 className="fs-4">BUY CUP TOKEN VIA CRYPTO TRANSFER</h2>
                  )}
                </div>
                {window.location.pathname == '/tobulkorder' || window.location.pathname == "/tobulkorder/" ? (
                  <form
                    className="new-contact-form"
                    name="footercontact"
                    id="footercontact"
                    autocomplete="off"
                    novalidate="novalidate"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className="row align-items-center justify-content-center">
                      <div className="cols-lg-12 custom-bulk-box">
                        <div className="custom-contact-form align-items-center justify-content-center">
                          <div class="mb-3 row justify-content-center">
                            <label
                              for="inputPassword"
                              class="col-sm-3 col-form-label custom-contact-color"
                            >
                              Contact name
                            </label>
                            <div class="col-sm-4">
                              <span></span>
                              <input
                                type="text"
                                name="contactname"
                                class="form-control me-auto py-2 custom-value-color"
                                id="inputPassword"
                                placeholder="First Name"
                                maxLength={15}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.contactname}
                                onKeyPress={(event) => {
                                  const keyCode = event.which || event.keyCode;
                                  const keyValue = String.fromCharCode(keyCode);

                                  // Allow only numeric values (0-9) and the backspace key (8)
                                  if (!/^[a-zA-Z]+$/.test(keyValue)) {
                                    event.preventDefault();
                                  }
                                }}
                                invalid={
                                  touched.contactname && errors.contactname
                                    ? true
                                    : false
                                }
                              />
                              {touched.contactname && errors.contactname && (
                                <small className="invalid-email error password-text-33">
                                  {errors.contactname}
                                </small>
                              )}
                            </div>
                            <div class="col-sm-4">
                              <input
                                type="text"
                                name="contactLastname"
                                class="form-control me-auto py-2 custom-value-color"
                                id="inputPassword"
                                placeholder="Last Name"
                                maxLength={15}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.contactLastname}
                                onKeyPress={(event) => {
                                  const keyCode = event.which || event.keyCode;
                                  const keyValue = String.fromCharCode(keyCode);

                                  // Allow only numeric values (0-9) and the backspace key (8)
                                  if (!/^[a-zA-Z]+$/.test(keyValue)) {
                                    event.preventDefault();
                                  }
                                }}
                                invalid={
                                  touched.contactLastname &&
                                    errors.contactLastname
                                    ? true
                                    : false
                                }
                              />
                              {touched.contactLastname &&
                                errors.contactLastname && (
                                  <small className="invalid-email error password-text-33">
                                    {errors.contactLastname}
                                  </small>
                                )}
                            </div>
                          </div>
                          <div class="mb-3 row align-items-center justify-content-center">
                            <label
                              for="inputPassword"
                              class="col-sm-3 col-form-label custom-contact-color"
                            >
                              Company name
                            </label>
                            <div class="col-sm-8">
                              <input
                                type="text"
                                name="companyname"
                                class="form-control me-auto"
                                id="inputPassword"
                                placeholder="Company Name"
                                maxLength={100}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.companyname}
                                onKeyPress={(event) => {
                                  const keyCode = event.which || event.keyCode;
                                  const keyValue = String.fromCharCode(keyCode);

                                  // Allow only numeric values (0-9) and the backspace key (8)

                                  if (!/^[a-zA-Z\s]+$/.test(keyValue)) {
                                    event.preventDefault();
                                  }
                                }}
                                invalid={
                                  touched.companyname && errors.companyname
                                    ? true
                                    : false
                                }
                              />
                              {touched.companyname && errors.companyname && (
                                <small className="invalid-email error password-text-33">
                                  {errors.companyname}
                                </small>
                              )}
                            </div>
                          </div>
                          <div class="mb-3 row align-items-start justify-content-center">
                            <label
                              for="inputPassword"
                              class="col-sm-3 col-form-label custom-contact-color"
                            >
                              Address
                            </label>
                            <div class="col-sm-4">
                              <span></span>
                              <textarea
                                rows="3" // Set an initial number of rows (1 row in this case)
                                className="form-control me-auto py-2 custom-type-here custom-value-color mb-3"
                                id="inputPassword"
                                name="address"
                                placeholder="Type Here"
                                maxLength={250}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                style={{ height: "auto", minHeight: "50px" }} // Set initial and minimum height
                                invalid={
                                  touched.address && errors.address ? true : false
                                }
                              />
                              {touched.address && errors.address && (
                                <small className="invalid-email error password-text-33">
                                  {errors.address}
                                </small>
                              )}
                            </div>
                            <div class="col-sm-4">
                              <PhoneInput
                                type="number"
                                name="mobilenumber"
                                class="form-control me-auto custom-value-color"
                                id="inputPassword"
                                placeholder="Mobile number"
                                onChange={handlePhoneChange}
                                onBlur={handleBlur}
                                value={values.mobilenumber}
                                country={countryCode}
                                invalid={
                                  touched.mobilenumber && errors.mobilenumber
                                    ? true
                                    : false
                                }
                              />
                              {touched.mobilenumber && errors.mobilenumber && (
                                <small className="invalid-email error password-text-33">
                                  {errors.mobilenumber}
                                </small>
                              )}
                              <input
                                type="email"
                                name="email"
                                class="form-control me-auto mt-3 custom-value-color"
                                id="inputPassword"
                                placeholder="E-mail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                readOnly
                                onKeyPress={(event) => {
                                  const keyCode = event.which || event.keyCode;
                                  const keyValue = String.fromCharCode(keyCode);

                                  // Allow only numeric values (0-9) and the backspace key (8)
                                  if (!/^[a-zA-Z0-9._@]+$/.test(keyValue)) {
                                    event.preventDefault();
                                  }
                                }}
                                invalid={
                                  touched.email && errors.email ? true : false
                                }
                              />
                              {touched.email && errors.email && (
                                <small className="invalid-email error password-text-33">
                                  {errors.email}
                                </small>
                              )}
                            </div>
                          </div>
                          <div class="mb-0 row justify-content-center">
                            <label
                              for="inputPassword"
                              class="col-sm-3 col-form-label custom-contact-color"
                            >
                              Country
                            </label>
                            <div class="col-sm-4">
                              <span></span>
                              <ReactFlagsSelect
                                className="custom-flag-select mb-4"
                                selected={selectedCountryCode}
                                options={countries}
                                onSelect={(code) => setSelectedCountryCode(code)}
                                id="country"
                                name="country"
                                value={selectedCountryCode}
                              // value={selectedCountryName}
                              // value = {city}
                              />
                              <input
                                id="city"
                                type="hidden"
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // value={countryData.country_name}
                                // value={selectedCountryName}
                                invalid={
                                  touched.countryname && errors.countryname
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <div class="col-sm-4">
                              <input
                                type="text"
                                class="form-control me-auto py-2 custom-value-color"
                                id="inputPassword"
                                name="pincode"
                                placeholder="Postal/Zipcode/Pincode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.pincode}
                                onKeyPress={(event) => {
                                  const keyCode = event.which || event.keyCode;
                                  const keyValue = String.fromCharCode(keyCode);

                                  // Allow only numeric values (0-9) and the backspace key (8)
                                  if (!/^[0-9\b]+$/.test(keyValue)) {
                                    event.preventDefault();
                                  }
                                }}
                                invalid={
                                  touched.pincode && errors.pincode ? true : false
                                }
                              />
                              {touched.pincode && errors.pincode && (
                                <small className="invalid-email error password-text-33">
                                  {errors.pincode}
                                </small>
                              )}
                            </div>
                          </div>

                          <div class="mb-2 row new-ctn-inp-select justify-content-center align-items-start">
                            <label
                              for="inputPassword"
                              class="col-sm-3 col-form-label custom-contact-color"
                            >
                              Select a network
                            </label>
                            <div className="col-sm-4 ">
                              <Select
                                className="input-select coin-select-1"
                                value={selectedValue}
                                onChange={(selected) => HandleSelectChange(selected)}
                                placeholder="Please Select Coin"
                                options={options.map(option => ({
                                  value: option.value,
                                  label: (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <div>{option.label}</div>
                                      {option.balance && <div style={{ marginLeft: "auto" }}> Balance: {option.balance}</div>}
                                    </div>
                                  )
                                }))}
                                styles={customStyles}
                                isSearchable={false}  // Disable manual input
                              />
                            </div>

                            <div className="col-sm-4 mt-3 mt-md-0 mt-lg-0" >
                              <div className="">
                                <input
                                  type="text"
                                  class="form-control me-auto py-2 custom-value-color"
                                  id="inputPassword"
                                  name="quantity"
                                  placeholder="Enter Ampoule Quantity"
                                  onChange={userAvailableGram}
                                  onBlur={handleBlur}
                                  value={values.quantity}
                                  onKeyPress={(event) => {
                                    const keyCode = event.which || event.keyCode;
                                    const keyValue = String.fromCharCode(keyCode);

                                    // Allow only numeric values (0-9) and the backspace key (8)
                                    if (!/^[0-9\b]+$/.test(keyValue) || (values.quantity === '' && keyValue === '0')) {
                                      event.preventDefault();
                                    }
                                  }}
                                  invalid={(touched.quantity && errors.quantity) || InsufficientBulkBalance || selectedValue === undefined}

                                />
                              </div>

                              <div>
                                {InsufficientBulkBalance && (
                                  <div className="text-danger">Insufficient balance</div>
                                )}
                                {touched.quantity && errors.quantity && (
                                  <small className="invalid-email error password-text-33">
                                    {errors.quantity}
                                  </small>
                                )}
                              </div>

                            </div>

                          </div>
                          <div className="mb-3 row">
                            <div className="col-sm-3">
                            </div>
                            <div className="col-sm-6 custom-token-box mt-3">
                              <p>
                                Estimated USDT Balance:{" "}
                                <span className="custom-balance ms-2">
                                  {usdtprice}
                                </span>
                              </p>
                              <p>
                                <span className="custom-balance  text-dark ">
                                  Number of Ampoule You can Purchase:  <span style={{ color: "#bc9018" }}>{ExactAmpoulecost} Ampoule </span>
                                </span>{" "}
                              </p>
                              <p>
                                1 Ampoule Rate:
                                <span className="custom-balance ms-2">
                                  {ampoulePrice} USDT
                                </span>{" "}
                              </p>
                            </div>

                          </div>
                          <div class="mb-3 row d-flex align-items-center">
                            <label
                              for="inputPassword"
                              class="col-sm-4 col-form-label custom-contact-color display-hide"
                            >
                              Book Here For Live Visit

                            </label>
                            <div class="ms-3 mt-1 form-check col-sm-4">
                              <input type="checkbox" class="form-check-input" id="exampleCheck1" />

                            </div>

                          </div>
                          <div id="dvPassport" style={{ display: "none" }}>
                            {window.location.pathname === "/tobulkorder" || window.location.pathname == '/tobulkorder/' ? (
                              <div className="row align-items-end">
                                <div className="col-lg-10">
                                  <div className="custom-calender-form">

                                    <iframe
                                      className="custom-frame"
                                      src="https://calendly.com/info-5pa/30min?month=2023-11"
                                      title="Iframe Example"
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="row align-items-end">
                            <div className="col-lg-12 mt-4">
                              <div className="custom-calender-btn text-center">
                                {token ? (
                                  balance == 0.0 && validationStatus == true ? (
                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop"
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                    <>
                                      {isLoading ? (
                                        <a className="spinner-btn-1"><Spinner animation="border" /></a>
                                      ) : (
                                        <button className="spinner-btn-1" type="submit">submit</button>
                                      )}
                                    </>
                                  )
                                ) : (
                                  <Link to="/login">
                                    <button>Submit</button>
                                  </Link>
                                )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      id="staticBackdrop"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">
                              Deposit
                            </h1>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">Your Balance is 0.0 , You Want to Deposit First</div>
                          <div class="modal-footer ">
                            <Link to='/deposite'>
                              <button
                                type="button"
                                class="treasure-btn-1"
                                data-bs-dismiss="modal"
                              >
                                OK
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <form
                    className="new-contact-form"
                    name="footercontact"
                    id="footercontact"
                    autocomplete="off"
                    novalidate="novalidate"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className="row align-items-center justify-content-center">
                      <div className="cols-lg-12 custom-bulk-box">
                        <div className="custom-contact-form align-items-center justify-content-center">

                          <div class="mb-3 row align-items-start">
                            <label
                              for="inputPassword"
                              class="col-sm-3 col-form-label custom-contact-color"
                            >
                              Select a network
                            </label>
                            <div className="col-sm-4">
                              <Select
                                value={selectedValue}
                                onChange={(selected) =>
                                  HandleSelectChange(selected)
                                }
                                placeholder="Please choose 1"
                                options={options}
                                styles={customStyles}
                                className="input-select"
                                isSearchable={false}
                              />
                            </div>
                            <div className="col-sm-4 mt-3 mt-md-0 mt-lg-0">
                              <input
                                type="text"
                                class="form-control me-auto custom-value-color"
                                id="inputPassword"
                                name="quantity"
                                placeholder="Enter Gram Required"
                                onChange={userAvailableGram}
                                onBlur={handleBlur}
                                value={values.quantity}
                                onKeyPress={(event) => {
                                  const keyCode = event.which || event.keyCode;
                                  const keyValue = String.fromCharCode(keyCode);

                                  // Allow only numeric values (0-9) and the backspace key (8)
                                  if (!/^[0-9\b]+$/.test(keyValue) || (values.quantity === '' && keyValue === '0')) {
                                    event.preventDefault();
                                  }
                                }}
                                invalid={(touched.quantity && errors.quantity) || InsufficientBuyBalance}
                              />
                              {InsufficientBuyBalance && (
                                <div className="text-danger">Insufficient balance</div>
                              )}
                              {touched.quantity && errors.quantity && (
                                <small className="invalid-email error password-text-33">
                                  {errors.quantity}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <div className="col-sm-3">

                            </div>
                            <div className="col-sm-7">
                              <p>
                                Estimated USDT Balance:{" "}
                                <span className="custom-balance ms-2">
                                  {usdtprice}
                                </span>
                              </p>
                              <p>
                                <span className="custom-balance text-dark">
                                  Number of Grams You can Purchase: <span style={{ color: "#bc9018" }}> {Exactcost} Grams</span>
                                </span>{" "}
                              </p>
                              <p>
                                1 Gram Rate:
                                <span className="custom-balance ms-2">
                                  {buyPrice} USDT
                                </span>{" "}
                              </p>
                            </div>

                          </div>

                          <div className="row align-items-end">
                            <div className="col-lg-12 mt-4">
                              <div className="custom-calender-btn text-center">

                                {token ? (
                                  balance == '0.0' ? (
                                    <button
                                      data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                    <>
                                      {isLoading ? (
                                        <a className="spinner-btn-1"><Spinner animation="border" /></a>
                                      ) : (
                                        <button className="spinner-btn-1" type="submit">submit</button>
                                      )}
                                    </>

                                  )
                                ) : (
                                  <Link to="/login">
                                    <button>Submit</button>
                                  </Link>
                                )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Tobulkorder;
