import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { FaXTwitter } from "react-icons/fa6";
import render12 from './assets/image/RENDER-1-copy.webp';

function Thankyoupage() {
  let name = "";
  const Bulk = localStorage.getItem("order");
  if (Bulk == "bulkOrder") {
    name = "bulk";
  } else {
    name = "buy";
  }

  return (
    <div className="App">
      <div className="container-fluid ">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-11 ">
            <div className="pt-4">
              <a class="navbar-brand" href="/"><img src={render12}></img></a>
            </div>
          </div>
        </div>

        <div className="custom-thankyou  ">
          <div className="row  align-items-center">
            <div className="col-lg-8 mx-auto">
              <div className="custom-inside-thankyou">
                <h2 className="mt-4 ">Thank you for choosing us!</h2>
                <p>
                  Your inquiry for {name} orders has been received loud and
                  clear. Our team is ready to turn your vision into reality.
                  Excited to make a genuine connection with you.
                </p>
                <Link to="/dashboard/my-assets">
                  <button className="custom-thankyoubtn mb-4">My Asset</button>
                </Link>
                <div className="custom-icon-form mt-3 mb-4">
                  <a href="https://twitter.com/" target="_blank" className="text-dark">
                    <span>
                      <FaXTwitter className="me-1 thankyou-icon" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thankyoupage;
