import React from 'react'
import { Link } from 'react-router-dom';

function Error() {
    return (
        <div className='App'>
            <div className='container-fluid'>
                <div className='custom-error'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='custom-inside-error'>
                                <section class="page_404">
                                    <div class="container">
                                        <div class="row justify-content-center align-items-center">
                                            <div class="col-sm-12 ">
                                                <div class="col-sm-12 col-sm-offset-1  text-center">
                                                    <div class="four_zero_four_bg">
                                                        <h1 class="text-center " style={{ color: "#bc9018" }}>404</h1>


                                                    </div>

                                                    <div class="contant_box_404">
                                                        <h3 class="h2">
                                                            Look like you're lost
                                                        </h3>

                                                        <p>the page you are looking for not avaible!</p>

                                                        <a href="/" class="">
                                                            <button class="treasure-btn-1 active me-3">Go to Home</button>
                                                        </a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error