import React from 'react'
import "./../App.css";
import '../assets/css/Style2.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { FaEyeSlash } from "react-icons/fa";

function Registerone() {
  return (
    <div className='App'>
      <div className='container-fluid'>
        <div className='custom-register-one'>
          <div className=''>
            <div className='row min-vh-100 justify-content-center d-flex align-items-center'>
              <div className='col-lg-12'>
                <div className='row justify-content-center'>
                  <div className='col-lg-5'>
                    <div className='custom-register-one-inside'>
                      <form class="row g-3">
                        <div class="col-md-12">
                          <label for="inputEmail4" class="form-label text-white">Password</label>
                          <input type="email" class="form-control mb-1" id="inputEmail4" />
                        </div>
                        <div class="col-md-12">
                          <label for="inputPassword4" class="form-label text-white">Change Password</label>
                          {/* <input type="password" class="form-control  mb-1" id="inputPassword4" /> */}
                          <div class="input-group">
                            
                            <input type="text" class="form-control mb-1" aria-label="Username" aria-describedby="basic-addon1"/>
                            <span class="input-group-text eye-icon" id="basic-addon1"><FaEyeSlash /></span>
                          </div>
                        </div>
                        <div className='text-center'>
                          <button type="submit" class="register-btn-1">Sign in</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Registerone