import React from "react";
import { useEffect, useState } from "react";
import '../assets/css/Style2.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import $ from "jquery";
import usermask from '../assets/image/usermask.png';
import { ToastContainer, toast } from "react-toastify";
import config from '../config/config'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { Formik, useFormik, } from "formik";
import * as Yup from "yup";
import { FaEye } from "react-icons/fa";
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { Icon } from 'react-icons-kit';
import { Spinner } from "react-bootstrap";
import { makeApiRequest } from "../config/axiosService";
import profile3 from '../assets/image/profile3.png';
import render12 from '../assets/image/RENDER-1-copy.webp';

function Login() {


    const [enable, setEnable] = useState(false);
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [loader, setLoader] = useState(false);


    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }



    const navigate = useNavigate()


    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        errors,
        values,
        resetForm,
        setValues
    } = useFormik({
        initialValues: {
            email: "",
            password: ""

        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Please enter valid email!").required("E-mail is required!").matches(regex, 'Invalid Email!'),
            password: Yup.string().required(" Password is required!"),
        }),
        onSubmit: async (values) => {
            try {
                const payload = {
                    email: values.email,
                    password: values.password
                };

                const searchQuery = 'login'
                let params = {
                    url: `userLogin?query=${searchQuery}`,
                    method: "POST",
                    data: payload
                }

                const res = await makeApiRequest(params);
                
                if (res.data && res.data.email) {
                    const email = res.data.email
                    localStorage.setItem('email', email)
                }
                if (res.data && !res.data.secret) {
                    localStorage.setItem('token', res.token)
                }

                const passwordValue = values.password;
                if (res.status === false) {
                    setLoader(false)
                    // console.log(res.status, '====');
                    toast.error(res.message)
                }
                else {
                    // console.log('else');
                    setLoader(true)
                    const email = localStorage.getItem('email')
                    let params = {
                        url: `enable?UserEmail=${email}`,
                        method: "GET"
                    }
                    const response = await makeApiRequest(params);
                    if (response.user.secret) {
                        // console.log('userwithsecret');
                        setEnable(true)
                        setTimeout(() => {
                            if (response.status == true) {
                                // console.log('if');
                                setLoader(false)
                                toast.success('Verify Your 2FA');
                            }
                        }, 2000);
                        setTimeout(() => {
                            navigate('/authenticator', { replace: true, state: { password: passwordValue } });
                        }, 3000);
                    }
                    else {
                        setEnable(false)
                        setTimeout(() => {
                            if (res.status == true) {
                                // console.log('if');
                                setLoader(false)
                                toast.success('Login SuccessFully');
                            }
                        }, 2000);
                        setTimeout(() => {
                            navigate("/buy");
                        }, 4000);
                    }
                }

            } catch (error) {
                setLoader(false)
                // console.log("error", error.message);
            }
        },
    });




    useEffect(() => {
        const gettoken = localStorage.getItem("token")
        if (gettoken) {
            { gettoken ? navigate('/') : navigate("/login") }
        }

    }, [])

    return (
        <div>
            <ToastContainer />
            <section className="login-bg-styling-css">
                <div className="row justify-content-center align-items-center m-0">
                    <div className="col-lg-11 ">
                        <div className="pt-4">
                            <a class="navbar-brand" href="/"><img src={render12}></img></a>
                        </div>
                    </div>
                </div>
                {/* <a class="navbar-brand" href="/"><img src={render12} className="mx-4 my-2"></img></a> */}
                <div className="container-fluid col-lg-9">
                    <div className="row min-vh-100 text-center align-items-center justify-content-between g-4">

                        <div className="col-lg-5 text-center py-4 register-card-bg-styling login-inputs-css">
                            <img src={profile3} className="usermask-image-css" />
                            <form
                                className="new-contact-form"
                                name="footercontact"
                                id="footercontact"
                                autocomplete="off"
                                novalidate="novalidate"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                    return false;
                                }}
                            >
                                <p className="fs-20 fw-500 text-white">Login</p>
                                <div className="mb-3">
                                    <div class="input-group">
                                        <input type="text"
                                            name='email'
                                            class="form-control mb-1 with-inputs"
                                            id="exampleInputPassword1"
                                            placeholder='Email'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            onKeyPress={(event) => {
                                                const keyCode = event.which || event.keyCode;
                                                const keyValue = String.fromCharCode(keyCode);

                                                // Allow only numeric values (0-9) and the backspace key (8)
                                                if (!/^[a-zA-Z0-9._@]+$/.test(keyValue)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            invalid={touched.email && errors.email ? true : false}
                                        />

                                    </div>
                                    {touched.email && errors.email && (
                                        <small className="invalid-login error password-text-33">
                                            {errors.email}
                                        </small>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <div class="input-group">
                                        <input
                                            type={type}
                                            // type="password"
                                            class="form-control with-input mb-0"
                                            id="exampleInputPassword1"
                                            name="password"
                                            placeholder="Password"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            invalid={touched.password && errors.password ? true : false} />
                                        {/* <span class="input-group-text  eye-icon" id="basic-addon1"><FaEye /></span> */}
                                        <span class="input-group-text eye-icon" onClick={handleToggle}>
                                            <Icon class="absolute mr-10" icon={icon} size={25} />
                                        </span>
                                    </div>
                                    {touched.password && errors.password && (
                                        <small className="invalid-login error password-text-33">
                                            {errors.password}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <a className="fs-20 fw-500 text-white mb-4 mt-2" href="/forgot-password">Forgot Password ?</a>
                                </div>

                                {loader == true ? (
                                    <a className="spinner-btn-1"><Spinner animation="border" /></a>
                                ) : (
                                    <div className="d-flex flex-column">

                                        <span> <button type="submit" className="spinner-btn-1 p-0" > Login </button></span>
                                    </div>


                                )}
                            </form>
                        </div>
                        <div className="col-lg-6 mt-lg-5 pt-lg-5 mb-5">
                            <p className="fcaps heading-1-css  mb-2 d-lg-block d-none">Welcome</p>
                            <p className="fs-20  text-center d-lg-block d-none">Register continue your journey with the largest
                                community of innovators</p>
                            <p className="mt-lg-5 fs-20 fw-500">Don't have an account?</p>
                            <a href="/register" className="text-dark"><button className="btn main-btn-css-1 ">Register</button></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Login;
