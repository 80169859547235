
import DatePicker from "react-datepicker";
// import React, { useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
// import React, { useEffect  } from "react";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios'
import config from '../config/config'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Formik, useFormik, } from "formik";
import * as Yup from "yup";
import ReactFlagsSelect from "react-flags-select";
import "./../App.css"
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';
import { makeApiRequest } from "../config/axiosService";
import { jwtDecode } from "jwt-decode";


function Editprofile({ productID }) {
    const [startDate, setStartDate] = useState(new Date());
    const [countryCode, setCountryCodes] = useState()
    const [countryData, setCountryDatas] = useState({})
    const [userSelectedCountry, setUserSelectedCountry] = useState('')
    const [userId, setUserId] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [datas, setData] = useState({})
    const [userExist, setUserExist] = useState(false);
    const [profile, setProfile] = useState({})
    const utcDate = new Date();
    const currTime = utcDate.toUTCString(); 
    const [dob, setDob] = useState("");

    const [countries, setCountries] = useState([]);
    // const [value, setValue] = useState({ city: "", country_code: "" })
    const [day, setDay] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    // const [country, setCountry] = useState("")
    const [analysisCountryData, setAnalysisCountryData] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [emailVerifyStatus, setEmailVerifyStatus] = useState();
    const [kycVerifyStatus, setKYCVerifyStatus] = useState();
    const UserEmail = localStorage.getItem('email');

    // console.log("analysisCountryData---", analysisCountryData);


    const getData = async () => {
        try {
            // let payload = {
            //     productID: productID
            // }
            let params = {
                url: `get-user-details`,
                method: "GET",
                // data: payload
            }
            // console.log(params, 'params');
            const response = await makeApiRequest(params);
            // console.log(response.data, 'data');
            setValues(response.data);
            // console.log("getuser", response);
            var countryData = response.data.countrycode;
            // console.log("countryData",countryData);
            setSelectedCountryCode(countryData);

            setUserSelectedCountry(response.data.countryname)

        } catch (e) {
            console.log(e);
        }
    }

    const getVerifyStatus = async () => {
        let params = {
            url: `verifyEmailStatus`,
            method: "GET",
        }
        const response = await makeApiRequest(params);
        // console.log("response----+>",response);
        if (response.status == true) {
            const verifyEmailStatus = response.data.emailVerifyStatus;
            const verifyKYCStatus = response.data.kycVerifyStatus;
                setEmailVerifyStatus(verifyEmailStatus);
                setKYCVerifyStatus(verifyKYCStatus);
        }else{
            setEmailVerifyStatus(0);
            setKYCVerifyStatus(0);
        }
    }


    useEffect(() => {
        getVerifyStatus();
    }, [productID])


    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        // console.log('analysisCountryData---', analysisCountryData);
        if (analysisCountryData !== "") {
            // if (productID) {
            //     getData();
            // }

        } else {
            fetchCountryData();

        }
        // console.log(userSelectedCountry, "data----");

    }, []);

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const {
        handleChange,
        handleBlur,
        touched,
        errors,
        values,
        setValues,
        isValid,
    } = useFormik({
        initialValues: {
            contactname: "",
            contactLastname: "",
            companyname: "",
            address: "",
            countryname: "",
            pincode: "",
            mobilenumber: "",
            email: "",
            countrycode: "",
            // countryCode:""
            // dob: "",
        },
        // validationSchema: Yup.object().shape({
        //     contactname: Yup.string().required("Firstname is required!"),
        //     contactLastname: Yup.string().required("Lastname is required!"),
        //     companyname: Yup.string().required("Companyname is required!"),
        //     address: Yup.string().required("Address is required!"),
        //     pincode: Yup.string()
        //         .required("Pincode is required!"),
        //     mobilenumber: Yup.string().required("Mobilenumber is required!"),
        //     // dob: Yup.string().required("DOB is required!"),
        // }),
    });


    const [selectedCountryCode, setSelectedCountryCode] = useState(analysisCountryData);

    const fetchCountryData = async () => {
        try {
            const response = await fetch(`https://ipapi.co/json`);
            const data = await response.json();
            // console.log(userSelectedCountry, "data----", data);
            // if (data.country_name == undefined) {
            //     setCountryDatas("IN")
            // } else {
            //     setCountryDatas(data.country_name)
            // }
            const countrycode = data.country_code;
            // console.log('countrycode---', countrycode);
            setCountryDatas(data.country_name)
            setCountryCodes(data.country_code?.toLowerCase())
            setSelectedCountryCode(data.country_code)
            setCountryCodes(values.country_code)
        } catch (error) {
            console.error("Error fetching country data: ", error);
            setCountryDatas("us");
        }

    };

    let path = window.location.pathname;
    let pathUrl = path === "/dashboard/profile" ? "updateUserDatas" : "getRedeemBuy";

    const handleSubmit = async (e) => {
        e.preventDefault();
        handleChange(e);
        // console.log('e-----', e);
        try {
            if(emailVerifyStatus !== 1 && kycVerifyStatus !== 2){
                toast.error('Email or KYC not verified. Please verify your email and KYC first.');
                setTimeout(() => {
                    window.location.href = '/dashboard/profile';
                }, 2000);

            }else{
                const minPin = 4; // Replace with your minimum pin code value
                const maxPin = 10; // Replace with your maximum pin code value
                const isValidPin = /^\d{4}$/.test(values.pincode);
                // const sanitizedPincode = values.pincode.replace(/[^a-zA-Z]/g, '');
                const sanitizedName = values.contactname.replace(/[^a-zA-Z]/g, '');
                // console.log('values.pincode.length <= minPin----', values.pincode.length == undefined);
                if (!values.contactname) {
                    return toast.error('First Name is required');
                }
                else if (!values.contactLastname) {
                    return toast.error('Last Name is required');
                }
                // else if (!values.companyname) {
                //     return toast.error('Company Name is required');
                // }
                else if (values.address === "") {
                    return toast.error('Please fill in the Address');
                }
                else if (!values.mobilenumber) {
                    return toast.error('Mobile number is required ');
                }
                else if (values.mobilenumber.length < 10) {
                    return toast.error('Mobile number must be at least 10 digits');
                }
                else if (countryData == "") {
                    return toast.error('Please select Country');
                }
                else if (values.pincode == 0) {
                    return toast.error('Please fill pin code.');
                }
                else if (values.pincode.length <= minPin) {
                    return toast.error('Pin code must be above than 4.');
                }
                else if (values.pincode.length > maxPin) {
                    return toast.error('Pin code must be less than or equal to 10.');
                }
                else {
                    const selectedCountry = countries.find(
                        (country) => country.code === selectedCountryCode
                    );

                    if (!selectedCountry) {
                        return toast.error('Please selecte country code.');
                    }
                    // console.log("selectedCountry", selectedCountry);
                    setValues((prevValues) => ({
                        ...prevValues,
                        // country:values,
                        countryname: selectedCountry ? selectedCountry.name : "",
                        countrycode: selectedCountry ? selectedCountry.code : ""
                        // dob: selectedDate,
                    }));

                    let params = {
                        url: `${pathUrl}?productId=${productID}`,
                        method: 'POST',
                        data: {
                            ...values,
                            countryname: selectedCountry.name,
                            countrycode: selectedCountry.code,
                            startDate:startDate,
                            currTime:currTime,

                            // countryCode:selectedCountryCode
                            // dob: selectedDate,
                        },
                    };

                    const response = await makeApiRequest(params);
                    console.log("path--response--",response);

                    if (response.status === true) {
                        setIsLoading(true);
                        if (path === '/dashboard/profile') {
                            toast.success(response.message);
                        } else {
                            toast.success('Redeemed Successfully');
                        }
                        setTimeout(() => {
                            window.location.reload(false);
                        }, 2000);
                        getData();
                    }
                }
            //}
            }

        } catch (error) {
            console.log(error.message);
        }
    };

    const handlePhoneChange = (value, country, e, formettedValue) => {
        handleChange(e);
        setValues((prevValues) => (
            {
                ...prevValues,
                mobilenumber: formettedValue,
            }
        ));
    }



    useEffect(() => {
        // Fetch the list of countries from the "restcountries" API
        axios.get('https://restcountries.com/v3.1/all')
            .then((response) => {
                // Extract country codes and names from the response data
                const countryData = response.data.map(country => ({
                    code: country.cca2,
                    name: country.name.common
                }));
                // console.log(countryData, "countryDatacountryData");
                setCountries(countryData);

                for (let i = 0; i < countryData.length; i++) {
                    const element = countryData[i].code;
                    // console.log("elementelement--", element);
                    // setTheCountryCode(element)
                }
            })
            .catch((error) => {
                console.error('Error fetching country data:', error);
            });
    }, [])

    const getCountry = (data) => {
        return selectedCountryCode
    }
    return (
        <div className='App custom-tobulk-nav'>
            {/* <ToastContainer  /> */}
            <div className='container-fluid'>
                {/* {window.location.pathname == '/editprofile' ? (<Navbar />) : (<></>)} */}
                <div className=' custom-edit-profile-pop-up'>
                    <div className=''>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='custom-inside-bulk-order'>
                                    <h2 className="fs-4">UPDATE PROFILE</h2>
                                </div>

                                <form
                                    className="new-contact-form"
                                    name="footercontact"
                                    id="footercontact"
                                    autoComplete="off"
                                    noValidate="novalidate"
                                    onSubmit={(e) => handleSubmit(e)}
                                >
                                    <div className='row align-items-center justify-content-center'>
                                        <div className='cols-lg-12 custom-bulk-box'>
                                            <div className='custom-contact-form align-items-center justify-content-center'>
                                                {/* {window.location.pathname == '/dashboard' ? (
                                                    <></>
                                                ) : (<></>)} */}

                                                <div class="mb-3 row align-items-center justify-content-center">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-color">Contact name</label>
                                                    <div class="col-sm-4">
                                                        <span></span>
                                                        <input type="text"
                                                            name='contactname'
                                                            class="form-control me-auto py-2 custom-value-color"
                                                            id="inputPassword"
                                                            placeholder='First Name'
                                                            maxLength={15}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.contactname}
                                                            onKeyPress={(event) => {
                                                                const keyCode = event.which || event.keyCode;
                                                                const keyValue = String.fromCharCode(keyCode);

                                                                // Allow only numeric values (0-9) and the backspace key (8)
                                                                if (!/^[a-zA-Z]+$/.test(keyValue)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            invalid={touched.contactname && errors.contactname ? true : false}
                                                        />
                                                        {touched.contactname && errors.contactname && (
                                                            <small className="invalid-email error password-text-33">
                                                                {errors.contactname}
                                                            </small>
                                                        )}
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <input type="text"
                                                            name='contactLastname'
                                                            class="form-control me-auto py-2 custom-value-color"
                                                            id="inputPassword"
                                                            placeholder='Last Name'
                                                            maxLength={15}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.contactLastname}
                                                            onKeyPress={(event) => {
                                                                const keyCode = event.which || event.keyCode;
                                                                const keyValue = String.fromCharCode(keyCode);

                                                                // Allow only numeric values (0-9) and the backspace key (8)
                                                                if (!/^[a-zA-Z]+$/.test(keyValue)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            invalid={touched.contactLastname && errors.contactLastname ? true : false}
                                                        />
                                                        {touched.contactLastname && errors.contactLastname && (
                                                            <small className="invalid-email error password-text-33">
                                                                {errors.contactLastname}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="mb-3 row align-items-center justify-content-center">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-color">Company name</label>
                                                    <div class="col-sm-8">
                                                        <input
                                                            type="text"
                                                            name='companyname'
                                                            class="form-control me-auto"
                                                            id="inputPassword"
                                                            placeholder='Company Name'
                                                            maxLength={100}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.companyname}
                                                            onKeyPress={(event) => {
                                                                const keyCode = event.which || event.keyCode;
                                                                const keyValue = String.fromCharCode(keyCode);
                              
                                                                // Allow only numeric values (0-9) and the backspace key (8)
                              
                                                                if (!/^[a-zA-Z\s]+$/.test(keyValue)) {
                                                                  event.preventDefault();
                                                                }
                                                              }}
                                                            invalid={touched.companyname && errors.companyname ? true : false}
                                                        />
                                                        {touched.companyname && errors.companyname && (
                                                            <small className="invalid-email error password-text-33">
                                                                {errors.companyname}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="mb-3 row align-items-start justify-content-center">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-color">Address</label>
                                                    <div class="col-sm-4">
                                                        <span></span>
                                                        <textarea
                                                            rows="1" // Set an initial number of rows (1 row in this case)
                                                            className="form-control me-auto py-2 custom-type-here custom-value-color mb-3"
                                                            id="inputPassword"
                                                            name="address"
                                                            placeholder="Type Here"
                                                            maxLength={250}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.address}
                                                            style={{ height: 'auto', minHeight: '85px' }} // Set initial and minimum height
                                                            invalid={touched.address && errors.address ? true : false}
                                                        />
                                                        {touched.address && errors.address && (
                                                            <small className="invalid-email error password-text-33">
                                                                {errors.address}
                                                            </small>
                                                        )}

                                                    </div>
                                                    <div class="col-sm-4">
                                                        {/* <label for="inputPassword" class="col-sm-2 col-form-label">secondname:</label> */}

                                                        <PhoneInput
                                                            type="number"
                                                            name='mobilenumber'
                                                            class="form-control me-auto custom-value-color variable-width"
                                                            id="mobilenumber"
                                                            placeholder='Mobile number'
                                                            onChange={handlePhoneChange}
                                                            onBlur={handleBlur}
                                                            value={values.mobilenumber}
                                                            country={countryCode}
                                                            invalid={touched.mobilenumber && errors.mobilenumber ? true : false}
                                                        />
                                                        {touched.mobilenumber && errors.mobilenumber && (
                                                            <small className="invalid-email error password-text-33 variable-width">
                                                                {errors.mobilenumber}
                                                            </small>
                                                        )}
                                                        <input
                                                            type="text"
                                                            class="form-control me-auto py-2 mt-2  custom-value-color"
                                                            id="inputPassword"
                                                            name='pincode'
                                                            placeholder='Postal/Zipcode/Pincode'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.pincode}
                                                            onKeyPress={(event) => {
                                                                const keyCode = event.which || event.keyCode;
                                                                const keyValue = String.fromCharCode(keyCode);

                                                                // Allow only numeric values (0-9) and the backspace key (8)
                                                                if (!/^[0-9\b]+$/.test(keyValue)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            invalid={touched.pincode && errors.pincode ? true : false}
                                                        />
                                                        {touched.pincode && errors.pincode && (
                                                            <small className="invalid-email error password-text-33">
                                                                {errors.pincode}
                                                            </small>
                                                        )}
                                                    </div>

                                                </div>

                                                <div class="mb-0 row ">
                                                    <label for="inputPassword" class="col-sm-3 col-form-label custom-contact-color">Country</label>
                                                    <div class="col-sm-4">
                                                        <span></span>

                                                        <ReactFlagsSelect className="custom-flag-select mb-4"
                                                            selected={selectedCountryCode}
                                                            options={countries}
                                                            onSelect={(country_code) => setSelectedCountryCode(country_code)}
                                                            id="country"
                                                            name="country"
                                                            value={selectedCountryCode}
                                                        />

                                                        <input
                                                            id="city"
                                                            type="hidden"
                                                            name='city'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            // value={countryData.country_name}
                                                            // value={values.countryname}
                                                            // value={selectedCountryCode}
                                                            invalid={touched.countryname && errors.countryname ? true : false}
                                                        />
                                                    </div>

                                                </div>
                                                {/* <div class="mb-3 row ">
                                                    <label for="inputPassword" class="col-sm-3 col-form-label custom-contact-color">Postal Code</label>
                                                    <div class="col-sm-4">
                                                        <span></span>
                                                        <input
                                                            type="text"
                                                            class="form-control me-auto py-2 mt-2  custom-value-color"
                                                            id="inputPassword"
                                                            name='pincode'
                                                            placeholder='Postal/Zipcode/Pincode'
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.pincode}
                                                            onKeyPress={(event) => {
                                                                const keyCode = event.which || event.keyCode;
                                                                const keyValue = String.fromCharCode(keyCode);

                                                                // Allow only numeric values (0-9) and the backspace key (8)
                                                                if (!/^[0-9\b]+$/.test(keyValue)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            invalid={touched.pincode && errors.pincode ? true : false}
                                                        />
                                                        {touched.pincode && errors.pincode && (
                                                            <small className="invalid-email error password-text-33">
                                                                {errors.pincode}
                                                            </small>
                                                        )}
                                                    </div>

                                                </div> */}

                                                {/* <div class="mb-3 row ">
                                                    <label for="inputPassword" class="col-sm-3 col-form-label custom-contact-color display-hide">Book Here For Live Visit</label>
                                                </div> */}

                                                <div className='row align-items-end'>
                                                    <div className='col-lg-12 mt-4'>
                                                        <div className='custom-calender-btn text-center'>
                                                            <button>
                                                                {isLoading ? (<Spinner animation="border" />) : ('Submit')}
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Editprofile
