import React, { useState } from 'react'
import '../assets/css/Authenticator.css';
import { BsArrowRightShort } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { RiFileCopyFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import $ from "jquery";
import jQuery from "jquery";
import axios from 'axios';
import Web3 from "web3";
import { useEffect, useRef } from "react";
import Select from 'react-select';
import config from '../config/config';
import { makeApiRequest } from "../config/axiosService";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from 'react-bootstrap';
import Authenticator from '../pages/Authenticator';
import { Link, useLocation } from 'react-router-dom';
import { isAddress } from 'web3-validator';
import { useForm } from "react-hook-form";
import { validate } from 'bitcoin-address-validation';
import dusd_png from "../assets/image/dusd.png"


function Widthdrawcrypto() {

    const [userBalance, setUserBalance] = useState(0)
    const UserEmail = localStorage.getItem('email') || '';
    const [loader, setLoader] = useState(false);
    const [emailVerifyStatus, setEmailVerifyStatus] = useState();
    const [kycVerifyStatus, setKYCVerifyStatus] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const currTime = new Date().toLocaleTimeString();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const verification = params.get('verify');

    const customStyles = {
        menu: base => ({
            ...base,
            background: "#FFF",
            margin: "0px",
            padding: "0px",
        }),
        control: (base, state) => ({
            ...base,
            background: "transparent",
            color: "#000",
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#bc9018" : "transparent",
                color: "black",
            };
        }
    };

    const options = [
        {
            value: 'BTC', label: <a href="#btc"><div style={{ color: "black" }}>
                <img src={"https://s2.coinmarketcap.com/static/img/coins/200x200/1.png"} className="pb-1 me-2" style={{ width: "20px", color: "black" }} />Bitcoin
            </div></a>
        },

        {
            value: "DUSD", label: <a href='#dusd'><div style={{ color: "black" }}>
                <img src={dusd_png} className=' pb-1 me-2' style={{ width: "20px", color: "black" }} />DUSD
            </div></a>
        },
        {
            value: 'BSC-USD', label: <a href="#usdt"><div style={{ color: "black" }}>
                <img src={"https://bscscan.com/token/images/busdt_32.png"} className="pb-1 me-2" style={{ width: "20px" }} /> BSC-USD
            </div></a>
        },

        {
            value: 'ETH', label: <a href="#eth"><div style={{ color: "black" }} >
                <img src={"https://app.1inch.io/assets/images/network-logos/ethereum.svg"} className="pb-1 me-2" style={{ width: "20px", color: "black" }} />ETH
            </div></a>
        },
        {
            value: 'BNB', label: <a href="#bnb"><div style={{ color: "black" }}>
                <img src={"https://assets.coingecko.com/coins/images/12591/thumb/binance-coin-logo.png?1600947313"} className="pb-1 me-2" style={{ width: "20px" }} /> BNB
            </div></a>
        },

    ];



    const [selectedValue, setSelectedValue] = useState("");

    const [value, setValue] = useState("");
    // console.log("selectedValue",selectedValue, value);
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [rpc, setRpc] = useState('');



    const getVerifyStatus = async () => {
        let params = {
            url: `verifyEmailStatus`,
            method: "GET",
        }
        const response = await makeApiRequest(params);
        // console.log("response----+>",response);
        if (response.status == true) {
            const verifyEmailStatus = response.data.emailVerifyStatus;
            const verifyKYCStatus = response.data.kycVerifyStatus;
            if (verifyEmailStatus && verifyKYCStatus) {
                setEmailVerifyStatus(verifyEmailStatus);
                setKYCVerifyStatus(verifyKYCStatus);
            } 
        }else{
            setEmailVerifyStatus(0);
            setKYCVerifyStatus(0);
        }
    }


    const handleCoinChange = (e) => {
        setValue(e.value);
        const value = e.value;
        options.map((data) => {
            if (value === data.value) {
                // console.log("setSelectedValue",data);
                setSelectedValue(data)
            }
        })
        if (value) {
            if (value == "BTC") {
                setRpc(config.btc_rpc);
            } else if (value == "DUSD") {
                setRpc(config.dcx_rpc);
            } else if (value == "BSC-USD") {
                setRpc(config.bnb_rpc);
            } else if (value == "ETH") {
                setRpc(config.eth_rpc);
            } else if (value == "BNB") {
                setRpc(config.bnb_rpc);
            } else {
                console.log("Wrong value");
            }
        }
    };
    const userWalletBal = async () => {
        try {
            let params = {
                url: `get-user-balance?nerwork=${value}`,
                method: "POST",
            }
            const response = await makeApiRequest(params);
            // console.log("userWalletBal",response);
            if (response.status == true) {
                setUserBalance(response.data)
            } else {
                setUserBalance(0);
            }
        } catch (error) {
            setUserBalance(0);
            console.log('Error fetching address:', error);
        }
    };
    useEffect(() => {
        userWalletBal();
    }, [selectedValue]);

    useEffect(() => {
        getVerifyStatus();
    }, [])

    const handleWithdrawcryptoClick = async (toAddress, network, withdrawAmt) => {
        // console.log("toAddress", toAddress);
        try {
            setLoader(true)
            if (emailVerifyStatus !== 1 && kycVerifyStatus !== 2) {
                toast.error('Email or KYC not verified. Please verify your email and KYC first.');
                setTimeout(() => {
                    window.location.href = '/dashboard/profile';
                }, 2000);
            }

            const email = localStorage.getItem('email')
            let param = {
                url: `enable?UserEmail=${email}`,
                method: "GET"
            }
            const res = await makeApiRequest(param);
            if(res.status == true){
                if (res.user.secret && verification == null) {
                    localStorage.setItem('verifyAuthenticate', 1);
                    setTimeout(() => {
                        const state = 'withdraw';
                        window.location.href = `/withdrawauthenticator/${state}?toAddress=${toAddress}&network=${network}&amount=${withdrawAmt}`;
                    }, 3000);
                }
                else {
                    toast.success('Enable Your 2FA First')
                    setTimeout(() => {
                        window.location.href = '/dashboard/security';
                    }, 2000);
                }
            }
        } catch (error) {
            setLoader(false);
            toast.error(error.message);
        }
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        if (data) {
            handleWithdrawcryptoClick(data.toAddress, value, data.withdrawAmount)
        }
    }
    const fixedBalance = (amt) => {
        let balanceAmt = Number(amt)
        // console.log("balanceAmt", balanceAmt);
        return balanceAmt.toFixed(8)
    }
    const validateWithdrawAmount = async (inputAmt) => {
        let params = {
            url: "validWithdrawAmt",
            method: "POST"
        }
        let response = await makeApiRequest(params);
            if (Number(inputAmt) === 0) {
                return "Please enter a value other than 0";
            }
            // Error Based Admin allowable
            if(value == "BTC" && Number(inputAmt) <= response.data.BTC_Withdraw_Amt){
                return `BTC withdraw minimum should be greater than ${response.data.BTC_Withdraw_Amt}`;
            }
            if (value == "ETH" && Number(inputAmt) <= response.data.ETH_Withdraw_Amt) {
                return `ETH withdraw minimum should be greater than ${response.data.ETH_Withdraw_Amt}`;
            }
            if (value == "BNB" && Number(inputAmt) <= response.data.BNB_Withdraw_Amt) {
                return `BNB withdraw minimum should be greater than ${response.data.BNB_Withdraw_Amt}`;
            }
            if (value == "BSC-USD" && Number(inputAmt) <= response.data.BUSDT_Withdraw_Amt) {
                return `BSC-USD withdraw minimum should be greater than ${response.data.BUSDT_Withdraw_Amt}`;
            }
            if (value == "DUSD" && Number(inputAmt) <= response.data.DUSD_Withdraw_Amt) {
                return `DUSD withdraw minimum should be greater than ${response.data.DUSD_Withdraw_Amt}`;
            }
            // Error Based user balance
            if (value == "BTC" && Number(inputAmt) > response.data.BTC_userBalance) {
                return `BTC wallet balance is low : ${fixedBalance(response.data.BTC_userBalance)}`;
            }
            if (value == "ETH" && Number(inputAmt) > response.data.ETH_userBalance) {
                return `ETH wallet balance is low : ${fixedBalance(response.data.ETH_userBalance)}`;
            }
            if (value == "BNB" && Number(inputAmt) > response.data.BNB_userBalance) {
                return `BNB wallet balance is low : ${fixedBalance(response.data.BNB_userBalance)}`;
            }
            if (value == "BSC-USD" && Number(inputAmt) > response.data.BUSDT_userBalance) {
                return `BSC-USD wallet balance is low : ${fixedBalance(response.data.BUSDT_userBalance)}`;
            }
            if (value == "DUSD" && Number(inputAmt) > response.data.DUSD_userBalance) {
                return `DUSD wallet balance is low : ${fixedBalance(response.data.DUSD_userBalance)}`;
            }
            return true;
    };

    const validateCryptoAddress = (value) => {
        // console.log(value, typeof value);
        let address = isAddress(value);
        let btcAddress = validate(value)
        // console.log("address", address, "btcAddress", btcAddress);
        const cryptoAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        const btcTestnetAddressRegex = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;

        if (!cryptoAddressRegex.test(address)) {
            console.log("Invalid Crypto address");
        } else if (!btcTestnetAddressRegex.test(btcAddress)) {
            console.log("Invalid BTC address");
        } 

        console.log("Valid address");
        return true;
    };


   
    const validateAddresses = (value) => {
        return validateCryptoAddress(value);
    };


    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-with-draw'>
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-inside-with-draw d-flex justify-content-between'>
                                    <h2><a href='/dashboard/my-crypto-wallet'><IoIosArrowBack className='me-3' /><span className='new-return-sec-ctn-02'></span></a><span className='new-return-sec-ctn-02'> Withdraw crypto</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5 '>
                            <div className='col-lg-12 '>
                                <div className='row align-items-center justify-content-center'>
                                    <div className='col-lg-10'>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className='custom-select-coins '>
                                                <div class="mb-3 row ">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0 coin-1" >Select Coin</label>
                                                    <div class="col-sm-6">
                                                        <div>
                                                            <div class="f-group w-100">
                                                                <label className='coin-1 mb-1'>Coin </label>
                                                                <Select
                                                                    value={selectedValue}  // Use selectedOption instead of value
                                                                    onChange={(selected) => handleCoinChange(selected)}  // Pass the selected option to handleCoinChange
                                                                    placeholder="Select Any Coins Or Tokens"
                                                                    options={options}
                                                                    styles={customStyles}
                                                                    className='input-select'
                                                                    isSearchable={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-3 row ">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0 coin-1">Balance</label>
                                                    <div class="col-sm-6"><span className='mb-2'></span>
                                                        <td>
                                                            {fixedBalance(userBalance)}
                                                        </td>
                                                    </div>
                                                </div>
                                                <div class="mb-3 row ">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0 coin-1">Send to</label>
                                                    <div class="col-sm-6"><span className='mb-2 coin-1'>Address</span>
                                                        <input type="text" name="contactname" class="form-control mt-2 me-auto py-2 custom-value-color" id="inputPassword" placeholder="Wallet Address"
                                                            {...register("toAddress", {
                                                                required: {
                                                                    value: true,
                                                                    message: 'This field is required'
                                                                },
                                                                maxLength: {
                                                                    value: 50,
                                                                    message: "Maximum 50 characters allowed"
                                                                },
                                                                validate: (value) => validateAddresses(value),
                                                            })}
                                                        />
                                                        {errors.toAddress && (
                                                            <div className="invalid-email error password-text-33 variable-width">
                                                                {errors.toAddress.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3 row ">
                                                <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0"></label>
                                                <div class="col-sm-6">
                                                    <span className='mb-2 coin-1'>Amount</span>
                                                    <input type="text" name="contactname" class="form-control mt-2 me-auto py-2 custom-value-color" id="withdrawAmt" placeholder="Enter Amount"
                                                        {...register("withdrawAmount", {
                                                            required: {
                                                                value: true,
                                                                message: 'This field is required'
                                                            },
                                                            pattern: {
                                                                value: /^[0-9]+(\.[0-9]+)?$/,
                                                                message: 'Please enter a valid number(123) or decimal(0.10)',
                                                            },
                                                            maxLength: {
                                                                value: 20,
                                                                message: "Maximum 20 characters allowed"
                                                            },
                                                            validate: validateWithdrawAmount
                                                        })}
                                                    />
                                                    {errors.withdrawAmount && (
                                                        <div className="invalid-email error password-text-33 variable-width">
                                                            {errors.withdrawAmount.message}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div class="mb-3 row ">
                                                <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0"></label>
                                                <div class="col-sm-6">
                                                    <div className='custom-withdraw-balance'>
                                                        <span className='coin-1 '>Notes</span>
                                                        <p> 1. Please ensure that the withdrawal address supports the selected Network </p>
                                                        <p> 2. Else you will loose your assets if the choosen platform does not support recovery</p>
                                                        <p> 3. <span style={{ color: "#bc9018" }}>2%</span> of your withdrawal amount will be deducted for <span style={{ color: "#bc9018" }}>Transaction fee </span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3 row ">
                                                <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0"></label>
                                                <div class="col-sm-6">
                                                    <div className='custom-withdraw-balance-bth'>
                                                        {loader == true ? (
                                                            <button>
                                                                <Spinner animation="border" />
                                                            </button>
                                                        ) : (
                                                            // <button onClick={balanceLimit}>Withdraw</button>
                                                            <button type='submit'>Withdraw</button>

                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Widthdrawcrypto