import React, { useEffect } from 'react'
import '../assets/css/Authenticator.css';
import { Link } from 'react-router-dom';
import $, { data } from "jquery";
import jQuery from "jquery";
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlineClose } from 'react-icons/ai';
import scane from '../assets/image/scane.svg';
import { BsDot } from 'react-icons/bs';
import { RiFileCopyFill } from 'react-icons/ri';
import { ImUsers } from 'react-icons/im';
import { HiOutlineDocumentSearch } from 'react-icons/hi';
import axios from 'axios';
import config from '../config/config'
import { useState } from 'react';
import QRCode from "react-qr-code";
import Select from 'react-select';
import { makeApiRequest } from "../config/axiosService";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { logDOM } from '@testing-library/react';
import io from 'socket.io-client';
import { jwtDecode } from "jwt-decode";
import { PiCopySimpleFill } from 'react-icons/pi';
import { Tooltip } from 'react-tooltip';
import dusd_png from "../assets/image/dusd.png"


function Deposite() {
    const location = useLocation()
    const Walletcoin = location.state ? location.state.coin : null;
    const [startDate, setStartDate] = useState(new Date());
    const currTime = new Date().toLocaleTimeString();
    const [NetworkAddress, setNetworkAddress] = useState(null)
    const [symbol, setSymbol] = useState()
    const [copied, setCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [emailVerifyStatus, setEmailVerifyStatus] = useState();
    const [kycVerifyStatus, setKYCVerifyStatus] = useState();
    const UserEmail = localStorage.getItem('email')
    const [addressLoader, setAddressLoader] = useState(false);

    const getVerifyStatus = async () => {
        let params = {
            url: `verifyEmailStatus`,
            method: "GET",
        }
        const response = await makeApiRequest(params);
        // console.log("response----+>",response);
        if (response.status == true) {
            const verifyEmailStatus = response.data.emailVerifyStatus;
            // console.log(verifyEmailStatus,'/////');
            const verifyKYCStatus = response.data.kycVerifyStatus;
            if (verifyEmailStatus && verifyKYCStatus) {
                setEmailVerifyStatus(verifyEmailStatus);
                setKYCVerifyStatus(verifyKYCStatus);
            } else {
                setEmailVerifyStatus(0);
                setKYCVerifyStatus(0);
            }
        }
    }


    const copyText = () => {
        navigator.clipboard.writeText(NetworkAddress)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch(err => {
                console.error('Unable to copy secret to clipboard:', err);
            });
    };

    const customStyles = {
        menu: base => ({
            ...base,
            background: "#FFF",
            margin: "0px",
            padding: "0px",
        }),
        control: (base, state) => ({
            ...base,
            background: "transparent",
            color: "#000",
            // Overwrittes the different states of border
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                backgroundColor: isFocused ? "#bc9018" : "transparent",
                color: "black",

            };
        }
    };


    const options = [
        {
            value: 'BTC', Symbol: 'btcusdt', label: <a><div style={{ color: "black" }}>
                <img src={"https://s2.coinmarketcap.com/static/img/coins/200x200/1.png"} className=" me-2" style={{ width: "20px", color: "black" }} />Bitcoin
            </div></a>
        },
        // {
        //     value: 'DCX', label: <a><div style={{ color: "black" }}>
        //         <img src={"https://s2.coinmarketcap.com/static/img/coins/200x200/25799.png"} className='  me-2' style={{ width: "20px", color: "black" }} />DCX
        //     </div></a>
        // },
        // {
        //     value: 'MATIC', Symbol: 'maticusdt', label: <a><div style={{ color: "black" }}>
        //         <img src={"https://app.1inch.io/assets/images/network-logos/polygon.svg"} className=" me-2" style={{ width: "20px" }} /> Polygon
        //     </div></a>
        // },
        {
            value: 'ETH', Symbol: 'ethusdt', label: <a><div style={{ color: "black" }} >
                <img src={"https://app.1inch.io/assets/images/network-logos/ethereum.svg"} className=" me-2" style={{ width: "20px", color: "black" }} />ETH
            </div></a>
        },
        {
            value: 'BNB', Symbol: 'bnbusdt', label: <a><div style={{ color: "black" }}>
                <img src={"https://cryptologos.cc/logos/bnb-bnb-logo.png"} className=" me-2" style={{ width: "20px" }} /> BNB
            </div></a>
        },
        {
            value: 'DUSD', Symbol: 'dcxusdt', label: <a><div style={{ color: "black" }}>
                <img src={dusd_png} className='  me-2' style={{ width: "20px", color: "black" }} />DUSD
            </div></a>
        },
        {
            value: 'BSC-USD', Symbol: 'busdt', label: <a><div style={{ color: "black" }}>
                <img src={"https://bscscan.com/token/images/busdt_32.png"} className=" me-2" style={{ width: "20px" }} /> BSC-USD
            </div></a>
        },
    ];

    // const DOption = options.find(option => option.value === "DUSD");
    // const [selectedValue, setSelectedValue] = useState(DOption);
    const [selectedValue, setSelectedValue] = useState();

    const [value, setValue] = useState();
    // const [value, setValue] = useState();

    // console.log("selectedValue---",selectedValue,"value---", value);

    const HandleSelectChange = (e) => {
        setValue(e.value);

        const value = e.value;
        // console.log("HandleSelectChange--data---",value);
        options.map((data) => {
            if (value === data.value) {
                setSelectedValue(data)
                setSymbol(data.Symbol)
            }
        })
    }

    const coinName = () => {
        if (Walletcoin == "ETH") {
            const sepoliaOption = options.find(option => option.value === "ETH");
            setValue(sepoliaOption.value);
            setSelectedValue(sepoliaOption);
            setSymbol(sepoliaOption.Symbol);
        }
        else if (Walletcoin == "BTC") {
            const btcOption = options.find(option => option.value === "BTC");
            // console.log("btcOption", btcOption);
            setValue(btcOption.value);
            setSelectedValue(btcOption);
            setSymbol(btcOption.Symbol);
        }
        else if (Walletcoin == "BNB") {
            const bnbOption = options.find(option => option.value === "BNB");
            setValue(bnbOption.value);
            setSelectedValue(bnbOption);
            setSymbol(bnbOption.Symbol);
        }
        else if (Walletcoin == "BSC-USD") {
            const BUSDOption = options.find(option => option.value === "BSC-USD");
            setValue(BUSDOption.value);
            setSelectedValue(BUSDOption);
            setSymbol(BUSDOption.Symbol);
        }
        else if (Walletcoin == "DUSD") {
            const DUSDoption = options.find(option => option.value === "DUSD");
            setValue(DUSDoption.value);
            setSelectedValue(DUSDoption);
            setSymbol(DUSDoption.Symbol);
        }
        // else if (Walletcoin == "DCX") {
        //     const DOption = options.find(option => option.value === "DCX");
        //     setValue(DOption.value);
        //     setSelectedValue(DOption);
        //     setSymbol(DOption.Symbol);
        // }
        // else if (Walletcoin == "MATIC") {
        //     const maticOption = options.find(option => option.value === "MATIC");
        //     setValue(maticOption.value);
        //     setSelectedValue(maticOption);
        //     setSymbol(maticOption.Symbol);
        // }
    }

    // console.log(symbol, 'symbol');
    // console.log(value, 'value');

    // const [btcNetworkAddr, setBtcNetworkAddr] = useState(null)

    const address = async () => {
        try {
            setAddressLoader(true)
            let params = {
                url: `address?UserEmail=${UserEmail}&selectedValue=${value}`,
                method: "GET",
            }

            const response = await makeApiRequest(params);
            // console.log("response", response);
            if (response.status) {
                // const btcOption = options.find(option => option.value === "BTC");
                const Address = response.data.address;
                // const BtcAddress = response.data.BtcAddress    
                // console.log("BtcAddress",BtcAddress);  
                // const addr = btcOption == "BTC" ? BtcAddress : Address        
                setNetworkAddress(Address);
                // setBtcNetworkAddr(BtcAddress)
                setAddressLoader(false)
            } else {
                setAddressLoader(true)
            }
        } catch (error) {
            setAddressLoader(true)
            console.log('Error fetching address:', error.message);
        }
    };

    const DepositTrans = async () => {

        if (emailVerifyStatus == 0) {
            console.log('emailnotverified');
        }
        else {
            setIsLoading(true)
            const Token = localStorage.getItem("token");
            const decoded = jwtDecode(Token);
            const userId = decoded.userId;
            try {
                const payload = {
                    UserEmail: UserEmail,
                    value: value,
                    userId: userId,
                    time: currTime,
                    date: startDate
                }
                let param = {
                    url: `depositTransactions`,
                    method: "POST",
                    data: payload
                }
                const res = await makeApiRequest(param);
                // console.log("deposit--res", res);
                if (res.status === true) {
                    toast.success('New deposite founded successfully...')
                    setTimeout(() => {
                        window.location.href = "/dashboard/my-crypto-wallet"
                    }, 2000);
                    setIsLoading(false)
                    // setTimeout(() => {
                    //     window.location.href = '/dashboard/my-crypto-wallet';
                    // }, 2000);
                }
                else {
                    toast.error('There is no recent deposite');
                    setIsLoading(false)
                }
            } catch (error) {
                toast.error('Something went wrong');
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        address();
    }, [selectedValue]);

    useEffect(() => {
        getVerifyStatus();
    }, [])

    useEffect(() => {
        coinName()
    }, [Walletcoin])

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-deposite-wallet mt-lg-4'>
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-inside-deposite custom-inside-with-draw'>
                                    {/* <h2><a href='/dashboard/my-crypto-wallet'><IoIosArrowBack className='me-3' /><span>Return</span></a> Deposite crypto</h2> */}
                                    <h2><a href='/dashboard/my-crypto-wallet'><IoIosArrowBack className='me-0 mb-1' /><span className='new-return-sec-ctn-02'></span></a><span className='new-Deposite-sec-ctn-02'> Deposit crypto</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>

                                <div className='custom-wallet-box'>

                                    <div className='custom-wallet-line-border'></div>
                                    {/* <div className='custom-wallet-wrong text-end mb-3'>
                                        <AiOutlineClose />
                                    </div> */}
                                    <div className='custom-wallet-icon'>
                                        {/* <ImUsers /> */}
                                    </div>
                                    <div className='row'>

                                        <div className='col-lg-3'>
                                            <div className='custom-box-line '>
                                                <h3><span>1</span></h3>
                                                <h2>Select Coin</h2>
                                                <p>First select any coin from select box</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='custom-box-line '>
                                                <h3><span>2</span></h3>
                                                <h2>Scan the QRCode and Send Amount</h2>
                                                <p>For deposit AMOUNT you want to scan the QR code and send coins</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='custom-box-line '>
                                                <h3><span>3</span></h3>
                                                <h2>Copy Address and Send Amount</h2>
                                                <p>Another way to send a coin is to copy the below Address and paste it in any wallet and send coin  </p>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='custom-box-line '>
                                                <h3><span>4</span></h3>
                                                <h2>Finishing the Deposit</h2>
                                                <p>on Clicking on submit button, Deposit will done successfully</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='row mt-2 '>
                            <div className='col-lg-12 '>
                                <div className='row align-items-center justify-content-center'>
                                    <div className='col-lg-11'>
                                        <div className='custom-select-coins '>
                                            {emailVerifyStatus == 0 ?
                                                (<h5 className="verify-email">{"Verify your Email First"}
                                                    <a href="/dashboard/profile" className="ps-lg-2" style={{ color: "red", fontSize: "16px" }}>ClickHere</a>
                                                </h5>)
                                                : (
                                                    <></>
                                                )}
                                            <div class="mb-0 row ">
                                                <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0">Select Coin</label>
                                                <div class="col-sm-5 p-0">
                                                    <div>
                                                        <div class="f-group w-100">
                                                            <label>Coin </label>

                                                            <Select
                                                                value={selectedValue}  // Use selectedOption instead of value
                                                                onChange={(selected) => HandleSelectChange(selected)}  // Pass the selected option to handleCoinChange
                                                                placeholder="Please Select Coin"
                                                                options={options}
                                                                styles={customStyles}
                                                                className='input-select '
                                                                isSearchable={true}
                                                            />


                                                        </div><br />
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="mb-3 row custom-deposite-loader-1">
                                                <label for="inputPassword" class="col-sm-2 col-form-label custom-contact-colors p-0"></label>
                                                {

                                                    addressLoader ? (<div class="col-sm-3 text-center"><Spinner animation="border" className='deposite-spinner' /><p style={{ color: "#BC9018", fontSize: "14px", marginTop: "10px" }}>Select Any Coin To Show The Address</p></div>)
                                                        : (
                                                            <div class="col-sm-6">
                                                                <div className='custom-scane-data text-left mt-3'>
                                                                    {NetworkAddress ? (
                                                                        <QRCode
                                                                            size={256}
                                                                            // style={{ height: "auto", maxWidth: "25%", width: "25%" }}
                                                                            value={NetworkAddress}
                                                                            viewBox={`0 0 256 256`}
                                                                            className='new-qr-code-btn-01'
                                                                        />

                                                                    ) : (
                                                                        <p>Select Any Coin To Show The Address</p>
                                                                    )}
                                                                    {/* <p>Address:{NetworkAddress}</p> */}
                                                                    <div>
                                                                        <button className='address-1-btn mt-4 new-btn-ctn-01' onClick={copyText}> {NetworkAddress}<a data-tooltip-id="my-tooltip-click"><PiCopySimpleFill className='ms-3' /></a></button>

                                                                        <Tooltip
                                                                            id="my-tooltip-click"
                                                                            content="Copied!"
                                                                            events={['click']}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row align-items-end">
                                                                    <div className="col-lg-12 mt-4 text-left ">
                                                                        <div className='submitSpan'>
                                                                            <div className='d-flex'>
                                                                                <p className='text-dark'>Note:</p>
                                                                                <div className='ms-0'>
                                                                                    {/* <p>1. Click the SUBMIT button Once the Transaction Completed on Explorer</p>
                                                                                    <p>2. If your previous deposit is not reflecting, you Clicks the Submit Button Again</p> */}
                                                                                    <ul style={{ listStyleType: "auto" }}>
                                                                                        <li> Click the SUBMIT button Once the Transaction Completed on Explorer</li>
                                                                                        <li> If your previous deposit is not reflecting, you Clicks the Submit Button Again (it will affect your crypto wallet within 5 to 10 min)</li>
                                                                                        <li> 2% of your deposit amount will be deducted for Transaction fee</li>
                                                                                    </ul>
                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                        <div className="custom-calender-btn text-center mt-4">

                                                                            {isLoading ? (
                                                                                <button ><Spinner animation="border" /></button>
                                                                            ) : (
                                                                                <button
                                                                                    onClick={() => {
                                                                                        if (emailVerifyStatus !== 1 && kycVerifyStatus !== 2) {
                                                                                            toast.error('Email or KYC not verified. Please verify your email and KYC first.');
                                                                                            setTimeout(() => {
                                                                                                window.location.href = '/dashboard/profile';
                                                                                            }, 2000);
                                                                                        }
                                                                                        else if (!value) {
                                                                                            toast.error('Please select a network');
                                                                                        } else {
                                                                                            DepositTrans();
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    Submit
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                }
                                            </div>
                                            {/* <div class="mb-3 row ">

                                                <div class="col-sm-8">
                                                    <div className='custom-deposite-address'>
                                                        <BsDot /> Send only <span>1IINCH-ERC20</span> to this <br />deposite address.
                                                    </div>
                                                </div>

                                            </div> */}
                                            {/* <div class="mb-3 row ">

                                                <div class="col-sm-8">
                                                    <div className='custom-recent-deposit'>
                                                        <h2>Recent Deposits</h2>
                                                        <p>if your previous deposit is not reflecting, you can claim your deposit below</p>
                                                        <h5>CLAIM DEPOSIT ?</h5>
                                                    </div>
                                                </div>

                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='custom-withdraw-table mt-4'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='custom-with-scroll'>
                                        <table class="table w-100">
                                            <thead >
                                                <tr>
                                                    <th >Assets</th>

                                                    <th >Amount</th>
                                                    <th >Bonus</th>
                                                    <th ></th>
                                                    <th ></th>
                                                    <th >Fees</th>

                                                    <th >Status</th>
                                                    <th >Date</th>


                                                </tr>
                                            </thead>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-wallet-search'>
                                    <HiOutlineDocumentSearch />
                                    <h2 className='mt-2'>No Records Found</h2>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >


    )
}


export default Deposite