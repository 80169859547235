import logo from './logo.svg';
import './App.css';
import { Link } from 'react-router-dom';
import fff from './assets/image/banner4.webp';
import mask from './assets/image/Mask group.png';
import nano from './assets/image/Group-123.png';
import cuptoken from './assets/image/Group-120.png';
import innovative from './assets/image/Group-121.png';
import versatile from './assets/image/Group-118.png';
import why from './assets/image/Group-122.png';
import security from './assets/image/Group-119.png';
import sustainability from './assets/image/Group-117.png';
import man from './assets/image/Group 91.png';
import handphone from './assets/image/Group-2-copy-1.png';
import bottle from './assets/image/avg_time.png';
import gasbottle from './assets/image/Group-104.png';
import spotify from './assets/image/imagedeco-1.png';
import dropbox from './assets/image/cosmotrans-ag-2-1.png';
import tesla from './assets/image/igas 1.png';
import reddit from './assets/image/ISE-23-1.png';
import google from './assets/image/applied-sience-1.png';
import stripe from './assets/image/Stripe.png';
import dhl from './assets/image/DHL.png';
import airbnb from './assets/image/Airbnb.png';
import herotext from './assets/image/Group-114.png';
import threetoy from './assets/image/banner-2-copper.png';
import dotedesign from './assets/image/Group 89.png';
import whitepaper from './assets/image/whitepaper.pdf';
import transfer1 from './assets/image/transfer-1.png';
import Group105 from './assets/image/Group-105.png';
import Trading1 from './assets/image/Trading-1.png';
import simple1 from './assets/image/simple-1.png';
import verification1 from './assets/image/verification-1.png';
import investors1 from './assets/image/investors-1.png';
import highvalue1 from './assets/image/high-value-1.png';
import enhancedperformance from './assets/image/enhanced-performance-1.png';
import versatility1 from './assets/image/versatility-1.png';
import investment1 from './assets/image/investment-1.png';
import sustainlablity1 from './assets/image/sustainlablity-1.png';
import inflation1 from './assets/image/inflation-1.png';
import Asset34 from './assets/image/Asset-34x.png';
import { FaXTwitter } from "react-icons/fa6";
import render12 from './assets/image/RENDER-1-copy.webp';
import Final1 from './assets/image/banner-video.mp4';

import $ from "jquery";
import Navbar from "./separate/Navbar"
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';


function Home() {

    const token = localStorage.getItem('token');

    // console.log("token----", token);


    return (
        <div className="App ">
            <div className='container-fluid'>
                <Navbar />
                <div className="custom-home-banner-body">
                    <div className="row align-items-end justify-content-around flex-lg-row-reverse min-vh-100 custom-videos">
                        <div className="col-lg-12 ">
                            <div className="custom-treasure">
                                <div>
                                    <video id="video-background" autoPlay={true} loop muted poster>
                                        <source src={Final1} type="video/mp4 " className="video_contain " />
                                    </video>
                                </div>
                                <div className="mt-4 custom-treasure1">
                                    {/* <Link to={token ? "/buy" : "/login"}><button className="treasure-btn-1 active me-3 ">BUY Now</button></Link> */}
                                    <Link to="/buy"><button className="treasure-btn-1 active me-3 ">BUY Now</button></Link>
                                    <a href={whitepaper} className='treasure-btn-1' download>Whitepaper</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-home-banner-2">
                    <div className="custom-container">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="custom-home-banner-2-inner">
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        loop={true}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        autoplay={{
                                            delay: 3500,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            768: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            1024: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                        }}
                                        modules={[Autoplay, Pagination]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <div class="row align-items-center justify-content-around">
                                                <div class="col-lg-7">
                                                    <div class="custom-loremleft">
                                                        <h2 className='fw-bold'>Your Gateway to the Smartest Nano Commodity</h2>
                                                        <p>Welcome to the future of trading with the CUP Token Trading Platform. We're revolutionizing the way you invest in commodities with our OTC platform, where you can acquire CUP tokens backed by Ultra Fine Copper Powder (UFCP) – the epitome of a smarter and more intelligent nano commodity.</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="custom-loremright">
                                                        <img src={nano} class="card-img-top" alt="..." />
                                                    </div>
                                                </div>
                                            </div>

                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="row align-items-center justify-content-around">
                                                <div class="col-lg-7">
                                                    <div class="custom-loremleft">
                                                        <h2 className='fw-bold'>Why Choose Our OTC Platform?</h2>

                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="custom-loremright">
                                                        <img src={why} class="card-img-top" alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="row align-items-center justify-content-around">
                                                <div class="col-lg-7">
                                                    <div class="custom-loremleft">
                                                        <h2 className='fw-bold'>Innovative Approach</h2>
                                                        <p>We've redefined commodity trading with the smartest version of nano commodities, offering a digital experience that stands out in the market.</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="custom-loremright">
                                                        <img src={innovative} class="card-img-top" alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="row align-items-center justify-content-around">
                                                <div class="col-lg-7">
                                                    <div class="custom-loremleft">
                                                        <h2 className='fw-bold'>CUP Token</h2>
                                                        <p>Backed by highquality UFCP, CUP tokens are your key to secure and intelligent investments in the world of commodities.</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="custom-loremright">
                                                        <img src={cuptoken} class="card-img-top" alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="row align-items-center justify-content-around">
                                                <div class="col-lg-7">
                                                    <div class="custom-loremleft">
                                                        <h2 className='fw-bold'>Security</h2>
                                                        <p>Our platform ensures the safety of your investments through stateoftheart security measures.</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="custom-loremright">
                                                        <img src={security} class="card-img-top" alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="row align-items-center justify-content-around">
                                                <div class="col-lg-7">
                                                    <div class="custom-loremleft">
                                                        <h2 className='fw-bold'>Versatility</h2>
                                                        <p>CUP tokens open doors to a wide range of applications, making it a versatile asset in your investment portfolio.</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="custom-loremright">
                                                        <img src={versatile} class="card-img-top" alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="row align-items-center justify-content-around">
                                                <div class="col-lg-7">
                                                    <div class="custom-loremleft">
                                                        <h2 className='fw-bold'>Sustainability</h2>
                                                        <p> We embrace sustainability by offering a digital alternative to physical commodities, reducing environmental impact.</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <div class="custom-loremright">
                                                        <img src={sustainability} class="card-img-top" alt="..." />
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-home-banner-3'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-1 col-2'>
                                <div className='dotedesigninside'>
                                    <img src={dotedesign} className="" alt="dot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-dummy'>
                    <div className="custom-container">
                        <div className='row justify-content-around  '>
                            <div className='col-lg-6'>
                                <div className='custom-dummy-left'>
                                    <h3 className='fw-400'>Why CUP (UFCP backed Token)A Sustainable and ForwardThinking Choice</h3>
                                    <img src={handphone} class="card-img-top customhand-phone" alt="..." />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='custom-dummy-right'>
                                    <div className='row'>
                                        <div className='col-lg-6 '>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={transfer1} class="card-img-top" alt="..." />
                                                        <h2>Seamless to Transfer </h2>
                                                        <p>Moving physical Ultra Fine Copper Powder (UFCP) can be
                                                            cumbersome and expensive, but transferring your CUP tokens
                                                            – backed by UFCP – is as simple as managing your other
                                                            crypto assets, wherever and whenever you choose.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={Group105} class="card-img-top" alt="..." />
                                                        <h2>CUP: The Smarter Alternative</h2>
                                                        <p>CUP, a token that grants you ownership of genuine physical Copper, brings the intelligence of blockchain to the world of Precious Metals, merging the best features of crypto assets.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={Trading1} class="card-img-top" alt="..." />
                                                        <h2>Effortless Trading</h2>
                                                        <p>Trading physical Precious Metals ties you to location and hours. CUP tokens can be traded 24/7, 365 days a year, worldwide, on the CUP trade Platform and on crypto Exchanges.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={simple1} class="card-img-top" alt="..." />
                                                        <h2>Simple Redemption</h2>
                                                        <p>If you wish to redeem your CUP tokens for physical copper, it's a

                                                            straightforward process. We deliver copper worldwide, terms
                                                            and conditions apply.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={verification1} class="card-img-top" alt="..." />
                                                        <h2>Effortless Verification</h2>
                                                        <p>Track all UFCP backed CUP tokens on our platform and verify your copper allocation in our secure vaults in Switzerland.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={investors1} class="card-img-top" alt="..." />
                                                        <h2>CUP for Institutional Investors</h2>
                                                        <p>CUP is an ideal asset for institutional investors, offering UFCP ownership with all the advantages of a crypto asset.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-dotedesign'>
                    <div className='container-fluid'>
                        <div className='row d-flex justify-content-end'>
                            <div className='col-lg-1 col-2'>
                                <div className='dotedesigninside'>
                                    <img src={dotedesign} className="" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-typesetting'>
                    <div className='custom-container'>
                        <div className='row justify-content-around flex-lg-row-reverse'>
                            <div className='col-lg-6 '>
                                <div className='custom-dummy-left setting'>
                                    <h3 className='fw-400'>Why to Invest in Ultra Fine Copper Powder (CU63 & CU65)? </h3>
                                    <img src={gasbottle} class="card-img-top" alt="..." />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='custom-dummy-right'>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={highvalue1} class="card-img-top" alt="..." />
                                                        <h2>High Purity, High Value:</h2>
                                                        <p> Exceptional electrical conductivity drives demand for ultrapure copper powder.
                                                            Essential in semiconductor, PCB, and highperformance connector production.
                                                            Low impurities ensure suitability for critical applications.
                                                            Minimal contaminants safeguard against detrimental trace impurities.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={enhancedperformance} class="card-img-top" alt="..." />
                                                        <h2>Enhanced Performance:</h2>
                                                        <p>  Ultra pure copper elevates efficiency in electronics and
                                                            conductive coatings, ensuring reliability and longevity by
                                                            reducing contamination related failures. It significantly
                                                            improves device and system performance. High purity copper
                                                            also minimizes the risk of corrosion</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={versatility1} class="card-img-top" alt="..." />
                                                        <h2>Versatility:</h2>
                                                        <p> Applied across diverse industries, including electronics and
                                                            aerospace, ultra pure copper is customizable to meet specific
                                                            manufacturing and property needs. It's a versatile material for
                                                            a wide array of applications and is widely used in the
                                                            automotive and other sectors.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={investment1} class="card-img-top" alt="..." />
                                                        <h2> LongTerm Investment:</h2>
                                                        <p> The growing demand in electric vehicles, renewables, and
                                                            electronics expands the need for ultra pure copper powder.
                                                            This trend indicates potential for long term growth and
                                                            investment returns, as high purity copper remains a
                                                            valuable commodity.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={sustainlablity1} class="card-img-top" alt="..." />
                                                        <h2>Sustainability:</h2>
                                                        <p> The growing demand in electric vehicles, renewables, and
                                                            electronics expands the need for ultra pure copper powder.
                                                            This trend indicates potential for long term growth and
                                                            investment returns, as high purity copper remains a valuable
                                                            commodity.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div class="card border-0 h-100">
                                                <div class="card-body p-0">
                                                    <div className='custom-lorem-ispum'>
                                                        <img src={inflation1} class="card-img-top" alt="..." />
                                                        <h2> Hedge Against Inflation:</h2>
                                                        <p>Investing in copper is an effective hedge against inflation's
                                                            erosion of value. As an essential material, its value tends to
                                                            rise, making it a valuable asset for preserving purchasing
                                                            power and offering protection against the effects of inflation.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ecosystem'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='custom-gold'>
                                    {/* {token ? (<a href="/buy"><button className='treasure-btn-1 active mb-3'>LEARN MORE</button></a>) : (<a href="/login"><button className='treasure-btn-1 active mb-3'>LEARN MORE</button></a>)} */}
                                    <a href="/buy"><button className='treasure-btn-1 active mb-3'>LEARN MORE</button></a>
                                    <h3>THE DICTYS ULTRA FINE COPPER ECOSYSTEM</h3>
                                    <p>CUP Token, A smarter version of Ultra Fine Copper Powder <br /> The Smart Choice for Smarter Investors</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-brand">
                    <div className='custom-container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-2 custom-brandborder'>
                                <img src={spotify} class="card-img-top" alt="..." />
                            </div>
                            <div className='col-lg-2 custom-brandborder'>
                                <img src={dropbox} class="card-img-top" alt="..." />
                            </div>
                            <div className='col-lg-2 custom-brandborder'>
                                <img src={tesla} class="card-img-top" alt="..." />
                            </div>
                            <div className='col-lg-2  custom-brandborder'>
                                <img src={reddit} class="card-img-top" alt="..." />
                            </div>
                            <div className='col-lg-3 custom-redditborder'>
                                <img src={google} class="card-img-top" alt="..." />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 align-items-center justify-content-center'>
                                <div className='text-center token-img-paper mt-5 mb-4'>
                                    <img src={Asset34}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-bussiness">
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <div className='custom-bussiness-left'>
                                    <img src={herotext} class="card-img-top" alt="..." />
                                </div>
                            </div>
                            <div className='col-lg-7 d-flex align-items-center'>
                                <div className='custom-bussiness-center d-flex justify-content-between align-items-center w-100'>
                                    <h3>Invest in CUP tokens today for a smarter tomorrow.</h3>
                                    <div className='bussiness-btn  d-flex align-items-center'>
                                        <Link to="/buy"><button >BUY now </button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 p-0'>
                                <div className='custom-bussiness-right'>
                                    <img src={threetoy} class="card-img-top" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-fotter">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="custom-fotter-left">
                                    <ul class="list-group list-group-horizontal">
                                        {/* <Link to={token?"/tobulkorder":"/login"}>
                                            <li class="list-group-item custom-footer-list">BUY VIA BANK</li>
                                        </Link>
                                        <Link to={token?"/buy":"/login"}>
                                            <li class="list-group-item custom-footer-list">BUY WITH CRYPTO</li>
                                        </Link> */}

                                        <Link to="/tobulkorder">
                                            <li class="list-group-item custom-footer-list">BULK ORDER</li>
                                        </Link>
                                        <Link to="/buy">
                                            <li class="list-group-item custom-footer-list">BUY WITH CRYPTO</li>
                                        </Link>
                                        <Link to="/isotope">
                                            <li class="list-group-item custom-footer-list">WHAT IS OUR ASSET</li>
                                        </Link>
                                    </ul>
                                    <ul class="list-group list-group-horizontal">
                                        <Link to="/terms"><li class="list-group-item custom-footer-list">TERMS AND CONDITIONS</li></Link>
                                        <Link to="/privacypolicy"><li class="list-group-item custom-footer-list">PRIVACY POLICY</li></Link>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="custom-fotter-right">
                                    <a href=''><img src={render12}></img></a>
                                </div>
                                <div className='col-lg-8'>
                                    <ul class=" text-lg-center ps-0 ">
                                        <a href="https://twitter.com/DictysFinance" target='_blank' className='text-dark'><li class="list-group-item custom-footer-list"> <FaXTwitter /></li></a>
                                    </ul>
                                </div>

                            </div>
                            <div className='col-lg-12 '>
                                <ul class=" text-lg-center p-0">
                                    <a href=""  className='text-dark'><li class="list-group-item custom-footer-list">Copyright @ 2024 Dictys</li></a>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    );

}


export default Home;