import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios'
import '../assets/css/Style2.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import $ from "jquery";
import usermask from '../assets/image/usermask.png';
import { ToastContainer, toast } from "react-toastify";
import config from '../config/config'
import { useNavigate } from "react-router-dom";
import { Formik, useFormik, } from "formik";
import * as Yup from "yup";
import Checkbox from "react-custom-checkbox";
import { FaEye } from "react-icons/fa";
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { Icon } from 'react-icons-kit';
import { Spinner } from "react-bootstrap";
import { makeApiRequest } from "../config/axiosService";
import profile3 from '../assets/image/profile3.png';
import render12 from '../assets/image/RENDER-1-copy.webp';

function Register() {
    // const [email, setEmail] = useState()
    // const [password, setPassword] = useState()
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [loader, setLoader] = useState(false);

    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }


    const navigate = useNavigate()

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const passwordMatch =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~()\-_+=!@#$%^&*<>`:{}\[\];'?",.])(?=.*\d).{8,}$/;

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        errors,
        values,
        resetForm,
        setValues
    } = useFormik({
        initialValues: {
            email: "",
            password: "",
            termsAndConditions: false,

        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Please enter valid email!").required("E-mail is required!").matches(regex, 'Invalid Email!'),
            password: Yup.string().required(" Password is required!").matches(passwordMatch,"Password must contain at least 8 characters, one uppercase letter, one number, and atleast one symbol!"),
            termsAndConditions: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),

        }),
        onSubmit: async (values) => {
            try {
                const payload = {
                    email: values.email,
                    password: values.password
                };
                let params = {
                    url: `register`,
                    method: "POST",
                    data: payload
                }
                // await axios.post(`${config.backendurl}/register`, payload)
                const res = await makeApiRequest(params).then((res) => {
                    // console.log("res", res.status);
                    if (res.status === false) {
                        setLoader(false)
                        toast.error(res.message)
                    }
                    else {
                        console.log('else');
                        setLoader(true)
                        setTimeout(() => {
                            setLoader(false)
                            toast.success('Register SuccessFully')
                            // }
                        }, 2000);
                        setTimeout(() => {
                            navigate("/login");
                        }, 4000);
                    }
                })
            } catch (error) {
                // console.log("error", error.message);
            }
        },
    });

    useEffect(() => {
        const gettoken = localStorage.getItem("token")
        // console.log("token", gettoken);
        { gettoken ? navigate('/') : navigate("/register") }
    }, [])

    return (
        <div>
            <ToastContainer />
            <section className="register-bg-styling-css">
                <div className="row justify-content-center align-items-center m-0">
                    <div className="col-lg-11 ">
                        <div className="pt-4">
                            <a class="navbar-brand" href="/"><img src={render12}></img></a>
                        </div>
                    </div>
                </div>
                {/* <a class="navbar-brand" href="/"><img src={render12} className="mx-4 my-2"></img></a> */}
                <div className="container-fluid col-lg-9">
                    <div className="row min-vh-100 text-center align-items-center justify-content-between flex-lg-row-reverse">
                        <div className="col-lg-5 text-center py-4 register-card-bg-styling login-inputs-css">

                            <img src={profile3} className="usermask-image-css" />
                            <form
                                className="new-contact-form"
                                name="footercontact"
                                id="footercontact"
                                autocomplete="off"
                                novalidate="novalidate"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                    return false;
                                }}
                            >
                                <p className="fs-20 fw-500 text-white">Register</p>
                                {/* <input type="text" class="form-control " placeholder="Email" id="exampleInputPassword1" onChange={(e) => setEmail(e.target.value)} /> */}
                                <div className="mb-3">
                                    <div class="input-group">
                                        <input type="text"
                                            name='email'
                                            class="form-control mb-0"
                                            id="exampleInputPassword1"
                                            placeholder='Email'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            onKeyPress={(event) => {
                                                const keyCode = event.which || event.keyCode;
                                                const keyValue = String.fromCharCode(keyCode);

                                                // Allow only numeric values (0-9) and the backspace key (8)
                                                if (!/^[a-zA-Z0-9._@]+$/.test(keyValue)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            invalid={touched.email && errors.email ? true : false}
                                        />
                                    </div>
                                    {touched.email && errors.email && (
                                        <small className="invalid-login error password-text-33">
                                            {errors.email}
                                        </small>
                                    )}
                                </div>

                                {/* <input type="password" class="form-control " placeholder="Password" id="exampleInputPassword1" onChange={(e) => setPassword(e.target.value)} /> */}
                                {/* <input type="password"
                                    name='password'
                                    class="form-control mb-0 mt-2"
                                    id="exampleInputPassword1"
                                    placeholder='Password'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    invalid={touched.password && errors.password ? true : false}
                                />
                                {touched.password && errors.password && (
                                    <small className="invalid-login error password-text-33">
                                        {errors.password}
                                    </small>
                                )} */}
                                <div className="mb-3">
                                    <div class="input-group mt-2">
                                        <input
                                            type={type}
                                            name="password"
                                            // type="password" 
                                            class="form-control with-input mb-0"
                                            id="exampleInputPassword1"
                                            placeholder="Password"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            invalid={touched.password && errors.password ? true : false} />
                                        {/* <span class="input-group-text  eye-icon" id="basic-addon1"><FaEye /></span> */}
                                        <span class="input-group-text eye-icon" style={{ cursor: "pointer" }} onClick={handleToggle}>
                                            <Icon class="absolute mr-10" icon={icon} size={25} />
                                        </span>
                                    </div>
                                    {touched.password && errors.password && (
                                        <small className="invalid-login error password-text-33">
                                            {errors.password}
                                        </small>
                                    )}
                                </div>

                                {/* <br /><br /> */}
                                <div class="mb-3 mt-3 d-flex flex-row align-items-center text-center justify-content-center">
                                    <div >
                                        <Link to="/terms">
                                            <label class="form-check-label text-white" for="exampleCheck1" style={{ cursor: "pointer" }}>Terms & Conditions</label>
                                        </Link>

                                    </div>
                                    <div className="ms-2 ">

                                        <input type="checkbox"
                                            class="form-check-input check-box"
                                            name="termsAndConditions"
                                            checked={values.termsAndConditions}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            id="exampleCheck1" />
                                    </div>
                                </div>
                                <div>
                                    {touched.termsAndConditions && errors.termsAndConditions && (
                                        <small className="invalid-login error password-text-33">
                                            {errors.termsAndConditions}
                                        </small>
                                    )}
                                </div>

                                {/* {errors.termsAndConditions && <p>{errors.termsAndConditions} </p>} */}
                                {loader == true ? (
                                    <a className="spinner-btn-1"><Spinner animation="border" /></a>
                                ) : (
                                    <button className="spinner-btn-1 p-0 mt-2" > Sign Up </button>
                                )}
                                {/* <a href="" className="fs-15 py-3 fw-500 text-white" >Signup</a> */}
                            </form>
                        </div>

                        <div className="col-lg-6 mt-lg-5 pt-lg-5 mb-5">
                            <p className="fcaps heading-1-css  mb-2 d-lg-block d-none">welcome </p>
                            <p className="fs-20  text-center d-lg-block d-none">Login continue your journey with the largest
                                community of innovators</p>
                            <p className="mt-3 mt-lg-5 fs-20 fw-500">Already have an account?</p>
                            <a href="/login" className="text-dark"><button className="btn main-btn-css-1">Login</button></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Register;
