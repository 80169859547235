import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Navbar from "./separate/Navbar";
import Bottle from "./assets/image/bottle.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import Tobulkorder from "./pages/Tobulkorder";
import axios from "axios";
import config from "./config/config";
import { logDOM } from "@testing-library/react";
import { jwtDecode } from "jwt-decode";
import { makeApiRequest } from "./config/axiosService";
import { useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import AuthContext from "../src/useContext";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";

function Buy() {
  const [data, setData] = useState([]);
  const [userExist, setUserExist] = useState(false);
  const [Ampouleno, setAmpouleno] = useState();
  const [Id, setId] = useState();
  const [pageNumber, setPageNumber] = useState(2);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [emailVerifyStatus, setEmailVerifyStatus] = useState()
  // const [currentPageData,setCurrentPageData] = useState(0)

  const token = localStorage.getItem("token");

  const [gramsSold1, setGramsSold1] = useState(100);
  const [gramsSold2, setGramsSold2] = useState(50);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increase grams sold every hour by 1, reset to 100 if it reaches 200
      setGramsSold1(prevGramsSold => (prevGramsSold === 200 ? 100 : prevGramsSold + 1));
      setGramsSold2(prevGramsSold => (prevGramsSold === 200 ? 50 : prevGramsSold + 1));
    }, 3600000); // 1 hour in milliseconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  const fractionSold = ((gramsSold1 / 200) * 100).toFixed(0);
  const fractionSold1 = ((gramsSold2 / 200) * 100).toFixed(0);


  const BuyFraction = async () => {
    try {
      setLoader(true)
      let params = {
        url: `buyfraction`,
        method: "GET",
      };
      const response = await makeApiRequest(params);
      // console.log("BuyFraction--",response);
      if (response.status == true) {
        setData(response.data);
        setLoader(false)
      }else{
        setData([]);
        setLoader(false)
      }
    } catch (error) {
      setLoader(true)
    }

  };




  const userFind = async () => {

    if (token) {
      const Token = localStorage.getItem("token");
      const decoded = jwtDecode(Token);
      const id = decoded.userId;

      const payload = {
        id,
      };
      let params = {
        url: `userFind`,
        method: "POST",
        data: payload,
      };
      const Email = localStorage.getItem("buyEmail");
      const response = await makeApiRequest(params);
      // console.log('userfind', response);
      if (response.status == true) {
        setUserExist(true);
      } else {
        setUserExist(false);
      }
    }
  };


  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }


  const GetAllCSV = async (id) => {
    // console.log("id", id);
    let params = {
      url: `getAll?proID=${id}`,
      method: "get",
    };
    const response = await makeApiRequest(params);
    // console.log('csvresponse', response);
    if (response.status == true) {
      setAmpouleno(response.user[0]._id);
    }else{
      setAmpouleno("");
    }
  };
  const PER_PAGE = 9;
  const offset = currentPage * PER_PAGE;

  const currentPageData = data
    .slice(offset, offset + PER_PAGE)
    .map((item, index) => (
      <>
        <div key={index} className="col-lg-4 text-center mb-5 ">
          <div className="assets-card-bg-styling-css  py-3 px-3">
            <div className="row row-cols-1 text-center align-items-center justify-content-between">
              <div className="col">
                <p className="card-title-styling-css text-start">
                  Ultrafine Copper Powder
                </p>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                      <p className="assets-card-inner-text-css">Ampoule No:</p>
                      <p className="assets-card-inner-text-css">
                        **********
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                      <p className="assets-card-inner-text-css">Net weight:</p>
                      <p className="assets-card-inner-text-css">
                        {200} gram
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                      <p className="assets-card-inner-text-css">Gram sold:</p>
                      <p className="assets-card-inner-text-css">
                        {200 - item.netweight} gram
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                      <p className="assets-card-inner-text-css">
                        Gram Available:
                      </p>
                      <p className="assets-card-inner-text-css">
                        {item.netweight} gram
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                      <p className="assets-card-inner-text-css">
                        Fraction sold:
                      </p>
                      <p className="assets-card-inner-text-css">
                        {(((200 - item.netweight) / 200) * 100).toFixed(0)}%
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                      <p className="assets-card-inner-text-css">
                        Fraction Available:
                      </p>
                      <p className="assets-card-inner-text-css">
                        {((item.netweight / 200) * 100).toFixed(0)}%
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                      <p className="assets-card-inner-text-css">
                        {item.currentvalue}USD / Fraction Or Gram
                      </p>
                    </div>
                    <div className="text-start mb-2">
                      {token ? (
                        <>
                          <a
                            type="button"
                            className="treasure-btn-1 custom-assetbtn align-items-start "
                            href="/tobulkorder"
                          >
                            Buy
                          </a>
                        </>
                      ) : (
                        <>
                          <Link to="/login"><button
                            type="button"
                            className="treasure-btn-1 custom-assetbtn align-items-start "
                          >
                            Buy
                          </button></Link>
                        </>
                      )

                      }
                    </div>
                    <Link to="/tobulkorder" style={{ cursor: "pointer", color: "black" }}>
                      <p style={{ cursor: "pointer" }} className="assets-card-inner-text-css text-start asset-ampoule-1">
                        For bulk order or To get complete Ampoule
                      </p>
                    </Link>

                    <Link to="/view" target="_blank">
                      <h3 className="custom-view text-start text-dark">
                        view certify
                        <MdKeyboardArrowRight />
                        <span></span>
                      </h3>
                    </Link>

                  </div>
                  <div className="col-lg-3">
                    <img className="custom-buybottle" src={Bottle} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ));

  const pageCount = Math.ceil(data.length / PER_PAGE);
  useEffect(() => {
    userFind();
    BuyFraction();
  }, []);
  return (
    <>
      <div className="App custom-buy-navbar">
        <AuthContext.Provider value={{ ampouleno: Ampouleno }}>
          <div className="container-fluid">
            <Navbar />
            <div className="custom-buy">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="custom-inside-buy">
                      <p>
                        Get Copper easy and secure with Cryptocurrencies in our platform. Trust our encrypted transactions for both Gram and Bulk purchases.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mb-5">
                  <div className="col-lg-4">
                    <div className='assets-card-bg-styling-css  py-3 px-3 mb-5 mb-lg-0'>
                      <div className="row row-cols-1 text-center align-items-center justify-content-between">
                        <div className="col">
                          <p className="card-title-styling-css text-start">Ultrafine Copper Powder</p>
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Ampoule No:</p>
                                <p className="assets-card-inner-text-css">*********</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Net weight:</p>
                                <p className="assets-card-inner-text-css">Unlimited</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Gram Sold:</p>
                                <p className="assets-card-inner-text-css">{gramsSold2} gram</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Gram Available:</p>
                                <p className="assets-card-inner-text-css">Unlimited</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Fraction Sold:</p>
                                <p className="assets-card-inner-text-css">{fractionSold1}%</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Fraction Available:</p>
                                <p className="assets-card-inner-text-css">Unlimited</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">
                                  USD / Fraction Or Gram
                                </p>

                              </div>
                              <div className='text-start mb-2'>
                                {token ? (
                                  <>
                                    <button
                                      type="button"
                                      className="treasure-btn-1 custom-assetbtn align-items-start "
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModalToggle"
                                    >
                                      Buy fraction
                                    </button>
                                  </>

                                ) : (
                                  <>
                                    <Link to="/login"><button
                                      type="button"
                                      className="treasure-btn-1 custom-assetbtn align-items-start "
                                    >
                                      Buy fraction
                                    </button></Link>
                                  </>
                                )
                                }
                                {/* <button className='treasure-btn-1 custom-assetbtn align-items-start'>Buy fraction</button> */}
                              </div>

                              <Link to="/tobulkorder" style={{ cursor: "pointer", color: "black" }}>
                                <p style={{ cursor: "pointer" }} className="assets-card-inner-text-css text-start asset-ampoule-1">
                                  For bulk order or To get complete Ampoule
                                </p>
                              </Link>
                              <Link to="/view" target="_blank">
                                <h3 className="custom-view text-start text-dark">
                                  View Certify
                                  <MdKeyboardArrowRight />
                                  <span></span>
                                </h3>
                              </Link>
                            </div>
                            <div className="col-lg-3">
                              <img className="custom-buybottle" src={Bottle} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className='assets-card-bg-styling-css  py-3 px-3'>
                      <div className="row row-cols-1 text-center align-items-center justify-content-between">
                        <div className="col">
                          <p className="card-title-styling-css text-start">Ultrafine Copper Powder</p>
                          <div className="row">
                            <div className="col-lg-9 ">
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Ampoule No:</p>
                                <p className="assets-card-inner-text-css">*********</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Net weight:</p>
                                <p className="assets-card-inner-text-css">Unlimited</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Gram Sold:</p>
                                <p className="assets-card-inner-text-css">{gramsSold1} gram</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Gram Available:</p>
                                <p className="assets-card-inner-text-css">Unlimited</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Fraction Sold:</p>
                                <p className="assets-card-inner-text-css">{fractionSold}%</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">Fraction Available:</p>
                                <p className="assets-card-inner-text-css">Unlimited</p>
                              </div>
                              <div className="d-flex flex-row  text-center align-items-center justify-content-between">
                                <p className="assets-card-inner-text-css">
                                  USD / Fraction Or Gram
                                </p>

                              </div>
                              <div className='text-start mb-2'>
                                {token ? (
                                  <>
                                    <button
                                      type="button"
                                      className="treasure-btn-1 custom-assetbtn align-items-start "
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModalToggle"
                                    >
                                      Buy fraction
                                    </button>
                                  </>

                                ) : (
                                  <>
                                    <Link to="/login"><button
                                      type="button"
                                      className="treasure-btn-1 custom-assetbtn align-items-start "
                                    >
                                      Buy fraction
                                    </button></Link>
                                  </>
                                )
                                }
                                {/* <button className='treasure-btn-1 custom-assetbtn align-items-start'>Buy fraction</button> */}
                              </div>

                              <Link to="/tobulkorder" style={{ cursor: "pointer", color: "black" }}>
                                <p style={{ cursor: "pointer" }} className="assets-card-inner-text-css text-start asset-ampoule-1">
                                  For bulk order or To get complete Ampoule
                                </p>
                              </Link>
                              <Link to="/view" target="_blank">
                                <h3 className="custom-view text-start text-dark">
                                  View Certify
                                  <MdKeyboardArrowRight />
                                  <span></span>
                                </h3>
                              </Link>
                            </div>
                            <div className="col-lg-3">
                              <img className="custom-buybottle" src={Bottle} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  loader ? (<div class="spinner"></div>) : (<div className="row justify-content-center align-items-between">
                    {currentPageData}
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={0}
                      pageRangeDisplayed={0}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center custom-value-process"}
                      activeClassName={"active"}
                      pageClassName={"page-link"}
                      previousClassName={"page-link custom-value-prev"}
                      nextClassName={"page-link"}
                      breakClassName={"page-link"}
                    />
                  </div>)
                }


              </div>
            </div>
          </div>
        </AuthContext.Provider>
      </div>
      <div className="justify-content-center custom-fraction-modal">
        <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
          <div class="modal-dialog">
            <div class="modal-content custom-edit-wallet">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="custom-buy-navbarss-new display-hide">
                  <Tobulkorder Ampouleno={Ampouleno} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="justify-content-center ">
        {/* <button type="button" class="treasure-btn-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button> */}
      </div>

      <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5">
                Deposit
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">Your Balance is 0.0 , You Want to Deposit First</div>
            <div class="modal-footer">
              <Link to='/deposite'>
                <button
                  type="button"
                  class="treasure-btn-1"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <button class="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Open first modal</button> */}
    </>
  );
}

export default Buy;
