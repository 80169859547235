// let config = {}
// let environment = "local"
// if (environment == "local") {
//     config = {
//     frontendurl:'http://localhost:3000',
//     backendurl:'http://localhost:5000/',
//     sepolia_explorer_url: 'https://sepolia.etherscan.io'
//     }
// }
// else if (environment == "demo") {
//     config = {
//         frontendurl:'http://54.221.80.30',
//         backendurl:'http://54.221.80.30:3000/'
//     }
// }
// else if (environment == "demo") {
//     config = {
//         frontendurl:'https://cuptoken.trade',
//         backendurl:'https://api.cuptoken.trade'
//     }


let config = {};
let environment = "demo";
if (environment == "local") {
  config = {
    frontendurl: "http://localhost:3000",
    backendurl: "http://localhost:5000/",
    adminBackendUrl: "http://localhost:5000/admin/",
    sepolia_explorer_url: 'https://sepolia.etherscan.io',
    polygon_explorer_url: 'https://mumbai.polygonscan.com',
    dchain_explorer_url: 'https://mainnet-explorer.d-ecosystem.io',
    bitcoin_explorer_url: 'https://live.blockcypher.com/btc-testnet',
    bnb_explorer_url: 'https://testnet.bscscan.com',
    btc_rpc: "https://connect.bitcoinevm.com/",
    eth_rpc: "https://sepolia.infura.io/v3/8e369ed1dcce4943868a18881336764f",
    bnb_rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    dcx_rpc: "https://mainnetcoin.d-ecosystem.io/",
    matic_rpc: "https://rpc-mumbai.maticvigil.com",
    toAddress : "0x079F0E0A4cfaBC9777DdF49170988B14eF1d4E7B",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
  };
} else if (environment == "demo") {
  config = {
    frontendurl: "https://staging.cuptoken.trade/",
    backendurl: "https://staging.api.cuptoken.trade/",
    adminBackendUrl: "https://staging.api.cuptoken.trade/admin/",
    sepolia_explorer_url: 'https://sepolia.etherscan.io',
    polygon_explorer_url: 'https://mumbai.polygonscan.com',
    dchain_explorer_url: 'https://mainnet-explorer.d-ecosystem.io',
    bitcoin_explorer_url: 'https://live.blockcypher.com/btc-testnet',
    bnb_explorer_url: 'https://testnet.bscscan.com',
    btc_rpc: "https://connect.bitcoinevm.com/",
    eth_rpc: "https://sepolia.infura.io/v3/8e369ed1dcce4943868a18881336764f",
    bnb_rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    dcx_rpc: "https://mainnetcoin.d-ecosystem.io/",
    matic_rpc: "https://rpc-mumbai.maticvigil.com",
    toAddress : "0x079F0E0A4cfaBC9777DdF49170988B14eF1d4E7B",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
  };
} else {
  config = {
    frontendurl: "https://cuptoken.trade/",
    backendurl: "https://api.cuptoken.trade/",
    adminBackendUrl: "https://api.cuptoken.trade/admin/",
    sepolia_explorer_url: 'https://sepolia.etherscan.io',
    polygon_explorer_url: 'https://mumbai.polygonscan.com',
    dchain_explorer_url: 'https://mainnet-explorer.d-ecosystem.io',
    bitcoin_explorer_url: 'https://btcscan.org',
    bnb_explorer_url: 'https://testnet.bscscan.com',
    btc_rpc: "https://connect.bitcoinevm.com/",
    eth_rpc: "https://mainnet.infura.io/v3/",
    bnb_rpc: "https://bsc-dataseed1.binance.org/",
    dcx_rpc: "https://mainnetcoin.d-ecosystem.io/",
    matic_rpc: "https://polygon-rpc.com/-polygon/",
    toAddress : "0x079F0E0A4cfaBC9777DdF49170988B14eF1d4E7B",
    encdcrKey: "awesrdtfyguhijmok!@#$%^&*",
  };
}

export default config;
