import React, { useEffect, useState } from "react";
import axios from 'axios'
import config from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import speakeasy from 'speakeasy'
import base32 from 'hi-base32';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AiOutlineArrowRight } from 'react-icons/ai';
import { makeApiRequest } from "../config/axiosService";
import { setIn } from "formik";
import { Link, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import render12 from '../assets/image/RENDER-1-copy.webp';
import CryptoJS from 'crypto-js';



function WithdrawAuthenticator() {
    const [otp, setOtp] = useState('');
    const { Type } = useParams();
    const location = useLocation()
    const password = location.state && location.state.password;
    const [userSceret, setUserSecret] = useState()
    const [encryptOTP, setEncryptOTP] = useState()
    const [verificationCode, setVerificationCode] = useState({
        ist: "",
        sec: "",
        third: "",
        fourth: "",
        fifth: "",
        six: "",
    });
    const [combinedValues, setCombinedValues] = useState("");
    const VerifyAuthenticate = localStorage.getItem('verifyAuthenticate');
    const navigate = useNavigate();
    const email = localStorage.getItem('email')
    const [ payloadData,setPayloadData ] =useState({})

useEffect(() => {
    const timeout = setTimeout(() => {
      if (localStorage.getItem('verifyAuthenticate') === '1') {
        localStorage.setItem('verifyAuthenticate', '0');
        window.location.href = "/withdrawcrypto";
      }
    }, 180000); // 3 minute in milliseconds

    const handleBeforeUnload = () => {
      localStorage.setItem('verifyAuthenticate', '0');
      clearTimeout(timeout);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      clearTimeout(timeout);
    };
  }, []);

    const get = async () => {
        let params = {
            url: `enable?UserEmail=${email}`,
            method: "GET",
        }
        const response = await makeApiRequest(params);
        if(response.status == true){
            const secret = response.user.secret
            var bytes = CryptoJS.AES.decrypt(secret, config.encdcrKey);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            const encryptOTP = response.user.encryptOTP
            setUserSecret(originalText)
            setEncryptOTP(encryptOTP)
        }else{
            setUserSecret("")
            setEncryptOTP(0)
        }
    }

    const verify = async () => {
        if (!otp) {
            toast.error("Please enter 2FA code")
        }
        const email = localStorage.getItem('email')
        const payload = {
            otp,
            email,
            password
        };
        const searchQuery = 'authenticator';
        let params = {
            url: `userLogin?query=${searchQuery}`,
            method: "POST",
            data: payload
        }
        const res = await makeApiRequest(params);
        if (res.message == 'Authentication success!') {
            localStorage.setItem('token', res.token)
            toast.success('Authentication Success')
        }
        else {
            toast.error('Authentication failed,Please enter the valid 2FA code');
        }
    }

    useEffect(() => {
        get()
        const searchParams = new URLSearchParams(location.search);
        const toAddress = searchParams.get("toAddress");
        const network = searchParams.get("network");
        const amount = searchParams.get("amount");
        setPayloadData({
             toAddress,
             network,
             amount
        })  
    }, []);

    const verify2FA = async () => {
        const userToken = otp;
        const secret = userSceret;
        if (!secret) {
            toast.error('User not Found');
            return;
        }
        else if (!otp) {
            toast.error('Please enter 2FA code')
        }
        else {
            try {
                const secretAscii = base32.decode(secret);
                const secretHex = toHex(secretAscii);
                function toHex(str) {
                    let result = '';
                    for (let i = 0; i < str.length; i++) {
                        result += str.charCodeAt(i).toString(16);
                    }
                    return result;
                }
                const verified = speakeasy.totp.verify({
                    secret: secretHex,
                    encoding: 'hex',
                    token: userToken,
                });
                if (verified) {
                    localStorage.setItem('verifyOTP', 1);
                    toast.success('Authentication SuccessFully');
                    setTimeout(() => {
                        window.location.href = `/verifyOTP?toAddress=${payloadData.toAddress}&network=${payloadData.network}&amount=${payloadData.amount}`
                    }, 4000);
                } else {
                    toast.error('Authentication failed,Please enter the valid 2FA code');
                }
            } catch (error) {
                console.log('Error during verification:', error.message);
            }
        }
    }
    return (
        <div>
            <ToastContainer />
            <section className="register-bg-styling-css">
                <div className="row justify-content-center align-items-center m-0">
                    <div className="col-lg-11 ">
                        <div className="pt-4">
                            <a class="navbar-brand" href="/"><img src={render12}></img></a>
                        </div>
                    </div>
                </div>
                <div className="container ">

                    <div className="row min-vh-100 text-center align-items-center justify-content-center">

                        <div className="col-md-12 col-lg-4 login-inputs-css">
                            <div className="row align-items-center  text-center  authenticator-card-bg-styling">
                                <div>
                                    <p className="fs-20 fw-500 text-white">Authenticator</p>
                                    <div className="userInput">
                                        
                                        <OtpInput
                                            value={otp}
                                            onChange={(value) => {
                                                const numericValue = value.replace(/\D/g, ''); // Remove non-digits
                                                setOtp(numericValue);
                                            }}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                    {
                                        Type == 'withdraw' ? (
                                            <button className="custom-aut-btn mt-5" onClick={verify2FA}><AiOutlineArrowRight /></button>
                                        ) : (
                                            <button className="custom-aut-btn mt-5" onClick={verify}><AiOutlineArrowRight /></button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );

}

export default WithdrawAuthenticator;
