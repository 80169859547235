import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import $ from "jquery";
import { FaXTwitter } from "react-icons/fa6";
import { makeApiRequest } from "../config/axiosService";

function ConfirmMail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mailStatus, setMailStatus] = useState({});
  const navigate = useNavigate();
  let mailVerifyStatus = async (payLoad) => {
    try {
      let params = {
        url: "mail-verify-status",
        method: "POST",
        data: payLoad,
      };
      let response = await makeApiRequest(params);
      if(response.status){
        setMailStatus(response);
        setTimeout(() => {
          navigate('/dashboard/profile')
        }, 2000);
      }else{
        setMailStatus(response);
        setTimeout(() => {
          navigate('/')
        }, 2000);
      }
    } catch (error) {
      setMailStatus(error);
      navigate('/')
    }
  };
  useEffect(() => {
    let token = searchParams.get("request");
    if(token){
    let payload = {
      token: token,
    };
    mailVerifyStatus(payload);
  }else{
    navigate('/')
  }
  });
  return (
    <div className="App">
      <div className="container-fluid ">
        <div className="custom-thankyou  ">
          <div className="row  align-items-center">
            <div className="col-lg-8 mx-auto">
              <div className="custom-inside-thankyou">
                <h2 className="mt-4 mb-4">{mailStatus.message}</h2>
                {mailStatus.status ? (
                  <Link to="/">
                    <button className="custom-thankyoubtn mb-2">Home</button>
                  </Link>
                ) : (
                  <Link to="/dashboard/profile">
                    <button className="custom-thankyoubtn mb-2">
                      Dashboard
                    </button>
                  </Link>
                )}

                <div className="custom-icon-form mt-3 mb-4">
                  <span>
                    <FaXTwitter className="me-1 thankyou-icon" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmMail;
