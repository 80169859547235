import './App.css';
import '../src/assets/css/Dashboard.css';
import Home from "./Home"
import Isotope from "./pages/Isotope"
import Dashboard from "./pages/Dashboard.js"
import Register from './pages/Register.js';
import Login from './pages/Login.js';
import Authenticator from './pages/Authenticator.js';
import Tobulkorder from './pages/Tobulkorder.js';
import Buy from './Buy.js';
import Thankyou from './Thankyou.js';
import Editprofile from './pages/Editprofile.js';
import Profile from './pages/Profile.js';
import "react-toastify/dist/ReactToastify.css";
import Withdrawcrypto from './Authenticator/Withdrawcrypto.js';
import Deposite from './Authenticator/Deposite.js';
import Privacypolicy from './Footer/Privacypolicy.js';
import Terms from './Footer/Terms.js';
import ProtectedRoute from './pages/ProductedRoute.js';
import Registerone from '../src/pages/Registerone.js';
import ForgotPassword from './pages/ForgotPassword.js'
import VerificationPopup from './pages/VerificationPopup.js';
import VerifyOTP from './Authenticator/VerifyOTP.js';
import WithdrawAuthenticator from './pages/WithdrawAuthenticator.js';
import Error from './pages/Error.js';
import View from './pages/View.js';
import { BrowserRouter, Route, Link, Routes, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { makeApiRequest } from './config/axiosService.js';
import ConfirmMail from './pages/ConfirmMail.js';
import ResetPassword from './pages/ResetPassword.js';

function App() {
  const [userData, setUserData] = useState(null);
  const VerifyAuthenticate = localStorage.getItem('verifyAuthenticate');
  const Verifyotp = localStorage.getItem('verifyOTP');

  const getData = async () => {
    try {
      let params = {
        url: `get-user-details`,
        method: "GET",
      }
      const response = await makeApiRequest(params);
      setUserData(response.data);

    } catch (e) {
      console.log(e);
    }
  }

  const isUserVerified = userData && userData.emailVerifyStatus === 1 && userData.kycVerifyStatus === 2;
  useEffect(() => {
    getData();
  }, [])

  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path="/authenticator" element={<Authenticator />} />
          <Route
            path="/withdrawauthenticator/:Type"
            element={<ProtectedRoute>
              {VerifyAuthenticate == 1 ? <WithdrawAuthenticator /> : <Navigate to="/withdrawcrypto" />}
            </ProtectedRoute>
            }
          />
          <Route exact path='/isotope' element={<Isotope />}></Route>
          <Route exact path='/dashboard' element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>}></Route>
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/tobulkorder" element={<Tobulkorder />} />
          <Route exact path="/buy" element={<Buy />} />
          <Route exact path="/Thankyoupage" element={<Thankyou />} />
          <Route exact path="/confirmMail" element={<ConfirmMail />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/withdrawcrypto"
            element={
              <ProtectedRoute>
                {(isUserVerified || isUserVerified == null) ?
                  (userData && userData.secret == '' ? <Navigate to="/dashboard/security" /> : <Withdrawcrypto />) : <Navigate to="/verify" />}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/deposite"
            element={
              <ProtectedRoute>
                {(isUserVerified || isUserVerified == null) ? <Deposite /> : <Navigate to="/verify" />}
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/verify"
            element={<VerificationPopup />}
          />


          <Route exact path="/dashboard/edit-profile" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/dashboard/profile" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/dashboard/my-assets" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/dashboard/my-crypto-wallet" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/dashboard/my-asset-transaction" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/dashboard/my-crypto-transaction" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/dashboard/trade-hisory" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/dashboard/security" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/dashboard/reedemorder" element={<ProtectedRoute> {""}<Dashboard />{""}</ProtectedRoute>} />
          <Route exact path="/verifyOTP" element={
            <ProtectedRoute>
              {Verifyotp == 1 ? <VerifyOTP /> : <Navigate to="/withdrawcrypto" />}
            </ProtectedRoute>
          }
          />

          <Route exact path="/privacypolicy" element={<Privacypolicy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route path="*" element={<Error />} />
          <Route exact path="/view" element={<View />} />
          <Route exact path="/registerone" element={<Registerone />} />
          {/* <Route exact path='/cryptotransaction' element={<Cryptotransaction />}></Route> */}
          {/* <Route path="*" element={<Error />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

