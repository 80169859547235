import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { makeApiRequest } from "../config/axiosService";
import Spinner from "react-bootstrap/Spinner";
import "../assets/css/Dashboard.css";
import axios from 'axios'

const KycVerify = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [file, setFile] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const verifyKYC = async () => {
        // console.log("kycImageFile---", KycImageFile);
        try {
            setIsLoading(true);
            if (file) {
                const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
                const minimumFileSize = 2 * 1024 * 1024; // 2MB in bytes

                if (allowedFileTypes.includes(file.type)) {
                    if (file.size <= minimumFileSize) {
                        setErrorMessage("");
                        const formData = new FormData();
                        formData.append("kycImage", file);
                        let params = {
                            url: `kycVerifyStatus`,
                            method: "POST",
                            data: formData,
                            header: "image"
                        };
                        
                        let response = await makeApiRequest(params);

                        if (response.status == true) {
                            // toast.success(response.message)

                            setTimeout(() => {
                                if (response.status == true) {
                                    setIsLoading(false);
                                    toast.success(response.message);
                                }
                            }, 2000);

                            // toast.success(response.message);
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000);
                        }
                    } else {
                        setErrorMessage("File size must be at least 2MB.");
                        setIsLoading(false);
                    }
                } else {
                    setErrorMessage("Invalid file type. Please upload a JPG, JPEG, or PNG file.");
                    setIsLoading(false);
                }
            } else {
                setErrorMessage("No file selected.");
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error:", error.message);
        }
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    return (
        <>
            {/* <ToastContainer /> */}
            <form>
                <div className="mb-3">

                    <label for="kycImage" className="form-label">
                        KYC Document Image
                    </label>
                    <input
                        type="file"
                        className="form-control"
                        id="kycImage"
                        accept="image/*"
                        onChange={handleFileChange}
                        disabled={isLoading}
                    />
                </div>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <div className="text-center">
                    {isLoading ? (
                        <button type="button" className="spinner-btn-1"><Spinner animation="border" /></button>
                    ) : (
                        <button type="button" onClick={verifyKYC} className="spinner-btn-1 p-0">
                            Submit</button>
                    )}
                </div>

                <div className="mt-3">
                    <h6 style={{ fontSize: "18px" }}>Note:</h6><p className="mb-0" style={{ color: "#BC9018" }}> Image formats supported are JPEG, JPG, and PNG, with a maximum file size of 2MB. </p>
                </div>
            </form>
        </>
    );
};

export default KycVerify;
