
import { Link } from 'react-router-dom';
import Navbar from "../separate/Navbar"
import $ from "jquery";
import Asset1 from "../assets/image/Asset-14x-8.png"
import Chemical1 from "../assets/image/Chemical-Industry.png"
import Electronics1 from "../assets/image/Electronics-Industry.png"
import Mechanicaz1 from "../assets/image/Mechanical-Industry.png"
import Pharmaceutical1 from "../assets/image/Pharmaceutical-Industry.png"
import Energy1 from "../assets/image/Energy-Storage.png"
import OtherApplications1 from "../assets/image/Other-Applications.png"
import React, { useEffect } from 'react';

function Isotopy() {
    useEffect(() => {
		// Scroll to the top of the page when component mounts
		window.scrollTo(0, 0);
  }, []);

    // window.onscroll = function () { scrollFunction() };

    // function scrollFunction() {
    //     if (document.body.scrollTop > 600 || document.documentElement.scrollTop > 600) {
    //         document.getElementById("navbar").style.display = "none";
    //     } else {
    //         document.getElementById("navbar").style.display = "block";
    //     }
    // }
    return (
        <div className='APP custom-isotoprnav'>

            <div className='container-fluid col-lg-12'>
                <Navbar />
                <div className='custom-isotopy'>
                    <div className='custom-container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='custom-insideisotopy'>
                                    {/* <p className='text-center'>It is a mixture of natural stable isotopes of copper 63Cu and 65Cu of ultra-high chemical purity - 99.999% in the form of powder from
                                        nanoparticles of non-layered structure (spherical shape), stored in special ampoules filled with inert gas argon (see the photo of the package).</p> */}
                                    <h2>
                                        What is our asset?</h2>
                                    <p className='text-left'>It is a mixture of natural stable isotopes of copper 63Cu and 65Cu of ultra-high chemical purity-
                                        99.999% in the form of powder from nanoparticles of non- layered structure (spherical shape),
                                        stored in special ampoules filled with inert gas argon.</p>
                                    <h2>What are isotopes and where are they used?</h2>
                                    <p>Isotopes are varieties of atoms (and nuclei) of the same chemical element with different numbers of neutrons in the nucleus. The name is due to the fact that isotopes are in the same place in Mendeleev's periodic table. The chemical properties of an atom depend almost only on the structure of the electron shell, which, in turn, is determined mainly by the charge of the nucleus. Z (that is, the number of protons in it) and almost does not depend on its mass number A (that is, he total number of protons Z and neutrons N). All isotopes of the same element have the same nucleus charge, differing only in the number of neutrons. Usually, an isotope is designated by the symbol of the chemical element to which it belongs, with the addition of the upper left index indicating the mass number (e.g., 63Cu, 65Cu).
                                        It is known that in nature copper can be found in the form of two stable isotopes 63Cu (69.1%) and 65Cu (30.9%). Their mass numbers are 63 and 65 respectively. The atomic nucleus of the copper isotope 63Cu contains twenty-nine protons and thirty-four neutrons, while the isotope 65Cu contains the same number of protons and thirty-six neutrons. A variety of isotopes of chemical elements, including copper, are widely used in scientific
                                        research, in various fields of industry and agriculture, in nuclear power engineering, modern biology and medicine, in environmental research and other fields. The ability to add their radioactive isotopes to natural chemical elements makes it possible to monitor various chemical and physical processes in which the element is involved, using radioactive radiation detectors. This method is widely used. Sometimes stable isotopes are added, the presence of which is further detected by mass spectral methods.
                                        Stable isotopes have found the greatest application in chemistry (for studying the mechanism of chemical reactions, combustion processes, catalysis, synthesis of chemical compounds, in spectrometry), in biology, physiology, biochemistry and agrochemistry (for studying the processes of metabolism in living organisms, transformation of proteins, fatty and amino acids, the processes of photosynthesis in plants, the movement of water from the root along the stem to leaves and fruits). They are also used in nuclear-physical equipment for the manufacture of neutron counters, which allows increasing the counting efficiency by times, in nuclear power engineering as neutron moderators and absorbers. The above, however, does not exhaust all existing and possible uses of isotopes. Moreover, the scope of their use as effective helpers in solving a number of scientific and applied problems is expanding every year.</p>
                                    <h2>Why is our material highly rated?</h2>
                                    <p>Because isotope production in the world is technologically very complex and costly. As you can see in the catalog of Oak Ridge National Laboratory, the world leader in isotope production, the prices for the most common isotopes in use are in the range of 1000-6000 USD per gram. For example, copper isotope 63Cu costs $2400 per gram and copper isotope 65Cu costs $5350 per Gram. </p>
                                    <p>As explained by Dr. Arndt Uhlendorf, president of the German ISE Institute, who made the official expert evaluation of copper powder for us, the estimate of $2850 per gram was determined by taking into account sales and purchase data from three German universities and two laboratories, the German Copper Institute, the Tyssen Krupp Trading Company and the Institut für Seltene Erden und Metalle e.V.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-lg-4'>
                            <div className='col-lg-12'>
                                <h2>What is the application for Copper Powder?</h2>
                                <div className='row mt-3'>
                                    <div className='col-lg-4 '>
                                        <div className='custom-isotope-box-1'>
                                            <img src={Chemical1}></img>
                                            <h2 className='mt-2'>Electronics Industry</h2>
                                            <p>In the rapidly evolving electronics sector, the key role of Ultra Fine Copper Powder cannot be overstated. Its exceptional electrical conductivity makes it indispensable in developing advanced conductive inks, coatings, and components. </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className='custom-isotope-box-1'>
                                            <img src={Electronics1}></img>
                                            <h2 className='mt-2'>Chemical Industry</h2>
                                            <p>Acting as a catalyst, Ultra Fine Copper Powder accelerates various chemical processes, aligning itself with the industry's strides towards more efficient and advanced chemical production methods.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className='custom-isotope-box-1'>
                                            <img src={Mechanicaz1}></img>
                                            <h2 className='mt-2'>Mechanical Industry</h2>
                                            <p>The mechanical sector benefits significantly from the inclusion of Ultra Fine Copper Powder, especially in metallurgy and manufacturing.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className='custom-isotope-box-1'>
                                            <img src={Pharmaceutical1}></img>
                                            <h2 className='mt-2'>Pharmaceutical Industry</h2>
                                            <p>Innovative applications of Ultra Fine Copper Powder in medicine, particularly for bone health and wound healing, are testament to its growing importance in pharmaceutical advancements.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className='custom-isotope-box-1'>
                                            <img src={Energy1}></img>
                                            <h2 className='mt-2'>Energy Storage</h2>
                                            <p>Marking its territory in the energy storage domain, Ultra Fine Copper Powder is vital for the development of high-performance batteries and supercapacitors.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className='custom-isotope-box-1'>
                                            <img src={OtherApplications1}></img>
                                            <h2 className='mt-2'>Other Applications</h2>
                                            <p>From aerospace to defense, Ultra Fine Copper Powder's versatility shines, with its use expanding in tandem with technological advancements in these dynamic fields. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-lg-3'>
                            <div className='col-lg-12'>
                                <h2 className=''>What is the market growth potential for copper powder in the coming years?</h2>
                                <div className='row mt-5 align-items-center justify-content-between'>
                                    <div className='col-lg-6'>
                                        <div className='custom-down-years'>
                                            <p>The Ultra Fine Copper Powder Market has seen rapid growth recently and is
                                                expected to continue expanding significantly from 2023 to 2028. This surge is
                                                primarily driven by rising demands in various sectors such as electronics,
                                                mechanics, and pharmaceuticals. Unique properties of ultra fine copper
                                                powder, which allow it to replace silver in certain applications, contribute to
                                                its popularity. The Global Ultra Fine Copper Powder Market report offers an
                                                in-depth analysis of the market, examining key segments, trends, and
                                                influential factors shaping its growth.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-5'>
                                        <div className='custom-down-years'>
                                            <img src={Asset1}></img>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Isotopy